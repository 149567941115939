.experts-filter {
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 6px;

  margin-bottom: 61px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(md) {
    max-width: 290px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 370px;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.025em;
    text-align: center;
    padding: 45px 10px;
    text-transform: uppercase;

    & a {
      color: #000;
      transition: all 0.2s ease-out;

      &:hover {
        color: #b3da26;
      }
    }
  }

  &__list {
    //padding-top: 6px;
    //padding-bottom: 6px;

    &__category {
      border-top: 1px solid #dee2e6;
      transition: height 0.3s ease-out;
      display: none;
      position: relative;

      &:first-child {
        border-top: none;
      }

      &.open {
        display: block;

        &:after {
          content: '';
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          right: 19px;
          background: url('../img/arrow-down.svg') no-repeat 50% 50%;
          transform: translateY(-50%);

          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }

      @include media-breakpoint-up(md) {
        display: block;
      }

      &__name {
        position: relative;
        cursor: pointer;
        overflow: hidden;

        & .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-right: 20px;

          &-type1 {
            background: url('../img/filter1.svg') no-repeat 50% 50%;
            background-size: 20px 20px;
          }

          &-type2 {
            background: url('../img/filter2.svg') no-repeat 50% 50%;
            background-size: 20px 20px;
          }

          &-type3 {
            background: url('../img/filter3.svg') no-repeat 50% 50%;
            background-size: 20px 20px;
          }

          &-type4 {
            background: url('../img/filter4.svg') no-repeat 50% 50%;
            background-size: 20px 20px;
          }

          &-type5 {
            background: url('../img/filter5.svg') no-repeat 50% 50%;
            background-size: 20px 20px;
          }
        }

        & a {
          color: #000000;
          text-decoration: none;
          z-index: 20;

          &:visited {
            color: #000000;
          }
        }

        & span {
          position: relative;
          display: block;
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
          padding: 18px 39px 18px 29px;
          z-index: 10;

          &::first-letter {
            text-transform: capitalize;
          }

          & i {
            font-style: normal;
            color: $blue;
          }

          &:after {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            right: 19px;
            background: url('../img/arrow-down.svg') no-repeat 50% 50%;
            transform: translateY(-50%);
          }

          &.notarrow {
            &::after {
              display: none;
            }
          }
        }

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 100%;
          border-right: 5px solid #b3da26;
          background-color: rgba(179, 218, 38, 0.4);
          transition: right 0.5s ease-out;
        }

        &:hover,
        &.current {
          &:after {
            transition: right 0.3s ease-in;
            right: 0;
          }
        }
      }

      &.current {
        //min-height: 450px;
        overflow: hidden;
        transition: height 0.3s ease-out;
      }

      &.current &__name {
        //@debug: 1px solid #dee2e6;
        &:after {
          right: 0;
        }

        & span {
          &:after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
      }

      &__content {
        padding: 18px 28px 3px;
        display: none;

        @include media-breakpoint-up(lg) {
          padding: 18px 68px 3px;
        }

        & input.text {
          background: #f2f2f2;
          border-radius: 6px;
          margin-bottom: 24px;
          border: none;
          width: 100%;
          padding: 14px 28px;
        }

        & ul {
          list-style: none outside;

          & li {
            margin-bottom: 18px;
          }
        }

        &__view-all {
          color: #b3da26;
          cursor: pointer;
          margin-bottom: 15px;
          transition: all 0.3s ease-out;

          &:hover {
            color: darken(#b3da26, 5%);
          }
        }

        &__clear-all {
          color: #b3da26;
          cursor: pointer;
          margin-bottom: 15px;
          transition: all 0.3s ease-out;
          display: none;

          &:hover {
            color: darken(#b3da26, 5%);
          }

          &.active {
            display: block;
          }
        }

        & .hide {
          display: none;
        }
      }

      &.current &__content {
        display: block;
      }
    }
  }

  &-showmore {
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;

    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
      max-width: 420px;
    }

    & .btn {
      width: 100%;
      text-transform: uppercase;
      margin-bottom: 10px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;

        width: 202px;
      }

      &-outline-primary {
        background-color: #fff;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}
