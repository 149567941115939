.footer-payment {
  padding: 30px 0;

  background-color: #fff;

  & .container {
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-list {
    display: flex;
    flex-flow: wrap;
    align-items: center;

    font-weight: 700;
    font-size: 18px;
    line-height: 1;

    text-transform: uppercase;

    @media (min-width: 768px) {
      justify-content: space-between;
      flex-flow: nowrap;
    }

    @include media-breakpoint-up(xl) {
      flex-flow: nowrap;
    }

    &__item {
      margin-right: 2%;
      width: 31.3%;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        /* width: 16.6%; */
        width: min-content;
        /* margin-right: 10px; */
      }

      @include media-breakpoint-up(lg) {
        /*  width: auto; */
        /* margin-right: 50px; */
      }

      &:last-of-type {
        margin-right: 0;
      }

      &__first {
        width: 100%;
        margin-bottom: 22px;

        @media (min-width: 768px) {
          width: min-content;
          margin-bottom: 0;
        }

        @media (min-width: 992px) {
          width: min-content;
        }

        @include media-breakpoint-up(xl) {
          /* width: 160px; */
          margin-bottom: 0;
        }
      }

      &__winding {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 50px;
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
          width: 100%;
          min-width: 80px;
          margin-bottom: 0;
        }

        @media (min-width: 992px) {
          min-width: 100px;
        }

        border-radius: 6px;
        background: #f2f2f2;
      }
    }
  }
}
