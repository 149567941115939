.nav-blog {
  background-color: #fff;

  &__link {
    color: inherit;
    font-size: 0.875rem;
    letter-spacing: -0.02em;
    margin: 0 12px;
    padding: 9px 0 10px;
    white-space: nowrap;
  }

  &__item,
  & .cat-item {
    font-size: 0.875rem;
    letter-spacing: -0.02em;
    color: #0a71cc;
    margin: 0;
    white-space: nowrap;
  }

  & .cat-item {
    padding: 0 12px;
  }

  & a {
    position: relative;
    padding: 9px 0 10px;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    display: inline-block;
    text-decoration: none;
    color: #a6a6a6;
    transition: color 0.3s ease;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: transparent;
      transition: background-color 0.3s ease;
    }

    &.active {
      color: #000;
      &:after {
        background-color: #000;
      }
    }
  }

  &__item {
    padding: 0 12px;
  }

  &__menu {
    position: relative;
    transition: all 0.6s;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;

    list-style-type: none;

    & .owl-stage {
      display: flex;
    }

    & .owl-item {
      position: relative;
    }

    & .owl-nav {
      & .owl-prev {
        position: absolute;
        top: 50%;
        left: 0;
        width: 230px;
        height: 100%;
        padding: 0 0 0 15px;
        transform: translate(-2px, -50%);
        z-index: 2;

        @media (max-width: 768px) {
          width: 40px;
        }

        img {
          position: relative;
          width: 15px;
          z-index: 3;
        }

        &:before {
          width: 50px;
          bottom: 0;
          content: "";
          display: block;
          pointer-events: none;
          position: absolute;
          background: linear-gradient(270deg, #fff0 0, #fff 90%, #fff);
          left: 0;
          top: 0;
          transition: opacity 0.2s ease-in-out;
          z-index: 1;
          width: 50px;

          @media (min-width: 768px) {
            width: 200px;
          }
        }
        &.disabled {
          display: none;
        }
      }

      & .owl-next {
        position: absolute;
        top: 50%;
        right: 0;
        width: 30px;
        height: 100%;
        padding: 0 15px 0 0;
        transform: translate(2px, -50%);
        z-index: 2;

        img {
          transform: rotate(180deg);
          position: relative;
          width: 15px;
          z-index: 3;
        }

        &:before {
          width: 50px;
          bottom: 0;
          content: "";
          display: block;
          background: linear-gradient(90deg, #fff0 0, #fff 90%, #fff);
          right: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          transition: opacity 0.2s ease-in-out;
          z-index: 1;
          width: 50px;

          @media (min-width: 768px) {
            width: 200px;
          }
        }

        &.disabled {
          display: none;
        }
      }
    }
  }

  &__container {
    max-width: 1185px;
    overflow: hidden;
    position: relative;
  }

  &__btn {
    background-color: rgba(255, 255, 255, 0);
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    z-index: 100;

    &:after {
      border-bottom: 2px solid #767676;
      border-right: 2px solid #767676;
      content: "";
      height: 10px;
      position: absolute;
      width: 10px;
    }

    &_active {
      opacity: 1;
    }
  }

  &__btn-left {
    left: 0;
    opacity: 0;

    &:after {
      left: 10px;
      transform: translateY(-50%) rotate(135deg);
    }
  }

  &__btn-right {
    right: 0;

    &:after {
      right: 10px;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}
