.footer {
  padding-top: 50px;
  padding-bottom: 50px;

  font-size: 16px;
  line-height: 26px;

  color: #fff;
  background: $dark;

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  & .menu-menu-3-container {
    & ul {
      & li:last-of-type {
        font-weight: 700;
      }
    }
  }

  & .container {
    @include media-breakpoint-down(xs) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &__telephone {
    display: inline-block;

    padding-top: 10px;

    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
  }

  & p {
    margin: 19px 0;
  }

  & a {
    text-decoration: none;
    color: inherit;
    &.btn {
      color: $primary;
    }
  }

  & h3 {
    @include media-breakpoint-up(lg) {
      margin-bottom: 22px;

      font-size: 18px;
      line-height: 1;
    }
  }

  & ul {
    padding-right: 95px;

    list-style: none outside;

    font-family: "Proxima Nova";

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 26px;
    }

    & li {
      margin-bottom: 8px;
    }
  }

  &__social {
    display: flex;
    justify-content: flex-start;

    max-width: 205px;
    margin-bottom: 22px;
    column-gap: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin-bottom: 22px;
    }

    &__item {
      display: block;

      width: 35px;
      height: 35px;

      &--vk {
        background: url("../img/icons/vk.svg") no-repeat 50% 0;
      }
      &--fb {
        background: url("../img/icons/fb.svg") no-repeat 50% 0;
        display: none;
      }
      &--ok {
        background: url("../img/icons/ok.svg") no-repeat 50% 0;
      }
      &--in {
        background: url("../img/icons/in.svg") no-repeat 50% 0;
        display: none;
      }
    }
  }

  &__btns {
    & .btn {
      margin-bottom: 16px;

      font-weight: 700;
      font-size: 16px;
      line-height: 19px;

      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }

      &-lg {
        height: 52px;
      }
    }

    & .btn-outline-primary {
      background-color: #fff;

      &:hover {
        color: darken($blue, 10%);
      }
    }
  }

  &__panelright {
    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 220px;
      margin-top: 0;
    }
  }

  &__popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;

    display: none;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    &.open {
      display: flex;
    }

    &__overflow {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      background: rgba(#000, 0.4);
    }

    &__content {
      position: relative;
      z-index: 2;

      width: 548px;
      padding: 50px 15px;

      color: #000;
      border-radius: 6px;
      background: #fff;

      @include media-breakpoint-up(md) {
        padding: 50px;
      }

      & .form__field {
        margin-bottom: 22px;
      }

      &__close {
        position: absolute;
        top: 22px;
        right: 22px;

        width: 22px;
        height: 22px;

        cursor: pointer;

        background: url("../img/burger-close.svg") no-repeat 50% 50%;
        background-size: 22px 22px;
      }

      & .btn {
        font-size: 16px;
        line-height: 19px;

        text-transform: uppercase;
      }

      &__title {
        margin-bottom: 20px;

        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
      }

      &--city {
        width: 100%;
        max-width: 844px;
        max-height: 90vh;

        @include media-breakpoint-up(lg) {
          padding: 30px 20px;
        }
      }

      &__label {
        font-size: 14px;
        line-height: 17px;
        color: #0d1d4a;
        margin-bottom: 22px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &__price {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #0d1d4a;
        margin-bottom: 22px;

        @include media-breakpoint-up(lg) {
          font-size: 28px;
          line-height: 34px;
        }

        & span {
          display: block;
          color: #ff0000;
          font-weight: 400;

          @include media-breakpoint-up(lg) {
            margin-top: 4px;

            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      &__sublabel {
        color: #818ba5;
        margin-bottom: 12px;

        @include media-breakpoint-up(lg) {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 22px;
        }
      }
    }

    &__title {
      margin-bottom: 22px;

      text-align: center;
    }

    &__agreement {
      margin-top: 13px;

      font-size: 12px;
      line-height: 125%;
      min-height: 16px;
      & a {
        color: black;
      }

      &--review {
        margin-top: 0;
        margin-bottom: 20px;
      }

      & input.error {
        & + label {
          color: $red;

          & a {
            color: inherit;
          }
        }
      }
    }

    & .reviews__item__rating {
      margin-bottom: 20px;
    }
  }

  &__copy {
    @include media-breakpoint-up(lg) {
      margin-bottom: 12px;

      font-size: 16px;
      line-height: 26px;
    }
  }

  &__partners-item {
    font-weight: 700;
  }

  & input[type="checkbox"]:checked,
  & input[type="checkbox"]:not(:checked),
  & input[type="radio"]:checked,
  & input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  & input[type="checkbox"]:checked + label,
  & input[type="checkbox"]:not(:checked) + label,
  & input[type="radio"]:checked + label,
  & input[type="radio"]:not(:checked) + label {
    position: relative;

    display: inline-block;

    padding-left: 28px;

    cursor: pointer;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before,
  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: 16px;
    height: 16px;

    border: 1px solid #ced4da;
    border-radius: 2px;
    background-color: #fff;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
  }

  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    border-radius: 100%;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    content: "";

    position: absolute;

    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after {
    top: 0;
    left: 0;

    width: 16px;
    height: 16px;

    background: url("../img/checked.svg") no-repeat 50% 50%;
  }

  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    top: 5px;
    left: 5px;

    width: 10px;
    height: 10px;

    border-radius: 100%;
    background-color: #b3da26;
  }

  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="radio"]:checked + label:after {
    opacity: 1;
  }
}
