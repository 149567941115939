@font-face {
  font-family: "Proxima Nova";
  src: /* url('../fonts/ProximaNovaCond-LightIt.eot') */ url("../fonts/ProximaNovaCond-LightIt.woff2") format("woff2");
  src: local("Proxima Nova Condensed Light Italic"), local("ProximaNovaCond-LightIt"),
    url("../fonts/ProximaNovaCond-LightIt.woff") format("woff")
      /* url('../fonts/ProximaNovaCond-LightIt.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/ProximaNovaCond-LightIt.woff') format('woff'),

    url('../fonts/ProximaNovaCond-LightIt.ttf') format('truetype') */;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* @font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('../fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-LightIt.woff') format('woff'),
		url('../fonts/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
} */

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Light.woff2") format("woff2");
  src: url("../fonts/ProximaNova-Light.woff") format("woff")
    /* ,
    url('../fonts/ProximaNovaLight.svg#ProximaNovaLight') format('svg') */;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: /* url('../fonts/ProximaNova-Regular.eot') */ url("../fonts/ProximaNovaReg.woff2") format("woff2");
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
    /* url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), */
      url("../fonts/ProximaNova-Regular.woff") format("woff")
      /* ,
    url('../fonts/ProximaNova-Regular.ttf') format('truetype'),
    url('../fonts/ProximaNovaReg.woff2') format('woff2') */;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: /* url("../fonts/ProximaNova-RegularIt.eot") */ url("../fonts/ProximaNova-RegularIt.woff2") format("woff2");
  src: local("Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"),
    /*     url("../fonts/ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"), */
      url("../fonts/ProximaNova-RegularIt.woff") format("woff")
      /* ,
    url("../fonts/ProximaNova-RegularIt.ttf") format("truetype") */;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* @font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNovaReg.woff') format('woff');
  src: url('../fonts/ProximaNovaReg.woff2') format('woff2'),
    url('../fonts/ProximaNovaReg.svg#ProximaNovaReg') format('svg');
  font-weight: 400;
  font-display: swap;
} */

@font-face {
  font-family: "Proxima Nova";
  src: /* url("../fonts/ProximaNova-Semibold.eot") */ url("../fonts/ProximaNova-Semibold.woff") format("woff");
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
    /*     url("../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), */
      url("../fonts/ProximaNova-Semibold.woff") format("woff")
      /* ,
    url("../fonts/ProximaNova-Semibold.ttf") format("truetype") */;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: /* url("../fonts/ProximaNova-Bold.eot") */ url("../fonts/ProximaNovaBold.woff2") format("woff2");
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
    /* url("../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), */ url("../fonts/ProximaNova-Bold.woff")
      format("woff")
      /* ,
    url("../fonts/ProximaNova-Bold.ttf") format("truetype"),
    url("../fonts/ProximaNovaBold.woff2") format("woff2") */;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: /* url("../fonts/ProximaNova-BoldIt.eot") */ url("../fonts/ProximaNova-BoldIt.woff2") format("woff2");
  src: local("Proxima Nova Bold Italic"), local("ProximaNova-BoldIt"),
    /*  url("../fonts/ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"), */
      url("../fonts/ProximaNova-BoldIt.woff") format("woff")
      /* ,
    url("../fonts/ProximaNova-BoldIt.ttf") format("truetype") */;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* @font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNovaBold.woff') format('woff');
  src: url('../fonts/ProximaNovaBold.woff2') format('woff2'),
    url('../fonts/ProximaNovaBold.svg#ProximaNovaBold') format('svg');
  font-weight: 700;
  font-display: swap;
} */

@font-face {
  font-family: "GothamPro";
  src: /* url("../fonts/gothampro-webfont.eot") */ url("../fonts/gothampro-webfont.woff2") format("woff2");
  src: /* url("../fonts/gothampro-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/gothampro-webfont.woff2") format("woff2"), */ url("../fonts/gothampro-webfont.woff")
    format("woff")
    /* ,
    url("../fonts/gothampro-webfont.ttf") format("truetype"),
    url("../fonts/gothampro-webfont.svg#gotham_proregular") format("svg") */;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GothamPro";
  src: /* url("../fonts/gothampro-bold-webfont.eot") */ url("../fonts/gothampro-bold-webfont.woff2") format("woff2");
  src: /* url("../fonts/gothampro-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/gothampro-bold-webfont.woff2") format("woff2"), */ url("../fonts/gothampro-bold-webfont.woff")
    format("woff")
    /* ,
    url("../fonts/gothampro-bold-webfont.ttf") format("truetype"),
    url("../fonts/gothampro-bold-webfont.svg#gotham_probold") format("svg") */;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GothamPro";
  src: /* url("../fonts/GothamPro-Black.eot") */ url("../fonts/GothamPro-Black.woff2") format("woff2");
  src: local("GothamPro-Black"), local("GothamPro-Black"),
    /* url("../fonts/GothamPro-Black.eot?#iefix") format("embedded-opentype"), */ url("../fonts/GothamPro-Black.woff")
      format("woff") /* ,
    url("../fonts/GothamPro-Black.ttf") format("truetype") */;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
