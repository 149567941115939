.experts-reviews {
  // max-width: 300px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;

  @include media-breakpoint-up(sm) {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  &-list {
    &__item {
      border: 1px solid #b3da26;
      border-radius: 6px;
      padding: 36px 12px 33px;
      margin-bottom: 22px;
      transition: all 0.3s ease-out;

      &:hover {
        box-shadow: 0px 0px 5px #b3da26;
      }

      @include media-breakpoint-up(md) {
        padding-left: 19px;
        padding-right: 19px;
        padding-bottom: 27px;
      }

      &__avatar {
        width: 100%;
        max-width: 85px;
        margin-bottom: 22px;

        @include media-breakpoint-up(md) {
          margin-bottom: 14px;
        }

        @include media-breakpoint-up(md) {
          max-width: 102px;
        }

        & img {
          width: 100%;
        }
      }

      &__author {
        font-size: 13px;
        line-height: 16px;
        color: #666666;
        margin-bottom: 22px;

        @include media-breakpoint-up(md) {
          margin-bottom: 14px;
        }

        & p {
          margin: 5px 0;

          @include media-breakpoint-up(lg) {
            margin: 8px 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        & a {
          color: inherit;
        }

        & span {
          color: #0a71cc;
        }

        &__name {
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
          color: #000;
        }
      }

      &__form {
        @include media-breakpoint-up(md) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

      &__rating {
        background: #b3da26;
        border-radius: 6px;
        text-align: center;
        padding: 0 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 11px;

        @include media-breakpoint-up(md) {
          width: 115px;
        }
      }

      &__order {
        display: block;
        width: 100%;
        font-size: 12px;
        line-height: 15px;
        font-weight: 700;
        color: #fff;
        padding: 7.5px 10px;
        background: #0a71cc;
        border-radius: 6px;
        text-transform: uppercase;
        margin-bottom: 22px;
        transition: all 0.3s ease-out;

        &:hover {
          box-shadow: 0px 0px 5px #b3da26;
          background-color: #005bab;
          color: #fff;
        }

        &:focus,
        &:active {
          box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
          background-color: #005bab;
          color: #fff;
        }

        @include media-breakpoint-up(md) {
          width: 115px;
          margin-bottom: 14px;
        }

        @include media-breakpoint-up(lg) {
          width: 202px;
          font-size: 16px;
          line-height: 19px;
          padding: 15.5px 10px;
        }
      }

      &__content {
        font-size: 10px;
        line-height: 16px;

        & p {
          margin: 5px 0;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        & strong {
          font-size: 13px;
          display: inline-block;

          @include media-breakpoint-up(md) {
            padding-right: 11px;
            margin-bottom: 5px;
          }
        }

        & span {
          display: inline;
          padding-right: 3px;

          @include media-breakpoint-up(md) {
            display: inline-block;
            padding-right: 11px;
            margin-bottom: 5px;
            &:before {
              content: '';
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #b3da26;
              margin-right: 2px;
              vertical-align: middle;
              margin-top: -2px;
            }
          }

          @include media-breakpoint-up(xl) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
