.calculator {
  margin-top: 22px;
  margin-bottom: 55px;

  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  & .form__field {
    margin-bottom: 22px;
  }

  & .form__field__label {
    display: flex;
    align-items: center;

    margin-bottom: 22px;
  }

  &__title {
    margin-bottom: 8px;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  &__desc {
    margin-bottom: 22px;

    font-size: 16px;
    line-height: 26px;
  }

  &__steps {
    @include media-breakpoint-up(lg) {
      max-width: 570px;
    }
    &__item {
      display: none;

      &.current {
        display: block;
      }

      & .btn {
        margin-bottom: 22px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__steps-wrapper {
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin: 0 0 20px;

    & .form__field__label {
      margin: 0;
    }
  }

  &__steps-menu {
    font-size: 16px;
    line-height: 26px;

    color: #abb5be;

    padding-top: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(171, 181, 190, 0.5);

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      margin-bottom: 0px;
      border-bottom: none;
    }

    & ul {
      margin-bottom: 30px;

      list-style: none outside;

      counter-reset: section;

      & li {
        display: flex;
        align-items: center;

        margin-bottom: 22px;

        &:before {
          content: counter(section);

          display: flex;
          align-items: center;
          justify-content: center;

          width: 36px;
          height: 36px;
          margin-right: 15px;

          font-size: 16px;
          line-height: 119.3%;

          counter-increment: section;

          color: #fff;
          border-radius: 50%;
          background: #c8cdd2;
        }

        &.current {
          color: #000;

          &:before {
            background-color: $blue;
          }
        }
      }
    }
  }

  &__progress {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(171, 181, 190, 0.5);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      padding: 50px;

      border-radius: 6px;
      background: #fff;
      box-shadow: 0 0 30px rgba(74, 74, 74, 0.15);
      border-bottom: none;
    }

    &__title {
      margin-bottom: 8px;

      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      font-family: 'Proxima Nova';

      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }

    &__procents {
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      margin-top: 8px;
      display: inline-block;

      @include media-breakpoint-up(lg) {
        display: block;
      }

      &:after {
        content: '%';
      }
    }

    &__line {
      position: relative;

      overflow: hidden;

      height: 8px;
      margin: 8px 0;

      border-radius: 20px;
      background: #ededed;

      &__grey {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        min-width: 8px;
        height: 8px;

        border-radius: 20px;
        background: #c8cdd2;
      }

      &__green {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        min-width: 8px;
        height: 8px;

        border-radius: 20px;
        background: $green;
      }
    }

    &__info {
      font-size: 18px;
      line-height: 22px;

      color: #c4c4c4;

      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
  }

  &__button-agreament-wrapper {
    padding: 0 10px;

    & input.error {
      & + label {
        color: $red;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    flex: 0 0 auto;

    @media (min-width: 768px) {
      flex-direction: row;
      column-gap: 20px;
    }

    & button {
      display: none;
    }

    & .btn-lg {
      width: 100%;
      padding: 15.5px 2rem;

      @media (min-width: 768px) {
        width: max-content;
      }
    }

    & .btn-block {
      & + .btn-block {
        margin-top: 0.5rem;

        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    }

    & .current {
      display: block;
    }
  }
}
