.reviews {
  margin-top: 50px;
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &__title {
    @include media-breakpoint-up(md) {
      margin-bottom: 35px;
    }
  }

  &__blocktitle {
    font-size: 30px;
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      text-align: left;
      //margin-bottom: 8px;
    }

    @include media-breakpoint-up(lg) {
      //margin-bottom: 50px;
    }
  }

  &__rating-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    font-weight: 700;

    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;

    @media (min-width: 360px) {
      flex-direction: row;
      align-items: center;
    }

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      font-size: 18px;
      line-height: 24px;
      //margin-bottom: 20px;
    }

    @include media-breakpoint-up(lg) {
      //margin-bottom: 0;
    }

    &__stars {
      vertical-align: baseline;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }

  &__rating-title-wraper {
    width: 100%;
  }

  &__rating-common-grade-wrap,
  &__rating-common-cunt-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: flex-start;

    & span {
      white-space: nowrap;
    }

    & span:last-of-type {
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__rating-count {
    display: flex;
    column-gap: 10px;
    align-items: center;
    position: relative;
    font-size: 24px;

    svg {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      color: $green;

      @media (min-width: 425px) {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
      }
    }
  }

  &__rating-title-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin: 0 0 20px 0;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    & .col-md-12 {
      @media (min-width: 768px) {
        max-width: none;
        width: auto;
        flex: 0 0 auto;
      }
    }
  }

  &__rating-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    & span {
      font-size: 18px;
    }
  }

  &__rating-common {
    display: flex;
    column-gap: 10px;
    align-items: center;
    position: relative;
    font-size: 24px;

    svg {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      color: #ffd304;

      @media (min-width: 425px) {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
      }
    }
  }

  &__item {
    position: relative;

    overflow: hidden;

    padding: 48px 25px 45px;

    border-radius: 6px;
    background-color: #fff;
    transition: box-shadow 0.5s ease-out;

    &::before {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;

      display: block;

      width: 0;
      height: 5px;

      background-color: $green;
      transition: width 0.5s ease-out;
    }

    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      transition: box-shadow 0.3s ease-in;

      &::before {
        width: 100%;

        transition: width 0.3s ease-in;
      }
    }

    & > .row.align-items-md-center {
      align-items: center;
    }

    &__title {
      margin-bottom: 19px;

      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    &__content {
      min-height: 110px;
      margin-bottom: 29px;

      font-size: 12px;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 26px;
      }

      @include media-breakpoint-up(lg) {
        min-height: 160px;
      }

      @include media-breakpoint-up(xl) {
        min-height: 110px;
      }
    }

    &__author {
      display: flex;
      align-items: center;

      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      &__pic {
        padding-right: 8px;
        min-width: 65px;

        @include media-breakpoint-up(md) {
          padding-right: 15px;
          min-width: none;
        }
      }

      &__name {
        font-size: 16px;
        line-height: 1;

        letter-spacing: 0.04em;
        // font-weight: 600;
      }

      &__date {
        padding-top: 7px;

        white-space: nowrap;

        color: #abb5be;
      }
    }

    &__rating {
      display: flex;
      justify-content: space-between;

      width: 121px;
      & span {
        display: block;

        width: 20px;
        height: 20px;

        background: url("../img/star.svg") no-repeat 50% 50%;
        &.current {
          background: url("../img/star-current.svg") no-repeat 50% 50%;
        }
        &.active {
          background: url("../img/star-current.svg") no-repeat 50% 50%;
        }
      }
    }
  }
}
.reviewsList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
