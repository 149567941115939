.experts-page {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;

  @include media-breakpoint-up(sm) {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 30px;
      margin-bottom: 34px;
    }
  }

  &__author {
    width: 100%;
    max-width: 320px;
    background: #ffffff;
    border: 1px solid #b3da26;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 30px 30px;

    margin-bottom: 55px;

    text-align: center;

    & a {
      color: inherit;
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(md) {
      //width: 290px;
    }

    @include media-breakpoint-up(lg) {
      //width: 370px;
    }

    &__rating {
      background: #b3da26;
      border-radius: 0px 0px 6px 6px;
      width: 100%;
      margin: 0 auto 22px;
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      padding: 7px 10px;
    }

    &__avatar {
      margin-bottom: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__name {
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      color: #000;
      margin-bottom: 8px;
    }

    &__params {
      font-size: 13px;
      line-height: 16px;
      color: #666666;
      margin-bottom: 22px;

      @include media-breakpoint-up(md) {
        margin-bottom: 14px;
      }

      & p {
        margin: 5px 0;

        @include media-breakpoint-up(lg) {
          margin: 8px 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      & span {
        color: #0a71cc;
      }
    }

    &__profile {
      font-size: 14px;
      line-height: 17px;
      //margin-bottom: 35px;
      margin: 0 auto 35px auto;
      width: 202px;
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      & strong {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        display: block;
        margin-bottom: 8px;
      }

      & span {
        position: relative;
        width: auto;
        text-align: left;
        margin-bottom: 3px;
        padding: 0 0 0 15px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #b3da26;
          //margin-right: 10px;
          //vertical-align: middle;
          //@debug: -2px;
        }
      }
    }

    &__order {
      display: block;
      width: 202px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      color: #fff;
      padding: 7.5px 10px;
      background: #0a71cc;
      border-radius: 6px;
      text-transform: uppercase;
      transition: all 0.3s ease-out;
      margin: 0 auto;

      &:hover {
        box-shadow: 0px 0px 5px #b3da26;
        background-color: #005bab;
        color: #fff;
      }

      &:focus,
      &:active {
        box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
        background-color: #005bab;
        color: #fff;
      }

      @include media-breakpoint-up(md) {
        padding: 14.5px 10px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 19px;
        padding: 15.5px 10px;
      }
    }
  }

  &__reviews {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #000;
      //margin-bottom: 10px;
      text-align: center;

      & a {
        color: inherit;
      }
    }

    &__item {
      border-radius: 6px;
      border: 1px solid #dee2e6;
      padding: 20px 11px;
      margin-bottom: -1px;
      position: relative;
      z-index: 1;
      background-color: #fff;

      @include media-breakpoint-up(md) {
        padding: 36px 30px;
      }

      &:hover {
        z-index: 10;
        background: #fff7e3;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
      }

      &__date {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 22px;
        }

        & span {
          font-size: 14px;
          color: #8d8d8d;

          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }
        }
      }

      &__number {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        color: #8d8d8d;

        @include media-breakpoint-up(lg) {
          line-height: 22px;
        }
      }

      &__text {
        font-size: 16px;
        line-height: 24px;
        color: #8d8d8d;
        margin-bottom: 22px;
      }

      &__tag {
        color: #0a71cc;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
          font-size: 16px;
        }

        & a {
          display: inline-block;
          color: inherit;
          text-decoration: none;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      &__rating {
        display: flex;
        & span {
          display: block;
          width: 21px;
          height: 20px;
          background: url('../img/star.svg') no-repeat 50% 50%;
          background-size: 21px 20px;
          margin-right: 4px;

          &.current {
            background: url('../img/star-current.svg') no-repeat 50% 50%;
            background-size: 21px 20px;
          }
        }
      }
    }

    &__more {
      display: block;
      width: 202px;
      margin: 0 auto 61px;
      border: 1px solid #0a71cc;
      border-radius: 6px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      color: #0a71cc;
      background-color: #fff;
      padding: 7.5px 10px;
      text-transform: uppercase;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        margin-bottom: 99px;
        padding: 14.5px 10px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 19px;
        padding: 15.5px 10px;
      }

      &:hover {
        box-shadow: 0px 0px 5px #b3da26;
      }

      &:focus,
      &:active {
        box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
