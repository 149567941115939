.feedback-article {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
  padding: 44px 42px 51px;
  width: 100%;
  max-width: 728px;

  @media screen and (max-width: 767px) {
    width: auto;
    margin: 20px 0 0;
    padding: 27px 15px;
  }

  &__button {
    font-style: normal;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
  }

  &__title {
    color: #0a71cc;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  &__group {
    margin-bottom: 16px;
    padding: 0 8px;

    @media screen and (max-width: 767px) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  &__group_middle {
    flex: 1 1 50%;
    max-width: 50%;

    @media (max-width: 768px) {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }

  &__group_large {
    flex: 1 1 50%;
    max-width: 100%;
  }

  &__grid {
    margin: 36px -8px 0;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-top: 22px;
    }
  }

  & .select-wrapper {
    cursor: pointer;
    z-index: 10;

    &:after {
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      content: "";
      cursor: pointer;
      height: 8px;
      position: absolute;
      right: 32px;
      top: 45%;
      transform: rotate(45deg) translateY(-50%);
      width: 8px;
      z-index: -1;
    }
  }

  &__element {
    appearance: none;
    border: 1px solid #abb5be;
    border-radius: 6px;
    color: #abb5be;
    font-size: 1rem;
    min-height: 54px;
    padding: 8px 16px;
    resize: none;
    width: 100%;
    height: 100%;
    transition: border-color 0.3s ease;
    font-family: Proxima Nova;
    font-weight: 700;

    @media (max-width: 767px) {
      height: 100%;
    }

    &::placeholder {
      color: inherit;
    }

    &.error {
      border-color: $red;
    }
  }

  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::placeholder {
    color: #abb5be;
  }

  &__select {
    background-image: url(../img/icon-arrow.svg);
    background-position: center right 20px;
    background-repeat: no-repeat;
    background-size: 10px;
    cursor: pointer;
  }

  &__textarea {
    appearance: none;
    height: 99px;
  }

  &__button {
    border-radius: 6px;
    justify-content: center;
  }

  & .footer__popup__agreement {
    margin-top: 0;
    margin-bottom: 20px;

    & label {
      line-height: 16px;
    }
  }
}
