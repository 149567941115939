@charset "UTF-8";
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNovaCond-LightIt.woff2") format("woff2");
  src: local("Proxima Nova Condensed Light Italic"), local("ProximaNovaCond-LightIt"), url("../fonts/ProximaNovaCond-LightIt.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
/* @font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/ProximaNova-LightIt.eot');
	src: local('Proxima Nova Light Italic'), local('ProximaNova-LightIt'),
		url('../fonts/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ProximaNova-LightIt.woff') format('woff'),
		url('../fonts/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
} */
@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/ProximaNova-Light.woff2") format("woff2");
  src: url("../fonts/ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNovaReg.woff2") format("woff2");
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"), url("../fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-RegularIt.woff2") format("woff2");
  src: local("Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"), url("../fonts/ProximaNova-RegularIt.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
/* @font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNovaReg.woff') format('woff');
  src: url('../fonts/ProximaNovaReg.woff2') format('woff2'),
    url('../fonts/ProximaNovaReg.svg#ProximaNovaReg') format('svg');
  font-weight: 400;
  font-display: swap;
} */
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Semibold.woff") format("woff");
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"), url("../fonts/ProximaNova-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNovaBold.woff2") format("woff2");
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-BoldIt.woff2") format("woff2");
  src: local("Proxima Nova Bold Italic"), local("ProximaNova-BoldIt"), url("../fonts/ProximaNova-BoldIt.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/* @font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNovaBold.woff') format('woff');
  src: url('../fonts/ProximaNovaBold.woff2') format('woff2'),
    url('../fonts/ProximaNovaBold.svg#ProximaNovaBold') format('svg');
  font-weight: 700;
  font-display: swap;
} */
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/gothampro-webfont.woff2") format("woff2");
  src: url("../fonts/gothampro-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/gothampro-bold-webfont.woff2") format("woff2");
  src: url("../fonts/gothampro-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro-Black.woff2") format("woff2");
  src: local("GothamPro-Black"), local("GothamPro-Black"), url("../fonts/GothamPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/*!
 * Bootstrap Grid v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 758px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0a71cc !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #08569b !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #b3da26 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #90af1e !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f2f2f2 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d9d9d9 !important;
}

.bg-dark {
  background-color: #313845 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1c2027 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0a71cc !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #b3da26 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f2f2f2 !important;
}

.border-dark {
  border-color: #313845 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0a71cc !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #064983 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #b3da26 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #7e991a !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f2f2f2 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cccccc !important;
}

.text-dark {
  color: #313845 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #111418 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(10, 113, 204, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0a71cc;
  border-color: #0a71cc;
}
.btn-primary:hover {
  color: #fff;
  background-color: #085da8;
  border-color: #08569b;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #085da8;
  border-color: #08569b;
  box-shadow: 0 0 0 0.2rem rgba(47, 134, 212, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0a71cc;
  border-color: #0a71cc;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #08569b;
  border-color: #074f8f;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 134, 212, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #b3da26;
  border-color: #b3da26;
}
.btn-success:hover {
  color: #212529;
  background-color: #99ba20;
  border-color: #90af1e;
}
.btn-success:focus, .btn-success.focus {
  color: #212529;
  background-color: #99ba20;
  border-color: #90af1e;
  box-shadow: 0 0 0 0.2rem rgba(157, 191, 38, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #b3da26;
  border-color: #b3da26;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #90af1e;
  border-color: #87a41c;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 191, 38, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:hover {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #313845;
  border-color: #313845;
}
.btn-dark:hover {
  color: #fff;
  background-color: #21262f;
  border-color: #1c2027;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #21262f;
  border-color: #1c2027;
  box-shadow: 0 0 0 0.2rem rgba(80, 86, 97, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #313845;
  border-color: #313845;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1c2027;
  border-color: #171a20;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 86, 97, 0.5);
}

.btn-outline-primary {
  color: #0a71cc;
  border-color: #0a71cc;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0a71cc;
  border-color: #0a71cc;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 113, 204, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0a71cc;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a71cc;
  border-color: #0a71cc;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 113, 204, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #b3da26;
  border-color: #b3da26;
}
.btn-outline-success:hover {
  color: #212529;
  background-color: #b3da26;
  border-color: #b3da26;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 218, 38, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #b3da26;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #b3da26;
  border-color: #b3da26;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(179, 218, 38, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5);
}

.btn-outline-dark {
  color: #313845;
  border-color: #313845;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #313845;
  border-color: #313845;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 56, 69, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #313845;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #313845;
  border-color: #313845;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 56, 69, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0a71cc;
  text-decoration: none;
}
.btn-link:hover {
  color: #064983;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 15.5px 1rem;
  font-size: 1rem;
  line-height: 19px;
  border-radius: 6px;
}

.btn-sm {
  padding: 0.35rem 1.15rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

/* @import "./blocks/custom-input";
@import "./blocks/custom-toggle";
@import "./blocks/custom-select"; */
h1,
.h1 {
  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  h1,
.h1 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  h1,
.h1 {
    font-size: 30px;
  }
}
h1.extra,
.h1.extra {
  margin-bottom: 28px;
  font-weight: 700;
  font-size: 36px;
  line-height: 98.3%;
  letter-spacing: -0.02em;
}
@media (min-width: 768px) {
  h1.extra,
.h1.extra {
    margin-bottom: 23px;
    font-size: 64px;
  }
}
@media (min-width: 992px) {
  h1.extra,
.h1.extra {
    font-size: 72px;
  }
}

h2,
.h2 {
  margin-bottom: 30px;
  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  h2,
.h2 {
    margin-bottom: 30px;
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  h2,
.h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 50px;
  }
}

h3,
.h3 {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 16px;
  line-height: 122.8%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  h3,
.h3 {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  h3,
.h3 {
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.04em;
    margin-bottom: 22px;
  }
}

h4,
.h4 {
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  h4,
.h4 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 992px) {
  h4,
.h4 {
    font-size: 18px;
    line-height: 27px;
  }
}

p {
  margin-bottom: 20px;
}

.gradient-text {
  background: linear-gradient(180deg, #0a71cc 0%, #b3da26 166.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-primary {
  color: #0a71cc;
}

.experts-mainpanel {
  position: relative;
  background: #f2f2f2;
  padding: 40px 15px;
  text-align: center;
  padding-bottom: 0;
  padding-top: 0;
  flex-direction: column;
  min-height: 454px;
  /*     &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        background: url('../img/men-768.png') no-repeat 0% 100%;
        transform: translateX(-50%);
        background-size: 326px 300px;
        width: 326px;
        height: 300px;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            transform: translateX(0%);
            width: 476px;
            height: 437px;
            background-size: 476px 437px;
        }

        @include media-breakpoint-up(xl) {
            transform: translateX(20%);
        }
    } */
}
@media (min-width: 576px) {
  .experts-mainpanel {
    min-height: 454px;
    text-align: left;
  }
}
.experts-mainpanel .container {
  display: flex;
  flex-grow: 1;
  padding-top: 42px;
  padding-bottom: 276px;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: auto 290px;
  background-image: url("../img/men-768.png");
  background-image: image-set(url("../img/men-768.webp") 1x, url("../img/men-768@2x.webp") 2x, url("../img/men-768.png") 1x, url("../img/men-768@2x.png") 2x);
  min-height: inherit;
}
@media (min-width: 768px) {
  .experts-mainpanel .container {
    background-position: 140% 100%;
    background-size: auto 434px;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .experts-mainpanel .container {
    background-position: 100% 100%;
    /* background-size: 480px 434px; */
    padding-top: 0;
    padding-bottom: 0;
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .experts-mainpanel .container {
    max-width: 1140px;
  }
}
.experts-mainpanel .container .row {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  min-height: inherit;
}
@media (max-width: 991px) {
  .experts-mainpanel .container .row {
    max-width: 758px;
  }
}
.experts-mainpanel__title {
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #0a71cc;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) {
  .experts-mainpanel__title {
    font-size: 72px;
    line-height: 1;
  }
}
@media (min-width: 1200px) {
  .experts-mainpanel__title {
    margin-bottom: 9px;
  }
}
.experts-mainpanel__text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  margin-bottom: 43px;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) {
  .experts-mainpanel__text {
    font-size: 18px;
    line-height: 22px;
    max-width: 340px;
  }
}
@media (min-width: 1200px) {
  .experts-mainpanel__text {
    max-width: 626px;
    margin-bottom: 41px;
  }
}
.experts-mainpanel__form {
  display: flex;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #b3da26;
  margin-bottom: 4px;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) {
  .experts-mainpanel__form {
    max-width: 260px;
  }
}
@media (min-width: 768px) {
  .experts-mainpanel__form {
    max-width: 340px;
  }
}
@media (min-width: 1200px) {
  .experts-mainpanel__form {
    max-width: 626px;
  }
}
.experts-mainpanel__form form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.experts-mainpanel__form input {
  font-size: 16px;
  line-height: 21px;
  padding: 6.5px 18px;
  background-color: transparent;
  border: none;
  display: block;
  flex: 1 1 auto;
}
.experts-mainpanel__form input::placeholder {
  color: #abb5be;
  font-weight: 400;
}
.experts-mainpanel__form button {
  background: #b3da26 url("../img/search-icon.svg") no-repeat 50% 50%;
  width: 38px;
  height: 38px;
  border: none;
  margin: 0;
  padding: 0;
  display: block;
  flex: 0 1 38px;
  cursor: pointer;
}
.experts-mainpanel__help {
  font-size: 12px;
  line-height: 16px;
  color: #abb5be;
  display: none;
}
@media (min-width: 768px) {
  .experts-mainpanel__help {
    display: block;
  }
}
.experts-mainpanel__help span {
  font-size: 14px;
  color: #000;
}

.experts-filter {
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 61px;
}
@media (min-width: 576px) {
  .experts-filter {
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) {
  .experts-filter {
    max-width: 290px;
  }
}
@media (min-width: 992px) {
  .experts-filter {
    max-width: 370px;
  }
}
.experts-filter__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.025em;
  text-align: center;
  padding: 45px 10px;
  text-transform: uppercase;
}
.experts-filter__title a {
  color: #000;
  transition: all 0.2s ease-out;
}
.experts-filter__title a:hover {
  color: #b3da26;
}
.experts-filter__list__category {
  border-top: 1px solid #dee2e6;
  transition: height 0.3s ease-out;
  display: none;
  position: relative;
}
.experts-filter__list__category:first-child {
  border-top: none;
}
.experts-filter__list__category.open {
  display: block;
}
.experts-filter__list__category.open:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 19px;
  background: url("../img/arrow-down.svg") no-repeat 50% 50%;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .experts-filter__list__category.open:after {
    display: none;
  }
}
@media (min-width: 768px) {
  .experts-filter__list__category {
    display: block;
  }
}
.experts-filter__list__category__name {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.experts-filter__list__category__name .icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 20px;
}
.experts-filter__list__category__name .icon-type1 {
  background: url("../img/filter1.svg") no-repeat 50% 50%;
  background-size: 20px 20px;
}
.experts-filter__list__category__name .icon-type2 {
  background: url("../img/filter2.svg") no-repeat 50% 50%;
  background-size: 20px 20px;
}
.experts-filter__list__category__name .icon-type3 {
  background: url("../img/filter3.svg") no-repeat 50% 50%;
  background-size: 20px 20px;
}
.experts-filter__list__category__name .icon-type4 {
  background: url("../img/filter4.svg") no-repeat 50% 50%;
  background-size: 20px 20px;
}
.experts-filter__list__category__name .icon-type5 {
  background: url("../img/filter5.svg") no-repeat 50% 50%;
  background-size: 20px 20px;
}
.experts-filter__list__category__name a {
  color: #000000;
  text-decoration: none;
  z-index: 20;
}
.experts-filter__list__category__name a:visited {
  color: #000000;
}
.experts-filter__list__category__name span {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  padding: 18px 39px 18px 29px;
  z-index: 10;
}
.experts-filter__list__category__name span::first-letter {
  text-transform: capitalize;
}
.experts-filter__list__category__name span i {
  font-style: normal;
  color: #0a71cc;
}
.experts-filter__list__category__name span:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 19px;
  background: url("../img/arrow-down.svg") no-repeat 50% 50%;
  transform: translateY(-50%);
}
.experts-filter__list__category__name span.notarrow::after {
  display: none;
}
.experts-filter__list__category__name:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 100%;
  border-right: 5px solid #b3da26;
  background-color: rgba(179, 218, 38, 0.4);
  transition: right 0.5s ease-out;
}
.experts-filter__list__category__name:hover:after, .experts-filter__list__category__name.current:after {
  transition: right 0.3s ease-in;
  right: 0;
}
.experts-filter__list__category.current {
  overflow: hidden;
  transition: height 0.3s ease-out;
}
.experts-filter__list__category.current .experts-filter__list__category__name:after {
  right: 0;
}
.experts-filter__list__category.current .experts-filter__list__category__name span:after {
  transform: translateY(-50%) rotate(-180deg);
}
.experts-filter__list__category__content {
  padding: 18px 28px 3px;
  display: none;
}
@media (min-width: 992px) {
  .experts-filter__list__category__content {
    padding: 18px 68px 3px;
  }
}
.experts-filter__list__category__content input.text {
  background: #f2f2f2;
  border-radius: 6px;
  margin-bottom: 24px;
  border: none;
  width: 100%;
  padding: 14px 28px;
}
.experts-filter__list__category__content ul {
  list-style: none outside;
}
.experts-filter__list__category__content ul li {
  margin-bottom: 18px;
}
.experts-filter__list__category__content__view-all {
  color: #b3da26;
  cursor: pointer;
  margin-bottom: 15px;
  transition: all 0.3s ease-out;
}
.experts-filter__list__category__content__view-all:hover {
  color: #a2c522;
}
.experts-filter__list__category__content__clear-all {
  color: #b3da26;
  cursor: pointer;
  margin-bottom: 15px;
  transition: all 0.3s ease-out;
  display: none;
}
.experts-filter__list__category__content__clear-all:hover {
  color: #a2c522;
}
.experts-filter__list__category__content__clear-all.active {
  display: block;
}
.experts-filter__list__category__content .hide {
  display: none;
}
.experts-filter__list__category.current .experts-filter__list__category__content {
  display: block;
}
.experts-filter-showmore {
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .experts-filter-showmore {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .experts-filter-showmore {
    justify-content: space-between;
    max-width: 420px;
  }
}
.experts-filter-showmore .btn {
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .experts-filter-showmore .btn {
    margin-bottom: 0;
    width: 202px;
  }
}
.experts-filter-showmore .btn-outline-primary {
  background-color: #fff;
}
.experts-filter-showmore .btn-outline-primary:hover {
  color: #0a71cc;
}

.experts-reviews {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 576px) {
  .experts-reviews {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.experts-reviews-list__item {
  border: 1px solid #b3da26;
  border-radius: 6px;
  padding: 36px 12px 33px;
  margin-bottom: 22px;
  transition: all 0.3s ease-out;
}
.experts-reviews-list__item:hover {
  box-shadow: 0px 0px 5px #b3da26;
}
@media (min-width: 768px) {
  .experts-reviews-list__item {
    padding-left: 19px;
    padding-right: 19px;
    padding-bottom: 27px;
  }
}
.experts-reviews-list__item__avatar {
  width: 100%;
  max-width: 85px;
  margin-bottom: 22px;
}
@media (min-width: 768px) {
  .experts-reviews-list__item__avatar {
    margin-bottom: 14px;
  }
}
@media (min-width: 768px) {
  .experts-reviews-list__item__avatar {
    max-width: 102px;
  }
}
.experts-reviews-list__item__avatar img {
  width: 100%;
}
.experts-reviews-list__item__author {
  font-size: 13px;
  line-height: 16px;
  color: #666666;
  margin-bottom: 22px;
}
@media (min-width: 768px) {
  .experts-reviews-list__item__author {
    margin-bottom: 14px;
  }
}
.experts-reviews-list__item__author p {
  margin: 5px 0;
}
@media (min-width: 992px) {
  .experts-reviews-list__item__author p {
    margin: 8px 0;
  }
}
.experts-reviews-list__item__author p:last-of-type {
  margin-bottom: 0;
}
.experts-reviews-list__item__author a {
  color: inherit;
}
.experts-reviews-list__item__author span {
  color: #0a71cc;
}
.experts-reviews-list__item__author__name {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #000;
}
@media (min-width: 768px) {
  .experts-reviews-list__item__form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.experts-reviews-list__item__rating {
  background: #b3da26;
  border-radius: 6px;
  text-align: center;
  padding: 0 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 11px;
}
@media (min-width: 768px) {
  .experts-reviews-list__item__rating {
    width: 115px;
  }
}
.experts-reviews-list__item__order {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  color: #fff;
  padding: 7.5px 10px;
  background: #0a71cc;
  border-radius: 6px;
  text-transform: uppercase;
  margin-bottom: 22px;
  transition: all 0.3s ease-out;
}
.experts-reviews-list__item__order:hover {
  box-shadow: 0px 0px 5px #b3da26;
  background-color: #005bab;
  color: #fff;
}
.experts-reviews-list__item__order:focus, .experts-reviews-list__item__order:active {
  box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
  background-color: #005bab;
  color: #fff;
}
@media (min-width: 768px) {
  .experts-reviews-list__item__order {
    width: 115px;
    margin-bottom: 14px;
  }
}
@media (min-width: 992px) {
  .experts-reviews-list__item__order {
    width: 202px;
    font-size: 16px;
    line-height: 19px;
    padding: 15.5px 10px;
  }
}
.experts-reviews-list__item__content {
  font-size: 10px;
  line-height: 16px;
}
.experts-reviews-list__item__content p {
  margin: 5px 0;
}
.experts-reviews-list__item__content p:last-of-type {
  margin-bottom: 0;
}
.experts-reviews-list__item__content strong {
  font-size: 13px;
  display: inline-block;
}
@media (min-width: 768px) {
  .experts-reviews-list__item__content strong {
    padding-right: 11px;
    margin-bottom: 5px;
  }
}
.experts-reviews-list__item__content span {
  display: inline;
  padding-right: 3px;
}
@media (min-width: 768px) {
  .experts-reviews-list__item__content span {
    display: inline-block;
    padding-right: 11px;
    margin-bottom: 5px;
  }
  .experts-reviews-list__item__content span:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #b3da26;
    margin-right: 2px;
    vertical-align: middle;
    margin-top: -2px;
  }
}
@media (min-width: 1200px) {
  .experts-reviews-list__item__content span {
    font-size: 12px;
  }
}

.experts-control {
  display: none;
  overflow: hidden;
  border-radius: 16px;
  background: #b3da26 url("../img/bg.jpg") no-repeat 50% 50%;
  background-size: cover;
}
@media (min-width: 576px) {
  .experts-control {
    display: block;
    padding: 48px 30px;
  }
}
@media (min-width: 768px) {
  .experts-control {
    max-width: 290px;
    padding: 48px 30px;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .experts-control {
    max-width: 370px;
    text-align: left;
  }
}
.experts-control__title {
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  text-transform: uppercase;
}
.experts-control__content {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.experts-control .btn {
  display: block;
  width: 100%;
  padding: 15.5px 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border-radius: 6px;
  background: #0a71cc;
  transition: all 0.3s ease-out;
}
.experts-control .btn:hover {
  color: #0a71cc;
  border: 1px solid #0a71cc;
  background: #fff;
  box-shadow: 0 0 5px #b3da26;
}
.experts-control .btn:focus, .experts-control .btn:active {
  color: #fff;
  background: #005bab;
  box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
}

.experts-reviews-paginations {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 61px;
}
@media (min-width: 768px) {
  .experts-reviews-paginations {
    margin-bottom: 99px;
  }
}
.experts-reviews-paginations .btn {
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  margin: 0 5px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
  font-weight: 600;
}
.experts-reviews-paginations .btn:hover {
  background-color: #DEE2E6;
}
.experts-reviews-paginations .btn.disable {
  opacity: 0.2;
  background-color: #fff;
  cursor: default;
}
.experts-reviews-paginations .btn.current {
  background-color: #B3DA26;
  border: 1px solid #B3DA26;
}
.experts-reviews-paginations .btn-prev {
  background-image: url("../img/arrow-left.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.experts-reviews-paginations .btn-next {
  background-image: url("../img/arrow-right.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.experts-breadcrumbs {
  letter-spacing: -0.02em;
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
  margin-bottom: 28px;
}
@media (min-width: 768px) {
  .experts-breadcrumbs {
    font-size: 18px;
    margin-bottom: 36px;
  }
}
.experts-breadcrumbs ul {
  list-style: none outside;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}
.experts-breadcrumbs ul li {
  position: relative;
  padding: 0 15px 0 0;
}
.experts-breadcrumbs ul li:after {
  content: "›";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.experts-breadcrumbs ul li:last-of-type:after {
  display: none;
}
.experts-breadcrumbs a {
  color: initial;
  text-decoration: underline;
}

.experts-page {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 576px) {
  .experts-page {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.experts-page__title {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.04em;
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .experts-page__title {
    font-size: 30px;
    margin-bottom: 34px;
  }
}
.experts-page__author {
  width: 100%;
  max-width: 320px;
  background: #ffffff;
  border: 1px solid #b3da26;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 30px 30px;
  margin-bottom: 55px;
  text-align: center;
}
.experts-page__author a {
  color: inherit;
}
@media (min-width: 576px) {
  .experts-page__author {
    margin-bottom: 50px;
  }
}
.experts-page__author__rating {
  background: #b3da26;
  border-radius: 0px 0px 6px 6px;
  width: 100%;
  margin: 0 auto 22px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 7px 10px;
}
.experts-page__author__avatar {
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.experts-page__author__name {
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  color: #000;
  margin-bottom: 8px;
}
.experts-page__author__params {
  font-size: 13px;
  line-height: 16px;
  color: #666666;
  margin-bottom: 22px;
}
@media (min-width: 768px) {
  .experts-page__author__params {
    margin-bottom: 14px;
  }
}
.experts-page__author__params p {
  margin: 5px 0;
}
@media (min-width: 992px) {
  .experts-page__author__params p {
    margin: 8px 0;
  }
}
.experts-page__author__params p:last-of-type {
  margin-bottom: 0;
}
.experts-page__author__params span {
  color: #0a71cc;
}
.experts-page__author__profile {
  font-size: 14px;
  line-height: 17px;
  margin: 0 auto 35px auto;
  width: 202px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.experts-page__author__profile strong {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  display: block;
  margin-bottom: 8px;
}
.experts-page__author__profile span {
  position: relative;
  width: auto;
  text-align: left;
  margin-bottom: 3px;
  padding: 0 0 0 15px;
}
.experts-page__author__profile span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #b3da26;
}
.experts-page__author__order {
  display: block;
  width: 202px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  color: #fff;
  padding: 7.5px 10px;
  background: #0a71cc;
  border-radius: 6px;
  text-transform: uppercase;
  transition: all 0.3s ease-out;
  margin: 0 auto;
}
.experts-page__author__order:hover {
  box-shadow: 0px 0px 5px #b3da26;
  background-color: #005bab;
  color: #fff;
}
.experts-page__author__order:focus, .experts-page__author__order:active {
  box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
  background-color: #005bab;
  color: #fff;
}
@media (min-width: 768px) {
  .experts-page__author__order {
    padding: 14.5px 10px;
  }
}
@media (min-width: 992px) {
  .experts-page__author__order {
    font-size: 16px;
    line-height: 19px;
    padding: 15.5px 10px;
  }
}
.experts-page__reviews {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.experts-page__reviews__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  text-align: center;
}
.experts-page__reviews__title a {
  color: inherit;
}
.experts-page__reviews__item {
  border-radius: 6px;
  border: 1px solid #dee2e6;
  padding: 20px 11px;
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
  background-color: #fff;
}
@media (min-width: 768px) {
  .experts-page__reviews__item {
    padding: 36px 30px;
  }
}
.experts-page__reviews__item:hover {
  z-index: 10;
  background: #fff7e3;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}
.experts-page__reviews__item__date {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .experts-page__reviews__item__date {
    font-size: 18px;
    line-height: 22px;
  }
}
.experts-page__reviews__item__date span {
  font-size: 14px;
  color: #8d8d8d;
}
@media (min-width: 992px) {
  .experts-page__reviews__item__date span {
    font-size: 16px;
  }
}
.experts-page__reviews__item__number {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #8d8d8d;
}
@media (min-width: 992px) {
  .experts-page__reviews__item__number {
    line-height: 22px;
  }
}
.experts-page__reviews__item__text {
  font-size: 16px;
  line-height: 24px;
  color: #8d8d8d;
  margin-bottom: 22px;
}
.experts-page__reviews__item__tag {
  color: #0a71cc;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .experts-page__reviews__item__tag {
    margin-bottom: 0;
    font-size: 16px;
  }
}
.experts-page__reviews__item__tag a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
.experts-page__reviews__item__tag a::first-letter {
  text-transform: uppercase;
}
.experts-page__reviews__item__rating {
  display: flex;
}
.experts-page__reviews__item__rating span {
  display: block;
  width: 21px;
  height: 20px;
  background: url("../img/star.svg") no-repeat 50% 50%;
  background-size: 21px 20px;
  margin-right: 4px;
}
.experts-page__reviews__item__rating span.current {
  background: url("../img/star-current.svg") no-repeat 50% 50%;
  background-size: 21px 20px;
}
.experts-page__reviews__more {
  display: block;
  width: 202px;
  margin: 0 auto 61px;
  border: 1px solid #0a71cc;
  border-radius: 6px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  color: #0a71cc;
  background-color: #fff;
  padding: 7.5px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
@media (min-width: 768px) {
  .experts-page__reviews__more {
    margin-bottom: 99px;
    padding: 14.5px 10px;
  }
}
@media (min-width: 992px) {
  .experts-page__reviews__more {
    font-size: 16px;
    line-height: 19px;
    padding: 15.5px 10px;
  }
}
.experts-page__reviews__more:hover {
  box-shadow: 0px 0px 5px #b3da26;
}
.experts-page__reviews__more:focus, .experts-page__reviews__more:active {
  box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
}

.header-topbar {
  padding-top: 13px;
  padding-bottom: 13px;
  color: #000;
}
.header-topbar a {
  color: inherit;
}
@media (min-width: 992px) {
  .header-topbar {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.header-topbar__logo {
  max-height: 20px;
}
@media (min-width: 768px) {
  .header-topbar__logo {
    padding-right: 40px;
  }
}
@media (min-width: 992px) {
  .header-topbar__logo {
    max-height: 31px;
    padding-right: 0px;
  }
}
.header-topbar__logo img {
  max-height: 20px;
}
@media (min-width: 992px) {
  .header-topbar__logo img {
    max-height: 100%;
  }
}
.header-topbar__location {
  position: relative;
  max-width: 165px;
  font-size: 14px;
  line-height: 22px;
}
.header-topbar__location-chooser {
  position: absolute;
  bottom: -5px;
  transform: translate(-50%, 100%);
  left: 80%;
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  pointer-events: none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px 0 2px rgba(0, 0, 0, 0.05);
}
.header-topbar__location-chooser--visible {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.header-topbar__location-name {
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
}
.header-topbar__location-name span {
  white-space: nowrap;
  text-decoration: underline;
}
.header-topbar__location-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}
.header-topbar__location-agree, .header-topbar__location-change {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #ffffff;
}
.header-topbar__telephone {
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .header-topbar__telephone {
    font-size: 18px;
    line-height: 24px;
  }
}
.header-topbar__burger {
  display: block;
  width: 24px;
  height: 16px;
  border: none;
  border-radius: 0;
  background: url("../img/burger.svg") no-repeat 50% 50%;
  background-size: contain;
}
.mobilemenu-open .header-topbar__burger {
  background: url("../img/burger-close.svg") no-repeat 50% 50%;
  width: 24px;
  height: 16px;
}
.header-topbar__loginbox {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -8px;
  margin-left: -8px;
}
.header-topbar__loginbox .btn {
  min-width: 150px;
  margin: 0 8px;
  padding: 8.5px 8px;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  border-radius: 34px;
}
.header-navbar {
  position: relative;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  background-color: #b3da26;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.header-navbar a {
  text-decoration: none;
  color: inherit;
}
.header-navbar-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  margin-right: -15px;
  margin-left: -15px;
  list-style: none outside;
}
@media (min-width: 992px) {
  .header-navbar-list {
    font-size: 18px;
    line-height: 22px;
  }
}
.header-navbar-list li {
  margin: 0 15px;
  white-space: nowrap;
}
.header-navbar-list a {
  color: #000;
}
.header-navbar__privat-office {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  .header-navbar__privat-office {
    font-size: 18px;
    line-height: 22px;
  }
}
.header-navbar__privat-office:before {
  content: "";
  display: block;
  width: 16px;
  height: 20px;
  margin-right: 6px;
  background: url("../img/icons/avatar.svg") no-repeat 50% 50%;
}
.header-navbar-popup {
  position: fixed;
  top: 46px;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: block;
  pointer-events: none;
  z-index: 500;
}
.mobilemenu-open .header-navbar-popup {
  pointer-events: all;
}
@media (min-width: 992px) {
  .mobilemenu-open .header-navbar-popup {
    display: none;
  }
}
.header-navbar-popup__overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.mobilemenu-open .header-navbar-popup__overlay {
  opacity: 1;
  visibility: visible;
}
.header-navbar-popup__content {
  position: relative;
  margin-left: auto;
  width: 100%;
  max-width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 10;
  transform: translate(100%, 0);
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  padding: 0 0 30px;
}
@media (max-width: 767px) {
  .header-navbar-popup__content {
    max-width: 400px;
  }
}
@media (max-width: 475px) {
  .header-navbar-popup__content {
    max-width: none;
  }
}
.mobilemenu-open .header-navbar-popup__content {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
}
@media (min-width: 992px) {
  .mobilemenu-open .header-navbar-popup__content {
    display: none;
  }
}
.header-navbar-popup__list {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  list-style: none outside;
  padding: 11px 15px 22px 37px;
}
@media (max-width: 768px) {
  .header-navbar-popup__list {
    font-size: 22px;
  }
}
.header-navbar-popup__list li {
  margin-bottom: 15px;
  text-align: left;
}
.header-navbar-popup__list a {
  color: #000;
  text-decoration: none;
}
.header-navbar-popup__privat-office {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #dee2e6;
}
.header-navbar-popup__privat-office a {
  display: block;
  width: max-content;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  padding: 10px 15px 10px 37px;
  color: #000;
  text-decoration: none;
  position: relative;
  text-align: right;
}
.header-navbar-popup__privat-office a::before {
  content: "";
  width: 17px;
  height: 20px;
  background: url("../img/icons/privat-office-popup-icon.svg") no-repeat 50% 50%;
  position: absolute;
  top: 13px;
  left: 14px;
}
@media (max-width: 767px) {
  .header-navbar-popup__privat-office a {
    font-size: 22px;
    line-height: 26px;
  }
}
.header-navbar-popup__loginbox {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  /* margin-right: -8px;
  margin-left: -8px; */
}
.header-navbar-popup__loginbox .btn {
  width: 200px;
  margin: 0 auto 11px 37px;
  padding: 8.5px 8px;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 34px;
}
.header-navbar-popup__contacts {
  padding: 11px 15px 22px 37px;
}
.header-navbar-popup__contacts a {
  color: #000;
}
.header-navbar-popup__contacts p {
  text-align: left;
}
.header-navbar-popup__telephone {
  font-family: "Proxima Nova";
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  display: block;
  margin-bottom: 11px;
  text-align: left;
}

.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  background: #313845;
}
@media (min-width: 992px) {
  .footer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.footer .menu-menu-3-container ul li:last-of-type {
  font-weight: 700;
}
@media (max-width: 575.98px) {
  .footer .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.footer__telephone {
  display: inline-block;
  padding-top: 10px;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
}
.footer p {
  margin: 19px 0;
}
.footer a {
  text-decoration: none;
  color: inherit;
}
.footer a.btn {
  color: #0a71cc;
}
@media (min-width: 992px) {
  .footer h3 {
    margin-bottom: 22px;
    font-size: 18px;
    line-height: 1;
  }
}
.footer ul {
  padding-right: 95px;
  list-style: none outside;
  font-family: "Proxima Nova";
}
@media (min-width: 992px) {
  .footer ul {
    font-size: 16px;
    line-height: 26px;
  }
}
.footer ul li {
  margin-bottom: 8px;
}
.footer__social {
  display: flex;
  justify-content: flex-start;
  max-width: 205px;
  margin-bottom: 22px;
  column-gap: 10px;
}
@media (min-width: 768px) {
  .footer__social {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer__social {
    max-width: 100%;
    margin-bottom: 22px;
  }
}
.footer__social__item {
  display: block;
  width: 35px;
  height: 35px;
}
.footer__social__item--vk {
  background: url("../img/icons/vk.svg") no-repeat 50% 0;
}
.footer__social__item--fb {
  background: url("../img/icons/fb.svg") no-repeat 50% 0;
  display: none;
}
.footer__social__item--ok {
  background: url("../img/icons/ok.svg") no-repeat 50% 0;
}
.footer__social__item--in {
  background: url("../img/icons/in.svg") no-repeat 50% 0;
  display: none;
}
.footer__btns .btn {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .footer__btns .btn {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer__btns .btn {
    margin-bottom: 16px;
  }
}
.footer__btns .btn-lg {
  height: 52px;
}
.footer__btns .btn-outline-primary {
  background-color: #fff;
}
.footer__btns .btn-outline-primary:hover {
  color: #08569b;
}
@media (min-width: 768px) {
  .footer__panelright {
    margin-top: 50px;
  }
}
@media (min-width: 992px) {
  .footer__panelright {
    max-width: 220px;
    margin-top: 0;
  }
}
.footer__popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.footer__popup.open {
  display: flex;
}
.footer__popup__overflow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.footer__popup__content {
  position: relative;
  z-index: 2;
  width: 548px;
  padding: 50px 15px;
  color: #000;
  border-radius: 6px;
  background: #fff;
}
@media (min-width: 768px) {
  .footer__popup__content {
    padding: 50px;
  }
}
.footer__popup__content .form__field {
  margin-bottom: 22px;
}
.footer__popup__content__close {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  background: url("../img/burger-close.svg") no-repeat 50% 50%;
  background-size: 22px 22px;
}
.footer__popup__content .btn {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}
.footer__popup__content__title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.footer__popup__content--city {
  width: 100%;
  max-width: 844px;
  max-height: 90vh;
}
@media (min-width: 992px) {
  .footer__popup__content--city {
    padding: 30px 20px;
  }
}
.footer__popup__content__label {
  font-size: 14px;
  line-height: 17px;
  color: #0d1d4a;
  margin-bottom: 22px;
}
@media (min-width: 992px) {
  .footer__popup__content__label {
    font-size: 18px;
    line-height: 22px;
  }
}
.footer__popup__content__price {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0d1d4a;
  margin-bottom: 22px;
}
@media (min-width: 992px) {
  .footer__popup__content__price {
    font-size: 28px;
    line-height: 34px;
  }
}
.footer__popup__content__price span {
  display: block;
  color: #ff0000;
  font-weight: 400;
}
@media (min-width: 992px) {
  .footer__popup__content__price span {
    margin-top: 4px;
    font-size: 16px;
    line-height: 19px;
  }
}
.footer__popup__content__sublabel {
  color: #818ba5;
  margin-bottom: 12px;
}
@media (min-width: 992px) {
  .footer__popup__content__sublabel {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 22px;
  }
}
.footer__popup__title {
  margin-bottom: 22px;
  text-align: center;
}
.footer__popup__agreement {
  margin-top: 13px;
  font-size: 12px;
  line-height: 125%;
  min-height: 16px;
}
.footer__popup__agreement a {
  color: black;
}
.footer__popup__agreement--review {
  margin-top: 0;
  margin-bottom: 20px;
}
.footer__popup__agreement input.error + label {
  color: #dc3545;
}
.footer__popup__agreement input.error + label a {
  color: inherit;
}
.footer__popup .reviews__item__rating {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .footer__copy {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 26px;
  }
}
.footer__partners-item {
  font-weight: 700;
}
.footer input[type=checkbox]:checked, .footer input[type=checkbox]:not(:checked), .footer input[type=radio]:checked, .footer input[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.footer input[type=checkbox]:checked + label, .footer input[type=checkbox]:not(:checked) + label, .footer input[type=radio]:checked + label, .footer input[type=radio]:not(:checked) + label {
  position: relative;
  display: inline-block;
  padding-left: 28px;
  cursor: pointer;
}
.footer input[type=checkbox]:checked + label:before, .footer input[type=checkbox]:not(:checked) + label:before, .footer input[type=radio]:checked + label:before, .footer input[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  background-color: #fff;
}
.footer input[type=checkbox]:checked + label:before, .footer input[type=checkbox]:not(:checked) + label:before {
  border-radius: 2px;
}
.footer input[type=radio]:checked + label:before, .footer input[type=radio]:not(:checked) + label:before {
  border-radius: 100%;
}
.footer input[type=checkbox]:checked + label:after, .footer input[type=checkbox]:not(:checked) + label:after, .footer input[type=radio]:checked + label:after, .footer input[type=radio]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.footer input[type=checkbox]:checked + label:after, .footer input[type=checkbox]:not(:checked) + label:after {
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background: url("../img/checked.svg") no-repeat 50% 50%;
}
.footer input[type=radio]:checked + label:after, .footer input[type=radio]:not(:checked) + label:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #b3da26;
}
.footer input[type=checkbox]:not(:checked) + label:after, .footer input[type=radio]:not(:checked) + label:after {
  opacity: 0;
}
.footer input[type=checkbox]:checked + label:after, .footer input[type=radio]:checked + label:after {
  opacity: 1;
}

.form__files__item {
  margin-bottom: 13px;
  padding-left: 26px;
  padding-right: 26px;
  color: #abb5be;
}
.form__files-error {
  color: #ff3333;
}
.form textarea {
  font-family: inherit;
}
.form__field-wrapper {
  position: relative;
}
.form__field-list-courses {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: absolute;
  top: calc(100% - 25px);
  left: 0;
  right: 0;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 6px;
  background-color: #fff;
  border: solid 1px #abb5be;
  box-shadow: 0 0 30px rgba(74, 74, 74, 0.15);
  list-style-type: none;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  min-height: 30px;
  z-index: 10;
}
.form__field-list-courses--opened {
  visibility: visible;
  opacity: 1;
}
.form__field-item-courses {
  font-size: 14px;
  line-height: 1;
  color: #5f6061;
  font-weight: 600;
  padding: 8px 26px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  word-wrap: break-word;
}
.form__field-item-courses--disabled {
  cursor: none;
  pointer-events: none;
}
.form__field-item-courses:hover {
  color: #000;
  background-color: #f2f2f2;
}
.form__field {
  width: 100%;
  margin-bottom: 13px;
  padding: 16px 26px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 1;
  color: #000;
  border: 1px solid #abb5be;
  border-radius: 6px;
  background: #fff;
}
.form__field::placeholder {
  font-weight: 700;
  font-family: "Proxima Nova";
  text-transform: uppercase;
  color: #abb5be;
}
.form__field.error {
  border-color: #dc3545;
}
.form__field.error::placeholder {
  color: #dc3545 !important;
}
.form__field__label {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
}
.form__field__label a {
  text-decoration: underline;
  color: inherit;
}
.form__field__label a:hover {
  color: #b3da26;
}
.form__field-group-files {
  position: relative;
  display: block;
  width: 100%;
  min-height: 52px;
  margin-bottom: 13px;
  padding: 16px 26px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1;
  color: #000;
  border: 1px solid #abb5be;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
}
.form__field-group-files::after {
  content: attr(data-placeholder);
  font-weight: 700;
  font-family: "Proxima Nova";
  text-transform: uppercase;
  color: #abb5be;
}
.form__field-group-files:before {
  content: "";
  display: block;
  width: 10px;
  height: 18px;
  background: url("../img/download.svg") no-repeat 50% 50%;
  position: absolute;
  top: 15px;
  right: 20px;
}
.form__field-group-files input {
  display: none;
}
.form__field--search {
  padding: 9.5px 26px;
  font-size: 14px;
  font-family: "Proxima Nova";
  font-weight: 400;
  text-transform: none;
  background-image: url("../img/icons/search.svg");
  background-position: 98% 50%;
  background-repeat: no-repeat;
}
.form__field--search::placeholder {
  font-weight: 400;
  font-family: "Proxima Nova";
  text-transform: none;
  color: #abb5be;
}
.form__textfield {
  width: 100%;
  min-height: 126px;
  margin-bottom: 22px;
  padding: 16px 26px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1;
  color: #000;
  border: 1px solid #abb5be;
  border-radius: 6px;
  background: #fff;
}
.form__textfield::placeholder {
  font-weight: 700;
  font-family: "Proxima Nova";
  text-transform: uppercase;
  color: #abb5be;
}

.footer-payment {
  padding: 30px 0;
  background-color: #fff;
}
@media (max-width: 575.98px) {
  .footer-payment .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.footer-payment-list {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .footer-payment-list {
    justify-content: space-between;
    flex-flow: nowrap;
  }
}
@media (min-width: 1200px) {
  .footer-payment-list {
    flex-flow: nowrap;
  }
}
.footer-payment-list__item {
  margin-right: 2%;
  width: 31.3%;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .footer-payment-list__item {
    /* width: 16.6%; */
    width: min-content;
    /* margin-right: 10px; */
  }
}
@media (min-width: 992px) {
  .footer-payment-list__item {
    /*  width: auto; */
    /* margin-right: 50px; */
  }
}
.footer-payment-list__item:last-of-type {
  margin-right: 0;
}
.footer-payment-list__item__first {
  width: 100%;
  margin-bottom: 22px;
}
@media (min-width: 768px) {
  .footer-payment-list__item__first {
    width: min-content;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer-payment-list__item__first {
    width: min-content;
  }
}
@media (min-width: 1200px) {
  .footer-payment-list__item__first {
    /* width: 160px; */
    margin-bottom: 0;
  }
}
.footer-payment-list__item__winding {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 8px;
  border-radius: 6px;
  background: #f2f2f2;
}
@media (min-width: 768px) {
  .footer-payment-list__item__winding {
    width: 100%;
    min-width: 80px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .footer-payment-list__item__winding {
    min-width: 100px;
  }
}

.achievements {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .achievements {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.achievements__box {
  margin: 0 auto 22px;
  max-width: 380px;
}
@media (min-width: 992px) {
  .achievements__box {
    margin-bottom: 0;
    max-width: none;
  }
}
.achievements__box__pic {
  position: relative;
}
.achievements__box__pic img {
  position: relative;
  z-index: 10;
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto auto 0;
}
.achievements__box__pic:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 80%;
  border-radius: 6px 6px 0 0;
}
.achievements__box__info {
  min-height: 146px;
  padding: 29px 30px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  border: 1px solid #dee2e6;
  border-top: none;
  border-radius: 0 0 6px 6px;
  background-color: #fff;
}
.achievements__box__info__count {
  margin-bottom: 8px;
  font-weight: 900;
  font-size: 36px;
  line-height: 1;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.achievements__box--first .achievements__box__pic:before {
  background-color: #b3da26;
}
@media (min-width: 992px) {
  .achievements__box--first .achievements__box__pic:before {
    border-radius: 6px 0 0 0;
  }
}
@media (min-width: 992px) {
  .achievements__box--first .achievements__box__info {
    border-radius: 0 0 0 6px;
  }
}
.achievements__box--second .achievements__box__pic:before {
  background-color: #0a71cc;
}
@media (min-width: 992px) {
  .achievements__box--second .achievements__box__pic:before {
    border-radius: 0 0 0 0;
  }
}
@media (min-width: 992px) {
  .achievements__box--second .achievements__box__info {
    border-right: none;
    border-left: none;
    border-radius: 0;
  }
}
.achievements__box--last {
  margin-bottom: 0;
}
.achievements__box--last .achievements__box__pic:before {
  background-color: #b3da26;
}
@media (min-width: 992px) {
  .achievements__box--last .achievements__box__pic:before {
    border-radius: 0 6px 0 0;
  }
}
@media (min-width: 992px) {
  .achievements__box--last .achievements__box__info {
    border-radius: 0 0 6px 0;
  }
}
.achievements--partners .achievements__box {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 0 22px;
}
.achievements--partners .achievements__box--first {
  display: none;
}
@media (min-width: 992px) {
  .achievements--partners .achievements__box--second .achievements__box__pic:before {
    border-radius: 6px 0 0 0;
  }
}
@media (min-width: 992px) {
  .achievements--partners .achievements__box--second .achievements__box__info {
    border-radius: 0 0 0 6px;
    border-left: 1px solid #dee2e6;
  }
}
.achievements--partners .achievements__box__pic img {
  height: auto;
}
.achievements--partners .achievements__box__info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  text-align: left;
  padding: 31px 21px 24px;
}
.achievements--partners .achievements__box__info__count-wrapper {
  margin: 0 0 18px;
}
.achievements--partners .achievements__box__info__count {
  position: relative;
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  text-transform: capitalize;
}
.achievements--partners .achievements__box__info__count svg {
  position: absolute;
  top: 50%;
  right: -22px;
  transform: translate(100%, -50%);
  color: #0a71cc;
}
.achievements--partners .achievements__box__info__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  margin: 0 0 auto;
}
.achievements--partners .achievements__box__info__button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  padding: 17px 24px;
  margin: 30px 0 0;
  width: 100%;
}
@media (min-width: 992px) {
  .achievements--partners .achievements__box__info__button {
    width: auto;
  }
}

.reviews {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .reviews {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
@media (min-width: 768px) {
  .reviews__title {
    margin-bottom: 35px;
  }
}
.reviews__blocktitle {
  font-size: 30px;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .reviews__blocktitle {
    font-size: 24px;
    text-align: left;
  }
}
.reviews__rating-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
@media (min-width: 360px) {
  .reviews__rating-title {
    flex-direction: row;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .reviews__rating-title {
    justify-content: flex-end;
    font-size: 18px;
    line-height: 24px;
  }
}
.reviews__rating-title__stars {
  vertical-align: baseline;
  margin-right: 5px;
  margin-bottom: -2px;
}
.reviews__rating-title-wraper {
  width: 100%;
}
.reviews__rating-common-grade-wrap, .reviews__rating-common-cunt-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: flex-start;
}
.reviews__rating-common-grade-wrap span, .reviews__rating-common-cunt-wrap span {
  white-space: nowrap;
}
.reviews__rating-common-grade-wrap span:last-of-type, .reviews__rating-common-cunt-wrap span:last-of-type {
  font-size: 12px;
  font-weight: 600;
}
.reviews__rating-count {
  display: flex;
  column-gap: 10px;
  align-items: center;
  position: relative;
  font-size: 24px;
}
.reviews__rating-count svg {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  color: #b3da26;
}
@media (min-width: 425px) {
  .reviews__rating-count svg {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
}
.reviews__rating-title-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin: 0 0 20px 0;
}
@media (min-width: 768px) {
  .reviews__rating-title-list {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .reviews__rating-title-list .col-md-12 {
    max-width: none;
    width: auto;
    flex: 0 0 auto;
  }
}
.reviews__rating-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.reviews__rating-wrapper span {
  font-size: 18px;
}
.reviews__rating-common {
  display: flex;
  column-gap: 10px;
  align-items: center;
  position: relative;
  font-size: 24px;
}
.reviews__rating-common svg {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  color: #ffd304;
}
@media (min-width: 425px) {
  .reviews__rating-common svg {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
}
.reviews__item {
  position: relative;
  overflow: hidden;
  padding: 48px 25px 45px;
  border-radius: 6px;
  background-color: #fff;
  transition: box-shadow 0.5s ease-out;
}
.reviews__item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 5px;
  background-color: #b3da26;
  transition: width 0.5s ease-out;
}
.reviews__item:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in;
}
.reviews__item:hover::before {
  width: 100%;
  transition: width 0.3s ease-in;
}
.reviews__item > .row.align-items-md-center {
  align-items: center;
}
.reviews__item__title {
  margin-bottom: 19px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.reviews__item__content {
  min-height: 110px;
  margin-bottom: 29px;
  font-size: 12px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .reviews__item__content {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (min-width: 992px) {
  .reviews__item__content {
    min-height: 160px;
  }
}
@media (min-width: 1200px) {
  .reviews__item__content {
    min-height: 110px;
  }
}
.reviews__item__author {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .reviews__item__author {
    margin-bottom: 0;
  }
}
.reviews__item__author__pic {
  padding-right: 8px;
  min-width: 65px;
}
@media (min-width: 768px) {
  .reviews__item__author__pic {
    padding-right: 15px;
    min-width: none;
  }
}
.reviews__item__author__name {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.04em;
}
.reviews__item__author__date {
  padding-top: 7px;
  white-space: nowrap;
  color: #abb5be;
}
.reviews__item__rating {
  display: flex;
  justify-content: space-between;
  width: 121px;
}
.reviews__item__rating span {
  display: block;
  width: 20px;
  height: 20px;
  background: url("../img/star.svg") no-repeat 50% 50%;
}
.reviews__item__rating span.current {
  background: url("../img/star-current.svg") no-repeat 50% 50%;
}
.reviews__item__rating span.active {
  background: url("../img/star-current.svg") no-repeat 50% 50%;
}

.reviewsList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.warranty {
  padding-top: 55px;
  padding-bottom: 25px;
  color: #fff;
  background: #313845;
}
@media (min-width: 768px) {
  .warranty {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
.warranty__title {
  margin-bottom: 22px;
}
.warranty__desc {
  margin-bottom: 50px;
  font-size: 12px;
  line-height: 16px;
}
@media (min-width: 768px) {
  .warranty__desc {
    font-size: 16px;
    line-height: 26px;
  }
}
.warranty__item {
  max-width: 310px;
  margin: 0 auto 50px;
  text-align: center;
}
.warranty__item--agency {
  font-size: 16px;
  font-size: 16px;
}
.warranty__item img {
  display: block;
  margin: 0 auto 20px;
}
.warranty__item h3 {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  font-family: "Proxima Nova";
}
.warranty--partners .warranty__item svg {
  margin: 0 auto 22px;
}
.warranty--partners .warranty__item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.about-smart {
  padding-top: 55px;
  padding-bottom: 55px;
  color: #fff;
  background: #313845;
}
@media (min-width: 768px) {
  .about-smart {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.about-smart__desc {
  font-size: 12px;
  line-height: 16px;
}
@media (min-width: 768px) {
  .about-smart__desc {
    font-size: 16px;
    line-height: 26px;
  }
}
.about-smart__desc p {
  margin-bottom: 30px;
}
.about-smart__desc p:last-of-type {
  margin-bottom: 0;
}
.about-smart__desc:before {
  content: "“";
  display: none;
  float: left;
  overflow: hidden;
  width: 156px;
  height: 156px;
  margin-top: -20px;
  margin-right: 50px;
  font-weight: 700;
  font-size: 288px;
  line-height: 242px;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
}
@media (min-width: 768px) {
  .about-smart__desc:before {
    display: block;
  }
}

.about-content {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 16px;
  color: #68717a;
}
@media (min-width: 768px) {
  .about-content {
    margin-top: 55px;
    margin-bottom: 55px;
    font-size: 16px;
    line-height: 26px;
  }
}
@media (min-width: 992px) {
  .about-content {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.about-content p:last-of-type {
  margin-bottom: 0;
}
.about-content__tabs__menu {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 37px;
  margin-bottom: 20px;
  list-style: none outside;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #68717a;
}
@media (min-width: 768px) {
  .about-content__tabs__menu {
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 24px;
  }
}
.about-content__tabs__menu a {
  display: block;
  padding-bottom: 8px;
  text-decoration: none;
  color: inherit;
  position: relative;
}
.about-content__tabs__menu a:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: transparent;
  z-index: 10;
}
.about-content__tabs__menu > .card {
  margin-bottom: 0;
  white-space: nowrap;
  position: relative;
  margin-right: 0;
  padding: 0 20px;
}
.about-content__tabs__menu > .card:first-child {
  padding: 0 20px 0 0;
}
.about-content__tabs__menu > .card:last-child {
  padding-right: 30px;
}
.about-content__tabs__menu > .card:last-child:after {
  width: calc(100% - 30px);
}
.about-content__tabs__menu > .card:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: transparent;
  z-index: 1;
}
.about-content__tabs__menu > .card.current {
  color: #000;
}
.about-content__tabs__menu > .card.current a:after {
  background-color: #b3da26;
}
.about-content__tabs__item {
  display: none;
}
.about-content__tabs__item.current {
  display: block;
}
.about-content__tabs__item iframe {
  width: 100%;
  height: 370px;
}
.about-content__tabs__btn {
  position: absolute;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 40px;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
@media (min-width: 768px) {
  .about-content__tabs__btn {
    top: -7px;
  }
}
.about-content__tabs__btn__left {
  left: -2px;
  display: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}
.about-content__tabs__btn__left:after {
  content: "";
  display: block;
  width: 9px;
  height: 16px;
  background: url("../img/arrow-left.svg") no-repeat 50% 50%;
}
.about-content__tabs__btn__left.current {
  display: flex;
}
.about-content__tabs__btn__right {
  right: -2px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
.about-content__tabs__btn__right:after {
  content: "";
  display: block;
  width: 9px;
  height: 16px;
  background: url("../img/arrow-right.svg") no-repeat 50% 50%;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.scrolling-wrapper .card {
  display: inline-block;
}

.scrolling-wrapper-flexbox {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}
.scrolling-wrapper-flexbox .card {
  flex: 0 0 auto;
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow-style: autohiding-scrollbar;
  -moz-overflow-style: autohiding-scrollbar;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  width: 100%;
  height: 30px;
}
@media (min-width: 768px) {
  .scrolling-wrapper,
.scrolling-wrapper-flexbox {
    height: 37px;
  }
}
.scrolling-wrapper::-webkit-scrollbar,
.scrolling-wrapper-flexbox::-webkit-scrollbar {
  display: none;
}

.helpers__title {
  margin-bottom: 30px;
}
.helpers__container {
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;
}
.helpers__container::after {
  display: block;
  content: "";
  width: 100%;
  height: 260px;
  background-image: url("../img/helpers/bg-md.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: image-set(url("../img/helpers/bg-md.webp") 1x, url("../img/helpers/bg-md@2x.webp") 2x, url("../img/helpers/bg-md.png") 1x, url("../img/helpers/bg-md@2x.png") 2x);
  background-size: 213px 257px;
  margin-bottom: -55px;
}
@media (min-width: 768px) {
  .helpers__container::after {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    right: 0;
    height: 561px;
    background-size: 464px 561px;
  }
}
@media (min-width: 992px) {
  .helpers__container::after {
    display: none;
  }
}
@media (min-width: 768px) {
  .helpers__container {
    padding-top: 100px;
    padding-bottom: 306px;
  }
}
@media (min-width: 992px) {
  .helpers__container {
    padding-top: 175px;
    padding-bottom: 50px;
    background-image: url("../img/helpers/bg-md.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-image: image-set(url("../img/helpers/bg-md.webp") 1x, url("../img/helpers/bg-md@2x.webp") 2x, url("../img/helpers/bg-md.png") 1x, url("../img/helpers/bg-md@2x.png") 2x);
  }
}
.helpers__container__tooltip {
  position: absolute;
  bottom: 72px;
  left: 10px;
  padding: 16px 8px;
  max-width: 210px;
  border-radius: 6px;
  background-color: #fff;
  font-size: 12px;
  line-height: 15px;
  z-index: 10;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}
@media (min-width: 768px) {
  .helpers__container__tooltip {
    padding: 18px 19px;
    max-width: 298px;
    font-size: 16px;
    line-height: 19px;
    left: 50px;
    bottom: 78px;
  }
}
@media (min-width: 992px) {
  .helpers__container__tooltip {
    left: 50%;
    bottom: 78px;
    transform: translateX(-150px);
  }
}
.helpers__container__tooltip:after {
  content: "";
  position: absolute;
  right: -8px;
  top: 10px;
  display: block;
  width: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 8px solid #fff;
}
.helpers__desc ul {
  list-style: none outside;
}
.helpers__desc ul li {
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 17px;
}
@media (min-width: 768px) {
  .helpers__desc ul li {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 22px;
  }
}
.helpers__desc ul li:before {
  content: "—";
  font-weight: 700;
  margin-right: 10px;
  color: #b3da26;
}

.working {
  margin-top: 55px;
  margin-bottom: 55px;
  font-size: 12px;
  line-height: 16px;
  color: #68717a;
}
@media (max-width: 767px) {
  .working--partners-students .working__title {
    margin-bottom: 0;
  }
}
.working p:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .working {
    margin-top: 100px;
    margin-bottom: 80px;
    font-size: 16px;
    line-height: 26px;
  }
}
.working__title {
  margin-bottom: 30px;
  color: #000;
}
.working__item {
  width: 200px;
  margin: 0 auto;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000;
}
@media (min-width: 768px) {
  .working__item {
    width: 270px;
  }
}
@media (min-width: 992px) {
  .working__item {
    width: 230px;
  }
}
.working__item__title--partners-students {
  font-family: "GothamPro", "Arial", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 128%;
  color: #000;
  margin: 0 auto 16px;
  text-transform: uppercase;
}
.working__item.working__item--partners-students {
  width: 100%;
  position: relative;
}
@media (min-width: 992px) {
  .working__item.working__item--partners-students {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .working__item.working__item--partners-students {
    max-width: 200px;
  }
}
.working__item--first:after {
  content: "";
  display: none;
  width: 105px;
  height: 139px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("../img/working/arrow1.svg") no-repeat 50% 50%;
  transform: translateX(50px) translateY(20px);
}
@media (min-width: 768px) {
  .working__item--first:after {
    display: block;
  }
}
@media (min-width: 1200px) {
  .working__item--first:after {
    transform: translateX(50px) translateY(40px);
  }
}
@media (max-width: 1023px) {
  .working__item--first.working__item--partners-students {
    grid-area: first;
  }
}
.working__item--first.working__item--partners-students:after {
  content: "";
  display: none;
  width: 125px;
  height: 139px;
  position: absolute;
  top: 0;
  right: 10px;
  background: url("../img/working/arrow2.svg") no-repeat 50% 50%;
  transform: translateX(81px) translateY(160px);
}
@media (min-width: 768px) {
  .working__item--first.working__item--partners-students:after {
    display: block;
  }
}
@media (min-width: 1200px) {
  .working__item--first.working__item--partners-students:after {
    transform: translateX(71px) translateY(145px);
  }
}
.working__item--second:after {
  content: "";
  display: none;
  width: 125px;
  height: 139px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("../img/working/arrow2.svg") no-repeat 50% 50%;
  transform: translateX(81px) translateY(160px);
}
@media (min-width: 992px) {
  .working__item--second:after {
    display: block;
  }
}
@media (min-width: 1200px) {
  .working__item--second:after {
    transform: translateX(71px) translateY(145px);
  }
}
@media (max-width: 1023px) {
  .working__item--second.working__item--partners-students {
    grid-area: second;
  }
}
.working__item--second.working__item--partners-students:after {
  content: none;
}
.working__item--trith:after {
  content: "";
  display: none;
  width: 105px;
  height: 139px;
  position: absolute;
  top: 0;
  right: 0;
  background: url("../img/working/arrow3.svg") no-repeat 50% 50%;
  transform: translateX(65px) translateY(0px);
}
@media (min-width: 768px) {
  .working__item--trith:after {
    display: block;
  }
}
@media (min-width: 1200px) {
  .working__item--trith:after {
    transform: translateX(50px) translateY(10px);
  }
}
@media (max-width: 1023px) {
  .working__item--trith.working__item--partners-students {
    grid-area: third;
  }
}
.working__item--trith.working__item--partners-students:after {
  content: "";
  display: none;
  width: 105px;
  height: 139px;
  position: absolute;
  top: 0;
  left: -150px;
  background: url("../img/working/arrow1.svg") no-repeat 50% 50%;
  transform: translateX(50px) translateY(20px);
}
@media (min-width: 768px) {
  .working__item--trith.working__item--partners-students:after {
    display: block;
  }
}
@media (min-width: 1200px) {
  .working__item--trith.working__item--partners-students:after {
    transform: translateX(50px) translateY(40px);
  }
}
@media (max-width: 1023px) {
  .working__item--trith.working__item--partners-students:after {
    top: -140px;
    left: 60%;
    transform: translateX(-50%) translateY(20px) rotate(130deg);
  }
}
@media (max-width: 767px) {
  .working__item--trith .working__item__pic--partners-students img {
    min-width: 252px;
  }
}
.working__item__pic {
  width: 100%;
  margin: 0 auto 22px;
  position: relative;
  display: flex;
  justify-content: center;
}
.working__item__pic img {
  width: 100%;
  max-width: 312px;
  height: auto;
}
@media (min-width: 768px) {
  .working__item__pic img {
    display: block;
    position: relative;
  }
}
@media (min-width: 1200px) {
  .working__item__pic img {
    display: block;
    position: relative;
  }
}
.working__item__pic--partners-students {
  max-height: 284px;
  max-width: 274px;
  height: 284px;
  width: 274px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
@media (max-width: 767px) {
  .working__item__pic--partners-students {
    width: 100%;
  }
}
.working__item__pic--partners-students img {
  max-height: 284px;
  max-width: 274px;
  object-fit: contain;
  object-position: bottom;
}
.working__item__desc {
  margin: 0 auto;
  font-size: 14px;
  line-height: 17px;
}
@media (min-width: 768px) {
  .working__item__desc {
    max-width: 230px;
    font-size: 18px;
    line-height: 22px;
  }
}
.working__item__desc--partners-students {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .working__item__desc--partners-students {
    max-width: 280px;
  }
}
.working__catalog {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .working__catalog {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .working__catalog {
    max-width: none;
  }
}
.working__catalog--partners-students {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
}
@media (min-width: 768px) {
  .working__catalog--partners-students {
    max-width: none;
  }
}
@media (max-width: 1023px) {
  .working__catalog--partners-students {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "first second" "third third";
    row-gap: 23px;
  }
}
@media (max-width: 767px) {
  .working__catalog--partners-students {
    display: flex;
    flex-direction: column;
  }
}

.jumbotron {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
}
@media (min-width: 768px) {
  .jumbotron {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.jumbotron > .container > .row > .col-12 {
  padding: 20px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.jumbotron--mainpage {
  overflow: hidden;
  background: #f2f2f2 url("../img/jumbotron/mainpage-xs.png") no-repeat 100% 100%;
  background-image: image-set(url("../img/jumbotron/mainpage-xs.webp") 1x, url("../img/jumbotron/mainpage-xs@2x.webp") 2x, url("../img/jumbotron/mainpage-xs.png") 1x, url("../img/jumbotron/mainpage-xs@2x.png") 2x);
}
@media (min-width: 768px) {
  .jumbotron--mainpage {
    background: #f2f2f2;
  }
}
.jumbotron--mainpage:before {
  content: "";
  display: none;
  width: 320px;
  height: 330px;
  background: url("../img/jumbotron/mainpage-xs.png") no-repeat 100% 100%;
  background-image: image-set(url("../img/jumbotron/mainpage-xs.webp") 1x, url("../img/jumbotron/mainpage-xs@2x.webp") 2x, url("../img/jumbotron/mainpage-xs.png") 1x, url("../img/jumbotron/mainpage-xs@2x.png") 2x);
  position: absolute;
  top: 663px;
  right: 0%;
  z-index: 1;
}
@media (min-width: 768px) {
  .jumbotron--mainpage:before {
    display: block;
    width: 100%;
    height: 900px;
    top: 0;
    left: 0%;
    background: url("../img/jumbotron/mainpage-md.png") no-repeat 100% 0%;
    background-image: image-set(url("../img/jumbotron/mainpage-md.webp") 1x, url("../img/jumbotron/mainpage-md@2x.webp") 2x, url("../img/jumbotron/mainpage-md.png") 1x, url("../img/jumbotron/mainpage-md@2x.png") 2x);
    transform: translateX(0px) translateY(0px);
  }
}
@media (min-width: 992px) {
  .jumbotron--mainpage:before {
    width: 50%;
    height: 880px;
    left: 50%;
    background: url("../img/jumbotron/mainpage.png") no-repeat 0% 100%;
    background-image: image-set(url("../img/jumbotron/mainpage.webp") 1x, url("../img/jumbotron/mainpage@2x.webp") 2x, url("../img/jumbotron/mainpage.png") 1x, url("../img/jumbotron/mainpage@2x.png") 2x);
  }
}
@media (min-width: 1200px) {
  .jumbotron--mainpage:before {
    width: 100%;
    height: 880px;
    transform: translateX(-130px) translateY(0px);
    background: url("../img/jumbotron/mainpage.png") no-repeat 0% 100%;
    background-image: image-set(url("../img/jumbotron/mainpage.webp") 1x, url("../img/jumbotron/mainpage@2x.webp") 2x, url("../img/jumbotron/mainpage.png") 1x, url("../img/jumbotron/mainpage@2x.png") 2x);
  }
}
.jumbotron--mainpage .jumbotron__desc {
  font-size: 18px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .jumbotron--mainpage .jumbotron__desc {
    margin: 0 auto;
  }
}
.jumbotron--servicies, .jumbotron--contacts, .jumbotron--service, .jumbotron--about, .jumbotron--worktape, .jumbotron--worktape-inner, .jumbotron--working-conditions, .jumbotron--partners, .jumbotron--promocodes, .jumbotron--authors-signup, .jumbotron--partners-students, .jumbotron--partners-agency {
  padding-bottom: 0;
  padding-top: 0;
  flex-direction: column;
  min-height: 454px;
}
@media (min-width: 768px) {
  .jumbotron--servicies, .jumbotron--contacts, .jumbotron--service, .jumbotron--about, .jumbotron--worktape, .jumbotron--worktape-inner, .jumbotron--working-conditions, .jumbotron--partners, .jumbotron--promocodes, .jumbotron--authors-signup, .jumbotron--partners-students, .jumbotron--partners-agency {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.jumbotron--servicies .container, .jumbotron--contacts .container, .jumbotron--service .container, .jumbotron--about .container, .jumbotron--worktape .container, .jumbotron--worktape-inner .container, .jumbotron--working-conditions .container, .jumbotron--partners .container, .jumbotron--promocodes .container, .jumbotron--authors-signup .container, .jumbotron--partners-students .container, .jumbotron--partners-agency .container {
  display: flex;
  flex-grow: 1;
  padding-top: 0;
  padding-bottom: 276px;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: auto 290px;
}
@media (min-width: 768px) {
  .jumbotron--servicies .container, .jumbotron--contacts .container, .jumbotron--service .container, .jumbotron--about .container, .jumbotron--worktape .container, .jumbotron--worktape-inner .container, .jumbotron--working-conditions .container, .jumbotron--partners .container, .jumbotron--promocodes .container, .jumbotron--authors-signup .container, .jumbotron--partners-students .container, .jumbotron--partners-agency .container {
    background-position: 140% 100%;
    background-size: auto 454px;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .jumbotron--servicies .container, .jumbotron--contacts .container, .jumbotron--service .container, .jumbotron--about .container, .jumbotron--worktape .container, .jumbotron--worktape-inner .container, .jumbotron--working-conditions .container, .jumbotron--partners .container, .jumbotron--promocodes .container, .jumbotron--authors-signup .container, .jumbotron--partners-students .container, .jumbotron--partners-agency .container {
    background-position: 100% 100%;
    /* background-size: 480px 434px; */
    padding-top: 0;
    padding-bottom: 0;
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .jumbotron--servicies .container, .jumbotron--contacts .container, .jumbotron--service .container, .jumbotron--about .container, .jumbotron--worktape .container, .jumbotron--worktape-inner .container, .jumbotron--working-conditions .container, .jumbotron--partners .container, .jumbotron--promocodes .container, .jumbotron--authors-signup .container, .jumbotron--partners-students .container, .jumbotron--partners-agency .container {
    max-width: 1140px;
  }
}
.jumbotron--servicies .container .row, .jumbotron--contacts .container .row, .jumbotron--service .container .row, .jumbotron--about .container .row, .jumbotron--worktape .container .row, .jumbotron--worktape-inner .container .row, .jumbotron--working-conditions .container .row, .jumbotron--partners .container .row, .jumbotron--promocodes .container .row, .jumbotron--authors-signup .container .row, .jumbotron--partners-students .container .row, .jumbotron--partners-agency .container .row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .jumbotron--servicies .container .row, .jumbotron--contacts .container .row, .jumbotron--service .container .row, .jumbotron--about .container .row, .jumbotron--worktape .container .row, .jumbotron--worktape-inner .container .row, .jumbotron--working-conditions .container .row, .jumbotron--partners .container .row, .jumbotron--promocodes .container .row, .jumbotron--authors-signup .container .row, .jumbotron--partners-students .container .row, .jumbotron--partners-agency .container .row {
    max-width: 758px;
  }
}
.jumbotron--authors-signup .container {
  background-image: url("../img/working-conditions/bg.png");
  background-image: image-set(url("../img/working-conditions/bg.webp") 1x, url("../img/working-conditions/bg@2x.webp") 2x, url("../img/working-conditions/bg.png") 1x, url("../img/working-conditions/bg@2x.png") 2x);
}
.jumbotron--authors-signup .jumbotron__desc--full {
  max-width: 547px;
}
.jumbotron--authors-signup .jumbotron__breadcrumbs {
  padding: 0 10px;
}
.jumbotron--authors-signup .jumbotron__innerpage {
  height: 100%;
}
.jumbotron--authors-signup .jumbotron__innerpage .row {
  margin-left: 0;
  margin-right: 0;
  margin: auto 0;
}
.jumbotron--servicies .container {
  background-image: url("../img/servicies/bg-lg.png");
  background-image: image-set(url("../img/servicies/bg-lg.webp") 1x, url("../img/servicies/bg-lg@2x.webp") 2x, url("../img/servicies/bg-lg.png") 1x, url("../img/servicies/bg-lg@2x.png") 2x);
}
.jumbotron--promocodes .container {
  background-image: url("../img/promocodes/bg.png");
  background-image: image-set(url("../img/promocodes/bg.webp") 1x, url("../img/promocodes/bg@2x.webp") 2x, url("../img/promocodes/bg.png") 1x, url("../img/promocodes/bg@2x.png") 2x);
}
@media (min-width: 768px) {
  .jumbotron--promocodes .container {
    background-position: 170px 100%;
  }
}
@media (min-width: 992px) {
  .jumbotron--promocodes .container {
    background-position: 100% 100%;
  }
}
.jumbotron--promocodes .jumbotron__suptitle {
  text-align: center;
}
@media (min-width: 768px) {
  .jumbotron--promocodes .jumbotron__suptitle {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .jumbotron--promocodes .jumbotron__desc {
    max-width: 500px;
  }
}
@media (min-width: 1200px) {
  .jumbotron--promocodes .jumbotron__desc {
    max-width: none;
  }
}
.jumbotron--partners .container {
  position: relative;
  background-image: url("../img/partners/main-bg@1x.png");
  background-image: image-set(url("../img/partners/main-bg@1x.webp") 1x, url("../img/partners/main-bg@2x.webp") 2x, url("../img/partners/main-bg@1x.png") 1x, url("../img/partners/main-bg@2x.png") 2x);
}
.jumbotron--partners .partners__main--second-text {
  font-weight: 700;
}
.jumbotron--partners .jumbotron__title h1 {
  position: relative;
  display: inline;
}
.jumbotron--partners .jumbotron__title h1 svg {
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translate(100%, -50%);
  width: 26px;
  height: auto;
}
@media (min-width: 768px) {
  .jumbotron--partners .jumbotron__title h1 svg {
    width: 38px;
    height: 54px;
  }
}
.jumbotron--partners .jumbotron__filter__tooltip {
  position: absolute;
  right: auto;
  left: 15px;
  bottom: 54px;
  top: auto;
}
@media (min-width: 768px) {
  .jumbotron--partners .jumbotron__filter__tooltip {
    left: auto;
    right: 135px;
  }
}
@media (min-width: 992px) {
  .jumbotron--partners .jumbotron__filter__tooltip {
    right: 335px;
  }
}
.jumbotron--partners-students .container {
  position: relative;
  background-image: url("../img/partners-students/main-bg@1x.png");
  background-image: image-set(url("../img/partners-students/main-bg@1x.webp") 1x, url("../img/partners-students/main-bg@2x.webp") 2x, url("../img/partners-students/main-bg@1x.png") 1x, url("../img/partners-students/main-bg@2x.png") 2x);
}
.jumbotron--partners-students .partners-student__header-button {
  text-transform: uppercase;
}
@media (max-width: 500px) {
  .jumbotron--partners-students .partners-student__header-button {
    width: 100%;
  }
}
.jumbotron--partners-students .jumbotron__innerpage {
  height: 100%;
}
.jumbotron--partners-students .jumbotron__innerpage > .experts-breadcrumbs {
  margin-bottom: 0;
}
.jumbotron--partners-students .jumbotron__innerpage > .row {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.jumbotron--partners-students > .container > .row > .col-12.partner-student-header-container {
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .jumbotron--partners-students .partner-student-header-container .jumbotron__desc {
    text-align: left;
    width: 100%;
  }
}
.jumbotron--partners-students .partner-student-header-container .jumbotron__breadcrumbs {
  margin-bottom: 0;
}
.jumbotron--partners-students .partner-student-header-container .jumbotron__suptitle {
  margin: 10px 0 8px;
}
.jumbotron--partners-students .partners-student__header-text {
  margin: 0 0 30px;
}
.jumbotron--partners-students .partners-student__header-text-blue {
  font-weight: 700;
  color: #0a71cc;
}
.jumbotron--partners-students .partners-student__header-text-dark {
  font-weight: 700;
  color: #000;
}
.jumbotron--partners-students .jumbotron__suptitle {
  margin: 0 0 8px;
}
.jumbotron--partners-agency .container {
  position: relative;
  background-image: url("../img/partners-agency/agency-jumbotron.png");
  background-image: image-set(url("../img/partners-agency/agency-jumbotron.webp") 1x, url("../img/partners-agency/agency-jumbotron@2x.webp") 2x, url("../img/partners-agency/agency-jumbotron.png") 1x, url("../img/partners-agency/agency-jumbotron2x.png") 2x);
}
.jumbotron--partners-agency .jumbotron__innerpage {
  height: 100%;
}
.jumbotron--partners-agency .jumbotron__innerpage > .experts-breadcrumbs {
  margin-bottom: 0;
}
.jumbotron--partners-agency .jumbotron__innerpage > .row {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.jumbotron--partners-agency > .container > .row > .col-12.partner-student-header-container {
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .jumbotron--partners-agency .partner-student-header-container .jumbotron__desc {
    text-align: left;
    width: 100%;
  }
}
.jumbotron--partners-agency .partner-student-header-container .jumbotron__breadcrumbs {
  margin-bottom: 0;
}
.jumbotron--partners-agency .partner-student-header-container .jumbotron__suptitle {
  margin: 10px 0 8px;
}
.jumbotron--partners-agency .partners-student__header-text {
  margin: 0 0 30px;
}
.jumbotron--partners-agency .partners-student__header-text-blue {
  font-weight: 700;
  color: #0a71cc;
}
.jumbotron--partners-agency .partners-student__header-text-dark {
  font-weight: 700;
  color: #000;
}
.jumbotron--partners-agency .jumbotron__suptitle {
  margin: 0 0 8px;
}
.jumbotron--contacts .container {
  background-image: url("../img/contacts/bg-xs.png");
  background-image: image-set(url("../img/contacts/bg-xs.webp") 1x, url("../img/contacts/bg-xs@2x.webp") 2x, url("../img/contacts/bg-xs.png") 1x, url("../img/contacts/bg-xs@2x.png") 2x);
}
.jumbotron--service .container {
  background-image: url("../img/servicies/service-bg-lg.png");
  background-image: image-set(url("../img/servicies/service-bg-lg.webp") 1x, url("../img/servicies/service-bg-lg@2x.webp") 2x, url("../img/servicies/service-bg-lg.png") 1x, url("../img/servicies/service-bg-lg@2x.png") 2x);
}
.jumbotron--about .container {
  background-image: url("../img/about/bg.png");
  background-image: image-set(url("../img/about/bg.webp") 1x, url("../img/about/bg@2x.webp") 2x, url("../img/about/bg.png") 1x, url("../img/about/bg@2x.png") 2x);
}
@media (min-width: 768px) {
  .jumbotron--about .container {
    background-size: auto 434px;
  }
}
.jumbotron--about ~ .about-content .about-content__tabs__btn__right {
  display: none;
}
.jumbotron--about ~ .about-content .about-content__tabs__btn__right.current {
  display: flex;
}
.jumbotron--worktape .container {
  background-image: url("../img/worktape/worktape-bg-lg.png");
  background-image: image-set(url("../img/worktape/worktape-bg-lg.webp") 1x, url("../img/worktape/worktape-bg-lg@2x.webp") 2x, url("../img/worktape/worktape-bg-lg.png") 1x, url("../img/worktape/worktape-bg-lg@2x.png") 2x);
}
@media (min-width: 992px) {
  .jumbotron--worktape .container {
    background-size: 440px 434px;
  }
}
.jumbotron--worktape-inner .container {
  background-image: url("../img/worktape/worktape-inner-bg-lg.png");
  background-image: image-set(url("../img/worktape/worktape-inner-bg-lg.webp") 1x, url("../img/worktape/worktape-inner-bg-lg@2x.webp") 2x, url("../img/worktape/worktape-inner-bg-lg.png") 1x, url("../img/worktape/worktape-inner-bg-lg@2x.png") 2x);
}
.jumbotron--working-conditions .container {
  background-image: url("../img/working-conditions/bg.png");
  background-image: image-set(url("../img/working-conditions/bg.webp") 1x, url("../img/working-conditions/bg@2x.webp") 2x, url("../img/working-conditions/bg.png") 1x, url("../img/working-conditions/bg@2x.png") 2x);
}
.jumbotron--working-conditions .jumbotron__desc--full {
  max-width: 547px;
}
.jumbotron__innerpage {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.jumbotron__breadcrumbs {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 120%;
  max-width: 680px;
}
@media (min-width: 768px) {
  .jumbotron__breadcrumbs {
    font-size: 18px;
    line-height: 120%;
  }
}
.jumbotron__breadcrumbs a {
  color: inherit;
}
.jumbotron__breadcrumbs--worktape {
  margin-bottom: 47px;
}
@media (min-width: 768px) {
  .jumbotron__breadcrumbs--worktape {
    margin-bottom: 38px;
  }
}
@media (min-width: 992px) {
  .jumbotron__breadcrumbs--worktape {
    margin-bottom: 60px;
  }
}
.jumbotron__suptitle {
  margin-bottom: 12px;
  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .jumbotron__suptitle {
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .jumbotron__suptitle {
    font-size: 30px;
    line-height: 150%;
  }
}
.jumbotron__parallax {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 100%;
  max-width: 1200px;
  height: 100%;
}
@media (min-width: 992px) {
  .jumbotron__parallax {
    display: block;
  }
}
.jumbotron__parallax__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.jumbotron__parallax__item--1 {
  z-index: 4;
  background: url("../img/parallax/1.png") no-repeat 80% 60%;
}
.jumbotron__parallax__item--2 {
  z-index: 3;
  background: url("../img/parallax/2.png") no-repeat 0 0;
}
.jumbotron__parallax__item--3 {
  z-index: 2;
  background: url("../img/parallax/3.png") no-repeat 85% 0;
}
.jumbotron__title, .jumbotron__title h1 {
  position: relative;
  z-index: 10;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: none;
  color: #0a71cc;
}
@media (min-width: 768px) {
  .jumbotron__title, .jumbotron__title h1 {
    font-size: 72px;
    line-height: 1;
    text-align: left;
  }
}
.jumbotron__desc {
  position: relative;
  z-index: 10;
  max-width: 340px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
}
@media (min-width: 768px) {
  .jumbotron__desc {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    text-align: left;
  }
}
.jumbotron__desc--full {
  max-width: 100%;
}
.jumbotron__desc__statistics {
  margin: 15px 0;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
}
@media (min-width: 768px) {
  .jumbotron__desc__statistics {
    font-size: 18px;
    line-height: 22px;
    margin: 30px 0 0;
  }
}
.jumbotron__desc__statistics h4 {
  margin-bottom: 11px;
  font-size: 18px;
  line-height: 100%;
  font-family: "Proxima Nova" !important;
}
@media (min-width: 768px) {
  .jumbotron__desc__statistics h4 {
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 8px;
  }
}
.jumbotron__desc__statistics .delimtr {
  margin-bottom: 11px;
}
@media (min-width: 768px) {
  .jumbotron__desc__statistics .delimtr {
    margin-bottom: 8px;
  }
}
.jumbotron__desc ul {
  list-style: none outside;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}
@media (min-width: 768px) {
  .jumbotron__desc ul {
    font-size: 18px;
    line-height: 22px;
  }
}
.jumbotron__desc ul li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 32px;
}
@media (min-width: 768px) {
  .jumbotron__desc ul li {
    margin-bottom: 22px;
  }
}
.jumbotron__desc ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background: url("../img/icons/good.svg") no-repeat 50% 50%;
}
.jumbotron__button-wrappers {
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
}
@media (max-width: 767px) {
  .jumbotron__button-wrappers {
    justify-content: space-between;
  }
}
@media (max-width: 499px) {
  .jumbotron__button-wrappers {
    flex-direction: column;
  }
}
.jumbotron__reviews {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  width: 440px;
  margin-top: 20px;
}
.jumbotron__reviews__pic {
  padding-right: 24px;
}
.jumbotron__reviews__pic img {
  width: 180px;
  height: 189px;
  max-width: none;
}
.jumbotron__reviews__desc {
  font-size: 14px;
  line-height: 120%;
  padding: 50px 0 0;
}
.jumbotron__filter {
  position: relative;
  z-index: 10;
  margin-top: 100px;
  padding: 20px 10px;
  border-radius: 6px;
  background-color: #fff;
  max-width: 988px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 258px;
}
.jumbotron__filter span.error {
  display: none !important;
}
.jumbotron__filter .selectric-items {
  border-radius: 6px;
  top: auto;
  bottom: 100%;
}
.jumbotron__filter .selectric-form__field .selectric {
  height: 100%;
}
.jumbotron__filter .row {
  margin-left: -8px;
  margin-right: -8px;
}
.jumbotron__filter .row > .col,
.jumbotron__filter .row > [class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}
@media (min-width: 768px) {
  .jumbotron__filter {
    margin-top: 230px;
    padding: 46px 56px 33px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .jumbotron__filter {
    margin-top: 20px;
    padding: 46px 66px 33px;
  }
}
.jumbotron__filter .form__field {
  margin-bottom: 22px;
}
.jumbotron__filter__tooltip {
  position: absolute;
  top: -84px;
  right: 8px;
  padding: 18px 19px;
  border-radius: 6px;
  background-color: #fff;
  font-size: 16px;
  line-height: 19px;
  z-index: 50;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
@media (min-width: 768px) {
  .jumbotron__filter__tooltip {
    right: auto;
    left: 0;
  }
}
@media (min-width: 992px) {
  .jumbotron__filter__tooltip {
    right: 66px;
    left: auto;
  }
}
.jumbotron__filter__tooltip:before {
  content: "";
  position: absolute;
  top: -16px;
  right: -8px;
  display: block;
  width: 46px;
  height: 46px;
  border-radius: 6px;
  background: rgba(10, 113, 204, 0.35) url("../img/good.svg") no-repeat 50% 50%;
  backdrop-filter: blur(10px);
}
.jumbotron__filter__tooltip:after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: -8px;
  display: block;
  width: 0;
  border-top: 8px solid #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.jumbotron__filter__agreement {
  width: 100%;
  padding-top: 8px;
  font-size: 12px;
  letter-spacing: 0.04em;
}
.jumbotron__filter__agreement a {
  color: black;
}
@media (min-width: 768px) {
  .jumbotron__filter__agreement {
    position: absolute;
    bottom: 20px;
    left: 0;
    padding-top: 0;
    text-align: center;
  }
}
.jumbotron__filter__agreement input.error + label {
  color: #dc3545;
}
.jumbotron__filter__agreement input.error + label a {
  color: inherit;
}
.jumbotron__filter__agreement input[type=checkbox]:checked + .filter-agreement__label:after, .jumbotron__filter__agreement input[type=checkbox]:checked + .filter-agreement__label:before, .jumbotron__filter__agreement input[type=checkbox]:not(:checked) + .filter-agreement__label:after, .jumbotron__filter__agreement input[type=checkbox]:not(:checked) + .filter-agreement__label:before {
  top: 50%;
  transform: translate(0, -50%);
}
.jumbotron__filter__agreement input[type=checkbox]:checked + .filter-agreement__label:after, .jumbotron__filter__agreement input[type=checkbox]:not(:checked) + .filter-agreement__label:after {
  left: 9px;
  transform: translate(-50%, -50%);
}

.principles {
  margin-top: 55px;
  margin-bottom: 55px;
}
@media (min-width: 768px) {
  .principles {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.principles__title {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .principles__title {
    margin-bottom: 50px;
  }
}
.principles__list, .principles .row:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
}
@media (min-width: 992px) {
  .principles__list, .principles .row:nth-child(2) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.principles ul {
  list-style: none outside;
}
.principles ul li {
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .principles ul li {
    margin-bottom: 22px;
  }
}
.principles ul li:before {
  content: "—";
  font-weight: 700;
  display: inline-block;
  margin-top: -4px;
  margin-right: 10px;
  vertical-align: middle;
  color: #b3da26;
}
.principles__item {
  width: 100%;
  flex: 0 0 auto;
  overflow: hidden;
  margin: 0 auto 22px;
  padding: 0;
  max-width: 400px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .principles__item {
    max-width: 500px;
    margin: 0 auto 22px;
  }
}
@media (min-width: 992px) {
  .principles__item {
    margin: 0 auto;
    max-width: none;
  }
}
.principles__item__pic {
  background-color: #b3da26;
}
.principles__item__pic img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.principles__item__desc {
  padding: 30px 22px;
  color: #68717a;
  font-size: 12px;
  line-height: 16px;
}
@media (min-width: 768px) {
  .principles__item__desc {
    font-size: 16px;
    line-height: 20px;
  }
}
.principles__item__desc h4 {
  margin-bottom: 8px;
  font-family: "Proxima Nova";
  text-transform: none;
  color: #343434;
}
@media (min-width: 768px) {
  .principles__item__desc h4 {
    margin-bottom: 22px;
    font-size: 18px !important;
    line-height: 24px !important;
  }
}
.principles__item:first-of-type .principles__item__pic {
  background-color: #0a71cc;
}
.principles__item:last-of-type {
  margin-bottom: 0;
}
.principles__item:last-of-type .principles__item__pic {
  background-color: #0a71cc;
}

.work-is-simple {
  margin-top: 55px;
  margin-bottom: 55px;
  font-size: 12px;
  line-height: 16px;
  color: #68717a;
}
@media (min-width: 768px) {
  .work-is-simple {
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 16px;
    line-height: 26px;
  }
}
.work-is-simple h2 {
  color: initial;
}
.work-is-simple__pic {
  height: auto;
  display: block;
  margin: 0 auto 30px;
  max-width: 100%;
}
.work-is-simple .editor-text ul {
  margin-bottom: 29px;
  list-style-type: disc;
  font-size: 16px;
  line-height: 20px;
  color: #68717a;
}
.work-is-simple .editor-text ul li {
  position: relative;
  margin-bottom: 22px;
  padding-left: 0;
}
.work-is-simple .editor-text ul li:before {
  content: none;
}
.work-is-simple ul {
  margin-bottom: 29px;
  list-style: none outside;
  font-size: 16px;
  line-height: 20px;
  color: #68717a;
}
.work-is-simple ul li {
  position: relative;
  margin-bottom: 22px;
  padding-left: 32px;
}
.work-is-simple ul li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: block;
  width: 20px;
  height: 20px;
  background: url("../img/icons/good.svg") no-repeat 50% 50%;
}
.work-is-simple .btn {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@media (min-width: 768px) {
  .work-is-simple .btn {
    width: 202px;
  }
}
@media (min-width: 992px) {
  .work-is-simple .btn {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .work-is-simple--partners, .work-is-simple--partners-students {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.work-is-simple--partners .row, .work-is-simple--partners-students .row {
  margin-left: 0;
  margin-right: 0;
}
.work-is-simple--partners h2, .work-is-simple--partners-students h2 {
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.02px;
  margin: 0 0 8px;
}
@media (min-width: 1200px) {
  .work-is-simple--partners h2, .work-is-simple--partners-students h2 {
    font-size: 30px;
  }
}
.work-is-simple--partners .work-is-simple__pic, .work-is-simple--partners-students .work-is-simple__pic {
  width: 372px;
  height: auto;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .work-is-simple--partners .work-is-simple__pic, .work-is-simple--partners-students .work-is-simple__pic {
    width: 320px;
  }
}
@media (min-width: 1200px) {
  .work-is-simple--partners .work-is-simple__pic, .work-is-simple--partners-students .work-is-simple__pic {
    width: 372px;
  }
}
.work-is-simple--partners .work-is-simple__text, .work-is-simple--partners-students .work-is-simple__text {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000;
  margin: 0 0 22px;
}
.work-is-simple--partners .btn, .work-is-simple--partners-students .btn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  padding: 16px 43px;
  width: 100%;
}
@media (min-width: 768px) {
  .work-is-simple--partners .btn, .work-is-simple--partners-students .btn {
    width: max-content;
  }
}
@media (min-width: 992px) {
  .work-is-simple--partners .btn, .work-is-simple--partners-students .btn {
    margin-left: 0;
    margin-right: 0;
  }
}
.work-is-simple--partners span.error, .work-is-simple--partners-students span.error {
  display: none !important;
}
.work-is-simple--partners .form__field, .work-is-simple--partners-students .form__field {
  margin-bottom: 22px;
}
.work-is-simple--partners .form__field:last-of-type, .work-is-simple--partners-students .form__field:last-of-type {
  margin-bottom: 10px;
}
.work-is-simple--partners .col-12 > .col-12, .work-is-simple--partners-students .col-12 > .col-12 {
  padding-left: 0;
  padding-right: 0;
}
.work-is-simple--partners form, .work-is-simple--partners-students form {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .work-is-simple--partners form, .work-is-simple--partners-students form {
    margin-bottom: 0;
  }
}
.work-is-simple--partners-students form {
  margin: 0;
}
.work-is-simple__partners-students-form {
  padding: 0 0 0 160px;
}
@media (max-width: 1023px) {
  .work-is-simple__partners-students-form {
    padding: 0;
    margin: 21px 0 0 0;
  }
  .work-is-simple__partners-students-form .btn {
    margin-left: 0;
  }
  .work-is-simple__partners-students-form h2, .work-is-simple__partners-students-form .work-is-simple__text {
    display: none;
  }
}
.work-is-simple__partners-students-picture h2, .work-is-simple__partners-students-picture .work-is-simple__text {
  display: none;
}
@media (max-width: 1023px) {
  .work-is-simple__partners-students-picture h2 {
    display: block;
  }
  .work-is-simple__partners-students-picture .work-is-simple__text {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  .we-company .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (min-width: 992px) {
  .we-company__title {
    margin-bottom: 55px;
  }
}
.we-company__item {
  margin-top: 22px;
  padding: 30px 37px 254px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  border-radius: 6px;
  background: #313845;
}
@media (min-width: 768px) {
  .we-company__item {
    min-height: 322px;
    padding: 50px;
    font-size: 16px;
    line-height: 26px;
  }
}
@media (min-width: 992px) {
  .we-company__item {
    height: calc(100% - 50px);
    margin-top: 50px;
    padding: 50px 140px 50px 50px;
  }
}
@media (min-width: 1200px) {
  .we-company__item {
    padding: 50px;
  }
}
.we-company__item.first-of-type {
  margin-top: 0;
}
.we-company__item--1 {
  position: relative;
}
@media (min-width: 768px) {
  .we-company__item--1 {
    background-position: 100% 100%;
    background-size: contain;
  }
}
.we-company__item--1:after {
  display: block;
  content: "";
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("../img/company/1-xs.png");
  background-image: image-set(url("../img/company/1-xs.webp") 1x, url("../img/company/1-xs@2x.webp") 2x, url("../img/company/1-xs.png") 1x, url("../img/company/1-xs@2x.png") 2x);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .we-company__item--1:after {
    background-image: url("../img/company/1-md.png");
    background-image: image-set(url("../img/company/1-md.webp") 1x, url("../img/company/1-md@2x.webp") 2x, url("../img/company/1-md.png") 1x, url("../img/company/1-md@2x.png") 2x);
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }
}
@media (min-width: 992px) {
  .we-company__item--1:after {
    top: -20px;
    background-image: url("../img/company/1.png");
    background-image: image-set(url("../img/company/1.webp") 1x, url("../img/company/1@2x.webp") 2x, url("../img/company/1.png") 1x, url("../img/company/1@2x.png") 2x);
    background-size: auto 340px;
  }
}
.we-company__item--2 {
  background-image: url("../img/company/2-xs.png");
  background-image: image-set(url("../img/company/2-xs.webp") 1x, url("../img/company/2-xs@2x.webp") 2x, url("../img/company/2-xs.png") 1x, url("../img/company/2-xs@2x.png") 2x);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 254px;
}
@media (min-width: 768px) {
  .we-company__item--2 {
    background-image: url("../img/company/2-md.png");
    background-image: image-set(url("../img/company/2-md.webp") 1x, url("../img/company/2-md@2x.webp") 2x, url("../img/company/2-md.png") 1x, url("../img/company/2-md@2x.png") 2x);
    background-position: 100% 100%;
    background-size: contain;
  }
}
@media (min-width: 992px) {
  .we-company__item--2 {
    background-image: url("../img/company/2.png");
    background-image: image-set(url("../img/company/2.webp") 1x, url("../img/company/2@2x.webp") 2x, url("../img/company/2.png") 1x, url("../img/company/2@2x.png") 2x);
    margin-right: 10px;
  }
}
.we-company__item--3 {
  background-image: url("../img/company/3-xs.png");
  background-image: image-set(url("../img/company/3-xs.webp") 1x, url("../img/company/3-xs@2x.webp") 2x, url("../img/company/3-xs.png") 1x, url("../img/company/3-xs@2x.png") 2x);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 254px;
}
@media (min-width: 768px) {
  .we-company__item--3 {
    background-image: url("../img/company/3-md.png");
    background-image: image-set(url("../img/company/3-md.webp") 1x, url("../img/company/3-md@2x.webp") 2x, url("../img/company/3-md.png") 1x, url("../img/company/3-md@2x.png") 2x);
    background-position: 100% 100%;
    background-size: contain;
  }
}
@media (min-width: 992px) {
  .we-company__item--3 {
    background-image: url("../img/company/3.png");
    background-image: image-set(url("../img/company/3.webp") 1x, url("../img/company/3@2x.webp") 2x, url("../img/company/3.png") 1x, url("../img/company/3@2x.png") 2x);
    margin-left: 10px;
  }
}
.we-company__item h4 {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 100%;
  font-family: inherit;
  color: #b3da26;
}
@media (min-width: 768px) {
  .we-company__item h4 {
    margin-bottom: 22px;
    font-size: 18px;
    line-height: 100%;
  }
}
.we-company__item__content {
  max-width: 340px;
  position: relative;
  z-index: 5;
}

.servicies__catalog {
  margin-top: 55px;
  margin-bottom: 55px;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .servicies__catalog {
    margin-top: 100px;
    margin-bottom: 60px;
    padding: 0;
  }
}
.servicies__catalog__item {
  position: relative;
  z-index: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0 20px 30px;
  border: 1px solid #dee2e6;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) {
  .servicies__catalog__item {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .servicies__catalog__item {
    border-right: none;
  }
}
.servicies__catalog__item:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 5px;
  background-color: #b3da26;
  transition: width 0.5s ease-out;
}
.servicies__catalog__item:hover {
  z-index: 10;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}
.servicies__catalog__item:hover:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
  background-color: #b3da26;
  transition: width 0.3s ease-in;
}
.servicies__catalog__item__pic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 151px;
  margin-right: -20px;
  margin-bottom: 30px;
  margin-left: -20px;
  background-color: #f2f2f2;
}
.servicies__catalog__item__desc {
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 26px;
  color: #68717a;
}
.servicies__catalog__item__desc h4 {
  margin-bottom: 6px;
  font-size: 18px;
  line-height: 24px;
  font-family: inherit;
  color: #343434;
  text-transform: none;
}
.servicies__catalog__item__desc h4 a {
  color: #343434;
  text-decoration: none;
}
.servicies__catalog__item__desc p {
  margin: 8px 0;
}
.servicies__catalog__item__desc p:first-of-type {
  margin-top: 6px;
}
.servicies__catalog__item__desc p:last-of-type {
  margin-bottom: 0;
}
.servicies__catalog__item .btn {
  margin-top: auto;
  text-transform: uppercase;
  color: #fff;
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .servicies__catalog__item:last-of-type, .servicies__catalog__item:nth-of-type(4n) {
    border-right: 1px solid #dee2e6;
    border-radius: 0 6px 6px 0;
  }
}
@media (min-width: 992px) {
  .servicies__catalog__item:first-of-type, .servicies__catalog__item:nth-of-type(5), .servicies__catalog__item:nth-of-type(9), .servicies__catalog__item:nth-of-type(13), .servicies__catalog__item:nth-of-type(17) {
    border-radius: 6px 0 0 6px;
  }
}

.calculator {
  margin-top: 22px;
  margin-bottom: 55px;
}
@media (min-width: 768px) {
  .calculator {
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .calculator {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.calculator .form__field {
  margin-bottom: 22px;
}
.calculator .form__field__label {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.calculator__title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.calculator__desc {
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 26px;
}
@media (min-width: 992px) {
  .calculator__steps {
    max-width: 570px;
  }
}
.calculator__steps__item {
  display: none;
}
.calculator__steps__item.current {
  display: block;
}
.calculator__steps__item .btn {
  margin-bottom: 22px;
}
@media (min-width: 768px) {
  .calculator__steps__item .btn {
    margin-bottom: 0;
  }
}
.calculator__steps-wrapper {
  display: flex;
  column-gap: 20px;
  align-items: center;
  margin: 0 0 20px;
}
.calculator__steps-wrapper .form__field__label {
  margin: 0;
}
.calculator__steps-menu {
  font-size: 16px;
  line-height: 26px;
  color: #abb5be;
  padding-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(171, 181, 190, 0.5);
}
@media (min-width: 992px) {
  .calculator__steps-menu {
    padding-top: 0;
    margin-bottom: 0px;
    border-bottom: none;
  }
}
.calculator__steps-menu ul {
  margin-bottom: 30px;
  list-style: none outside;
  counter-reset: section;
}
.calculator__steps-menu ul li {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.calculator__steps-menu ul li:before {
  content: counter(section);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  font-size: 16px;
  line-height: 119.3%;
  counter-increment: section;
  color: #fff;
  border-radius: 50%;
  background: #c8cdd2;
}
.calculator__steps-menu ul li.current {
  color: #000;
}
.calculator__steps-menu ul li.current:before {
  background-color: #0a71cc;
}
.calculator__progress {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(171, 181, 190, 0.5);
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .calculator__progress {
    padding: 50px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 0 30px rgba(74, 74, 74, 0.15);
    border-bottom: none;
  }
}
.calculator__progress__title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  font-family: "Proxima Nova";
  text-align: center;
}
@media (min-width: 992px) {
  .calculator__progress__title {
    text-align: left;
  }
}
.calculator__progress__procents {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 8px;
  display: inline-block;
}
@media (min-width: 992px) {
  .calculator__progress__procents {
    display: block;
  }
}
.calculator__progress__procents:after {
  content: "%";
}
.calculator__progress__line {
  position: relative;
  overflow: hidden;
  height: 8px;
  margin: 8px 0;
  border-radius: 20px;
  background: #ededed;
}
.calculator__progress__line__grey {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 8px;
  height: 8px;
  border-radius: 20px;
  background: #c8cdd2;
}
.calculator__progress__line__green {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  min-width: 8px;
  height: 8px;
  border-radius: 20px;
  background: #b3da26;
}
.calculator__progress__info {
  font-size: 18px;
  line-height: 22px;
  color: #c4c4c4;
  text-align: center;
}
@media (min-width: 992px) {
  .calculator__progress__info {
    text-align: left;
  }
}
.calculator__button-agreament-wrapper {
  padding: 0 10px;
}
.calculator__button-agreament-wrapper input.error + label {
  color: #dc3545;
}
.calculator__button-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .calculator__button-wrapper {
    flex-direction: row;
    column-gap: 20px;
  }
}
.calculator__button-wrapper button {
  display: none;
}
.calculator__button-wrapper .btn-lg {
  width: 100%;
  padding: 15.5px 2rem;
}
@media (min-width: 768px) {
  .calculator__button-wrapper .btn-lg {
    width: max-content;
  }
}
.calculator__button-wrapper .btn-block + .btn-block {
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .calculator__button-wrapper .btn-block + .btn-block {
    margin-top: 0;
  }
}
.calculator__button-wrapper .current {
  display: block;
}

.recomendation {
  margin-top: 55px;
  margin-bottom: 55px;
  font-size: 18px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .recomendation {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.recomendation__content {
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 16px;
}
@media (min-width: 768px) {
  .recomendation__content {
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 22px;
  }
}
@media (min-width: 992px) {
  .recomendation__content {
    max-width: 415px;
  }
}
.recomendation__video {
  position: relative;
  margin-left: 0;
}
@media (min-width: 768px) {
  .recomendation__video {
    margin-left: 105px;
  }
}
@media (min-width: 992px) {
  .recomendation__video {
    margin-left: 0;
  }
}
.recomendation__video__iframe {
  overflow: hidden;
  height: 195px;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .recomendation__video__iframe {
    height: 340px;
  }
}
@media (min-width: 992px) {
  .recomendation__video__iframe {
    height: 315px;
  }
}
.recomendation__video:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: -80px;
  display: none;
  width: 91px;
  height: 91px;
  background: url("../img/recomendation/smile.svg") no-repeat 50% 50%;
}
@media (min-width: 768px) {
  .recomendation__video:after {
    display: block;
  }
}
.recomendation__video iframe {
  display: block;
  margin: 0;
}

.contacts {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .contacts {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}
.contacts__content {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;
}
.contacts__content__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
}
.contacts__content__phone {
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
  margin-bottom: 8px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  .contacts__content__phone {
    font-size: 24px;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 0px;
  }
}
@media (min-width: 992px) {
  .contacts__content__phone {
    font-size: 24px;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 22px;
  }
}
.contacts__content__callback {
  width: 100%;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .contacts__content__callback {
    width: 202px;
  }
}
.contacts__map {
  height: 370px;
  width: 100%;
  background: url("../img/contacts/map.png") no-repeat 50% 50%;
}
.contacts__tabs {
  overflow: hidden;
}
.contacts__tabs__menu {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 50px;
  list-style: none outside;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #68717a;
  border-bottom: 2px solid #ededed;
}
.contacts__tabs__menu a {
  display: block;
  padding-bottom: 8px;
  text-decoration: none;
  color: inherit;
  border-bottom: 5px solid transparent;
}
.contacts__tabs__menu li {
  margin-right: 30px;
  margin-bottom: -2px;
  white-space: nowrap;
}
.contacts__tabs__menu li.current {
  color: #000;
}
.contacts__tabs__menu li.current a {
  border-bottom: 5px solid #b3da26;
}
.contacts__tabs__item {
  display: none;
}
.contacts__tabs__item.current {
  display: block;
}

.error-page {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .error-page {
    min-height: calc(100vh - 144px);
  }
}
.error-page__pic {
  display: block;
  margin: 0 auto 8px;
  max-width: 100%;
}
.error-page__title {
  margin-bottom: 8px;
}
.error-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  line-height: 120%;
}
@media (min-width: 768px) {
  .error-page__content {
    font-size: 18px;
    line-height: 24px;
    max-width: 726px;
  }
}
@media (min-width: 992px) {
  .error-page__content {
    max-width: 750px;
  }
}
.error-page__content .btn {
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .error-page__content .btn {
    width: 202px;
  }
}

@media (min-width: 992px) {
  .question-answer {
    display: block;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.question-answer__catalog__item {
  border: 1px solid #DEE2E6;
  border-radius: 6px;
  margin-bottom: 22px;
  padding: 15px 22px 15px 22px;
  min-height: 52px;
  position: relative;
}
.question-answer__catalog__item:after {
  position: absolute;
  top: 14px;
  right: 22px;
  content: "";
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #b3da26 url("../img/question-answer/plus.svg") no-repeat 50% 50%;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}
.question-answer__catalog__item.open:after {
  content: "";
  background: #b3da26 url("../img/question-answer/minus.svg") no-repeat 50% 50%;
}
.question-answer__catalog__item__title {
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  z-index: 10;
  padding-right: 46px;
}
@media (min-width: 992px) {
  .question-answer__catalog__item__title {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (min-width: 992px) {
  .question-answer__catalog__item__title h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
    text-transform: initial;
    font-weight: 300;
  }
}
.question-answer__catalog__item__content {
  display: none;
  font-size: 16px;
  line-height: 26px;
}
.question-answer__catalog__item__content ul {
  margin: 20px 0;
  padding-left: 22px;
}
.question-answer__catalog__item.open .question-answer__catalog__item__title {
  margin-bottom: 8px;
  color: #0a71cc;
}
.question-answer__catalog__item.open .question-answer__catalog__item__content {
  display: block;
}

.owl-carousel {
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}
.owl-carousel__navigation {
  display: flex;
  width: 80px;
  justify-content: space-between;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .owl-carousel__navigation {
    margin-bottom: 0;
  }
}
.owl-carousel__navigation button {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
  background-color: #fff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.owl-carousel__navigation button:hover {
  background-color: #DEE2E6;
}
.owl-carousel__navigation__prev {
  background-image: url("../img/reviews/left.svg");
}
.owl-carousel__navigation__next {
  background-image: url("../img/reviews/right.svg");
}

.owl-carousel .owl-stage {
  position: relative;
  -moz-backface-visibility: hidden;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  line-height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

.owl-carousel .owl-item {
  position: relative;
  float: left;
  min-height: 1px;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  padding: 0 !important;
  font: inherit;
  color: inherit;
  border: none;
  background: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  width: 22px;
  height: 40px;
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 40px;
  color: #fff !important;
  border: none;
  border-radius: 50%;
}

.owl-carousel .owl-nav {
  position: absolute;
  top: 170px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 40px;
}

.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 0;
  left: 5px;
  background: url("../img/arrow-left.svg") no-repeat 50% 50%;
}

.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 0;
  right: 5px;
  background: url("../img/arrow-right.svg") no-repeat 50% 50%;
}

.owl-carousel .owl-dots {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  margin-bottom: 88px;
}
@media (min-width: 1200px) {
  .owl-carousel .owl-dots {
    margin-bottom: 120px;
  }
}
.owl-carousel .owl-dots .owl-dot {
  width: 12px;
  height: 12px;
  margin: 0 3.5px;
  border: none;
  border-radius: 50%;
  background: rgba(13, 29, 74, 0.25);
}
.owl-carousel .owl-dots .owl-dot.active {
  background: rgb(13, 29, 74);
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  display: block;
  opacity: 0;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  calculation of the height of the owl-item that breaks page layouts
  */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  cursor: pointer;
  background: url("owl.video.play.png") no-repeat;
  transition: transform 100ms ease;
  -webkit-backface-visibility: hidden;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  height: 100%;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.worktape-filter {
  margin-bottom: 30px;
  padding: 20px 10px;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .worktape-filter {
    margin-bottom: 30px;
    padding: 50px 40px;
  }
}
@media (min-width: 992px) {
  .worktape-filter {
    margin-bottom: 30px;
    padding: 50px;
  }
}
.worktape-filter__title {
  margin-bottom: 22px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #0a71cc;
}
.worktape-filter .form__field, .worktape-filter .selectric-form__field {
  margin-bottom: 11px;
}
@media (min-width: 768px) {
  .worktape-filter .form__field, .worktape-filter .selectric-form__field {
    margin-bottom: 22px;
  }
}
.worktape-control {
  display: none;
  overflow: hidden;
  color: #000;
  border-radius: 16px;
  background: #b3da26 url("../img/bg.jpg") no-repeat 50% 50%;
  background-size: cover;
}
@media (min-width: 576px) {
  .worktape-control {
    display: block;
    padding: 48px 30px;
  }
}
@media (min-width: 768px) {
  .worktape-control {
    margin-bottom: 30px;
    padding: 40px 80px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .worktape-control {
    max-width: 370px;
    padding: 45px 70px;
    text-align: left;
  }
}
.worktape-control__title {
  margin-bottom: 25px;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  font-family: "GothamPro", Verdana, Arial, sans-serif;
  text-transform: uppercase;
}
.worktape-control__content {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.worktape-control .btn {
  display: block;
  width: 100%;
  padding: 15.5px 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border-radius: 6px;
  background: #0a71cc;
  transition: all 0.3s ease-out;
}
@media (min-width: 768px) {
  .worktape-control .btn {
    width: 202px;
  }
}
@media (min-width: 992px) {
  .worktape-control .btn {
    width: 100%;
  }
}
.worktape-control .btn:hover {
  color: #0a71cc;
  border: 1px solid #0a71cc;
  background: #fff;
  box-shadow: 0 0 5px #b3da26;
}
.worktape-control .btn:focus, .worktape-control .btn:active {
  color: #fff;
  background: #005bab;
  box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
}
.worktape__catalog {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 40px;
}
.worktape__catalog__title {
  margin-bottom: 40px;
  text-align: center;
  color: #000;
}
.worktape__catalog__title a {
  color: inherit;
}
.worktape__catalog__item {
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  padding: 20px 11px;
  border: 1px solid #b3da26;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .worktape__catalog__item {
    padding: 35px 30px;
  }
}
@media (min-width: 992px) {
  .worktape__catalog__item {
    font-size: 16px;
    line-height: 19px;
    padding: 36px 30px;
  }
}
.worktape__catalog__item:hover {
  z-index: 10;
  background: #fff7e3;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}
.worktape__catalog__item__date {
  color: #000;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .worktape__catalog__item__date {
    margin-bottom: 20px;
  }
}
.worktape__catalog__item__date span {
  color: #8d8d8d;
}
.worktape__catalog__item__number {
  margin-bottom: 8px;
  color: #8d8d8d;
}
@media (min-width: 768px) {
  .worktape__catalog__item__number {
    margin-bottom: 20px;
  }
}
.worktape__catalog__item__number span {
  color: #000;
}
.worktape__catalog__item__text {
  margin-bottom: 8px;
  color: #8d8d8d;
  font-size: 16px;
  line-height: 19px;
}
@media (min-width: 768px) {
  .worktape__catalog__item__text {
    margin-bottom: 22px;
  }
}
@media (min-width: 992px) {
  .worktape__catalog__item__text {
    font-size: 18px;
    line-height: 24px;
  }
}
.worktape__catalog__item__text a {
  text-decoration: none;
  color: #0a71cc;
}
.worktape__catalog__item__tag {
  display: flex;
  margin-bottom: 8px;
  color: #8d8d8d;
}
@media (min-width: 992px) {
  .worktape__catalog__item__tag {
    margin-bottom: 0;
  }
}
.worktape__catalog__item__tag a {
  color: inherit;
}
.worktape__catalog__item__tag:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: url("../img/icons/date.svg") no-repeat 50% 50%;
  margin-right: 8px;
}
.worktape__catalog__item__price {
  margin-bottom: 8px;
  color: #8d8d8d;
}
@media (min-width: 992px) {
  .worktape__catalog__item__price {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 19px;
  }
}
.worktape__catalog__item__price span {
  color: #000;
}
.worktape__catalog__item__rating {
  display: flex;
}
.worktape__catalog__item__rating span {
  display: block;
  width: 21px;
  height: 20px;
  margin-right: 4px;
  background: url("../img/star.svg") no-repeat 50% 50%;
  background-size: 21px 20px;
}
.worktape__catalog__item__rating span.current {
  background: url("../img/star-current.svg") no-repeat 50% 50%;
  background-size: 21px 20px;
}
.worktape__catalog__more {
  display: block;
  width: 202px;
  margin: 0 auto 61px;
  padding: 7.5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  text-transform: uppercase;
  color: #0a71cc;
  border: 1px solid #0a71cc;
  border-radius: 6px;
  background-color: #fff;
}
@media (min-width: 768px) {
  .worktape__catalog__more {
    margin-bottom: 99px;
    padding: 14.5px 10px;
  }
}
@media (min-width: 992px) {
  .worktape__catalog__more {
    padding: 15.5px 10px;
    font-size: 16px;
    line-height: 19px;
  }
}
.worktape__catalog__more:hover {
  box-shadow: 0 0 5px #b3da26;
}
.worktape__catalog__more:focus, .worktape__catalog__more:active {
  box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
}

.worktape-description {
  margin-top: 55px;
  margin-bottom: 55px;
}
@media (min-width: 768px) {
  .worktape-description {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
.worktape-description__box {
  box-sizing: border-box;
  border: 1px solid #b3da26;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .worktape-description__box {
    display: flex;
  }
}
.worktape-description__box__content {
  font-size: 16px;
  line-height: 19px;
  padding: 25px;
}
@media (min-width: 768px) {
  .worktape-description__box__content {
    padding: 30px;
  }
}
@media (min-width: 992px) {
  .worktape-description__box__content {
    padding: 20px 50px;
    font-size: 18px;
    line-height: 24px;
    width: 60%;
  }
}
@media (min-width: 768px) {
  .worktape-description__box__content > .row.align-items-center {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    height: 100%;
    justify-content: flex-start;
    row-gap: 20px;
  }
}
@media (min-width: 768px) {
  .worktape-description__box__content > .row.align-items-center .col-md-8, .worktape-description__box__content > .row.align-items-center .col-md-4 {
    width: 100%;
    flex: 0 0 auto;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .worktape-description__box__content > .row.align-items-center .col-md-4 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
@media (min-width: 992px) {
  .worktape-description__box__content > .row.align-items-center .col-md-4 {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
  }
}
@media (min-width: 768px) {
  .worktape-description__box__content > .row.align-items-center .col-md-4 .btn {
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .worktape-description__box__content > .row.align-items-center .col-md-4 .btn {
    margin: 0;
  }
}
.worktape-description__box__content--green {
  color: #fff;
  background-color: #b3da26;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
@media (min-width: 992px) {
  .worktape-description__box__content--green {
    width: 40%;
  }
}
.worktape-description__box__content--green .row .row {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.worktape-description__box__content--green > .row.align-items-center {
  row-gap: 20px;
}
@media (min-width: 768px) {
  .worktape-description__box__content--green > .row.align-items-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .worktape-description__box__content--green > .row.align-items-center .col-md-7, .worktape-description__box__content--green > .row.align-items-center .col-md-5, .worktape-description__box__content--green > .row.align-items-center .col-lg-2 {
    width: 100%;
    flex: 0 0 auto;
    max-width: 100%;
  }
}
.worktape-description__box__content p {
  margin-bottom: 8px;
}
.worktape-description__box__content p.label {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #8d8d8d;
}
@media (min-width: 768px) {
  .worktape-description__box__content p.label {
    margin-top: 16px;
    font-size: 16px;
    line-height: 19px;
  }
}
.worktape-description__box__content__label {
  color: #000;
  font-weight: 400;
  display: inline-block;
  margin-right: 8px;
}
@media (min-width: 992px) {
  .worktape-description__box__content__label {
    display: block;
    margin-right: 0;
  }
}
.worktape-description__box__content__date {
  padding-left: 55px;
  color: #000;
  background: url("../img/date.svg") no-repeat 0 0;
  background-size: 38px 46px;
  font-weight: 400;
}
.worktape-description__box__content__date span {
  color: #fff;
  font-weight: 700;
}
.worktape-description__box__content__date p {
  margin: 8px 0;
}
.worktape-description__box__workbtn {
  font-size: 16px !important;
  line-height: 19px !important;
  min-height: 52px;
  margin-bottom: 22px;
  margin-top: 22px;
}
@media (min-width: 768px) {
  .worktape-description__box__workbtn {
    width: 202px;
    margin-bottom: 22px;
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .worktape-description__box__workbtn {
    width: 202px;
    margin-bottom: 22px;
  }
}
.worktape-description__box__time {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.worktape-description__box__time:before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background: url("../img/clock.svg") no-repeat 50% 50%;
  margin-right: 8px;
  background-size: cover;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 490px) {
  .pagination {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}
.pagination__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  margin-right: 4px;
  margin-left: 4px;
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.pagination__btn:hover {
  border-color: #dee2e6;
  background-color: #dee2e6;
}
.pagination__btn.current {
  border-color: #b3da26;
  background-color: #b3da26;
}
.pagination__btn--prev:after {
  content: "";
  display: block;
  width: 12px;
  height: 16px;
  background: url("../img/arrow-left.svg") no-repeat 50% 50%;
}
.pagination__btn--next:after {
  content: "";
  display: block;
  width: 12px;
  height: 16px;
  background: url("../img/arrow-right.svg") no-repeat 50% 50%;
}

.articles-smart {
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;
}
@media (min-width: 768px) {
  .articles-smart {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.articles-smart__col {
  overflow: hidden;
}
@media (min-width: 768px) {
  .articles-smart__col {
    flex: 0 0 100%;
    max-width: none;
  }
}
.articles-smart__item {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .articles-smart__item {
    column-gap: 0;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .articles-smart__item {
    flex-direction: column;
    height: 100%;
  }
}
.articles-smart__item__preview {
  background-color: #ffe5b4;
  border: none;
  border-radius: 8px 8px 0 0;
  min-height: 202px;
  min-width: 280px;
  background-position: 50% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /*
  & img {
    position: relative;
    z-index: 1;

    display: block;

    width: 100%;
  } */
  /*       &__tag {
    display: flex;
    align-items: center;

    //min-width: 119px;
    height: 25px;
    padding: 8px 11px;

    font-size: 10px;
    line-height: 12px;

    border-radius: 6px;
    background: #fff;

    & span {
      display: block;

      width: 4px;
      height: 4px;
      margin-right: 5px;

      border-radius: 50%;

      &.green {
        background-color: #28a745;
      }

      &.orange {
        background-color: #ffa500;
      }
    }
  } */
}
@media (min-width: 768px) {
  .articles-smart__item__preview {
    border-radius: 8px;
  }
}
@media (min-width: 992px) {
  .articles-smart__item__preview {
    max-height: 303px;
    border-radius: 8px 8px 0 0;
  }
}
.articles-smart__item__preview__tags {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px;
}
@media (min-width: 768px) {
  .articles-smart__item__preview__tags {
    padding: 10px;
  }
}
@media (min-width: 992px) {
  .articles-smart__item__preview__tags {
    padding: 10px;
  }
}
.articles-smart__item__preview__tag {
  font-family: "Gotham Pro", Verdana, Arial, sans-serif;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  display: flex;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1;
  padding: 8px 11px 8px 18px;
  position: relative;
}
.articles-smart__item__preview__tag a {
  color: #000;
  white-space: nowrap;
  text-decoration: none;
}
.articles-smart__item__preview__tag b, .articles-smart__item__preview__tag span {
  transform: translateY(-50%);
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 100%;
  left: 10px;
  margin-right: 2px;
  margin-top: 0;
  max-height: 5px;
  max-width: 5px;
  position: absolute;
  top: 50%;
  vertical-align: middle;
  width: 100%;
}
.articles-smart__item__dop {
  font-size: 12px;
  line-height: 17px;
  color: #abb5be;
}
@media (min-width: 768px) {
  .articles-smart__item__dop {
    font-size: 12px;
    line-height: 17px;
  }
}
.articles-smart__item__dop span {
  color: #0a71cc;
}
@media (min-width: 768px) {
  .articles-smart__item__dop.text-lg-right {
    display: flex;
    column-gap: 5px;
    white-space: nowrap;
  }
}
.articles-smart__item__summary {
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  border: 1px solid #dee2e6;
  border-top: none;
  row-gap: 10px;
  padding: 20px 15px 20px;
}
@media (min-width: 768px) {
  .articles-smart__item__summary {
    border: none;
    padding: 0 0 0 20px;
  }
}
@media (min-width: 992px) {
  .articles-smart__item__summary {
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 20px 10px 20px 20px;
    height: 100%;
  }
}
.articles-smart__item__title {
  color: #343434;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: none;
  /*       font-size: 18px;
  line-height: 22px;

  color: #343434;
  & a {
    color: #343434;
    text-decoration: none;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;

    font-size: 18px;
    line-height: 22px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 20px;

    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  } */
}
@media (max-width: 425px) {
  .articles-smart__item__title {
    word-break: break-all;
  }
}
.articles-smart__item__description {
  color: #68717a;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 11px 0 34px;
}
@media (max-width: 425px) {
  .articles-smart__item__description {
    word-break: break-all;
  }
}
.articles-smart__item__description + p:not([class]) {
  color: #68717a;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 11px 0 0;
}
.articles-smart__item__content {
  font-size: 12px;
  line-height: 16px;
  color: #68717a;
}
@media (min-width: 768px) {
  .articles-smart__item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
.articles-smart__item__content > span {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media (min-width: 768px) {
  .articles-smart__item__content > span {
    justify-content: space-between;
    height: 100%;
  }
}
.articles-smart__item__content .row-content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
@media (min-width: 768px) {
  .articles-smart__item__content .row-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.articles-smart__item__content .row-content .col-12, .articles-smart__item__content .row-content .col-6 {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .articles-smart__item__content .row-content .col-12, .articles-smart__item__content .row-content .col-6 {
    width: auto;
    flex: 0 0 auto;
  }
}
@media (min-width: 768px) {
  .articles-smart__item__content {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 992px) {
  .articles-smart__item__content {
    font-size: 16px;
    line-height: 20px;
  }
}
.articles-smart__item__name {
  color: #343434;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: none;
}
@media (max-width: 425px) {
  .articles-smart__item__name {
    word-break: break-all;
  }
}
.articles-smart__item__body {
  text-decoration: none;
  height: 100%;
}
.articles-smart__item__body p:not([class]) {
  margin: 0 0 10px;
  max-height: 180px;
  overflow: hidden;
}

.articles-smart-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 768px) {
  .articles-smart-slider {
    overflow-x: auto;
  }
}
.articles-smart-slider .row {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media (min-width: 768px) {
  .articles-smart-slider .row {
    flex-direction: row;
    row-gap: 30px;
  }
}
@media (min-width: 992px) {
  .articles-smart-slider .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
  }
}
.articles-smart-slider__container {
  width: 100%;
}
.articles-smart-slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;
  transform: translateY(-225px);
  width: 90px;
  height: 520px;
  cursor: pointer;
  border: none;
  border-radius: 0;
}
@media (min-width: 768px) {
  .articles-smart-slider__btn {
    display: flex;
  }
}
@media (min-width: 992px) {
  .articles-smart-slider__btn {
    display: none;
  }
}
.articles-smart-slider__btn__left {
  left: 10px;
  display: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  justify-content: flex-start;
}
.articles-smart-slider__btn__left:after {
  content: "";
  display: block;
  margin-left: 6px;
  width: 16px;
  height: 19px;
  background: url("../img/icons/arrow-big.svg") no-repeat 50% 50%;
  transform: rotate(-180deg);
}
@media (min-width: 768px) {
  .articles-smart-slider__btn__left.current {
    display: flex;
  }
}
@media (min-width: 992px) {
  .articles-smart-slider__btn__left.current {
    display: none;
  }
}
.articles-smart-slider__btn__right {
  right: 10px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  justify-content: flex-end;
}
.articles-smart-slider__btn__right:after {
  content: "";
  display: block;
  margin-right: 6px;
  width: 16px;
  height: 19px;
  background: url("../img/icons/arrow-big.svg") no-repeat 50% 50%;
}

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img,
svg,
video {
  display: block;
  max-width: 100%;
}

blockquote,
body,
dd,
dl,
figcaption,
figure,
li {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

button,
input,
select,
textarea {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0s !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
  }
}
/* a {
  user-select: none;
} */
button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

#toTop img {
  width: 50px;
  margin: 0 auto !important;
}

a.bonus-blog__btn.btn.btn-bg-blue,
button.bonus-blog__btn.btn.btn-bg-blue {
  width: 202px;
  height: 52px;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-category-article.margin-bottom .articles-blog__item.d-flex {
  margin-bottom: 40px;
}

.main {
  background: #e4eaee;
  padding: 0 0 45px !important;
}

.btn-primary:disabled:hover {
  cursor: auto;
}

.visually-hidden {
  visibility: hidden;
}
.visually-hidden_clip {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  position: absolute;
  width: 1px;
}

.blog-wrapper .article-keep__button, .blog-wrapper .article-keep__item:before, .blog-wrapper .comments__main, .blog-wrapper .comments__name, .blog-wrapper .comments__time, .blog-wrapper .detail-article, .blog-wrapper .detail-article .title_h2:not([class]), .blog-wrapper .detail-article h2:not([class]), .blog-wrapper .feedback-article__title, .blog-wrapper .filter-comments, .blog-wrapper .form-comments, .blog-wrapper .form-comments__button {
  font-family: ProximaNova;
}
.blog-wrapper li {
  margin-bottom: 10px;
}
.blog-wrapper ul:not([class]) {
  margin-left: 0;
}
.blog-wrapper .wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
.blog-wrapper .main {
  flex: 1;
  position: relative;
}
.blog-wrapper .d-flex {
  display: flex;
}
.blog-wrapper .wrap {
  flex-wrap: wrap;
}
.blog-wrapper .responsive-img {
  height: auto;
  max-width: 100%;
}
.blog-wrapper .sticky {
  position: fixed;
  z-index: 101;
}
.blog-wrapper .stop {
  position: relative;
  z-index: 101;
}
.blog-wrapper .box-center {
  text-align: center;
}
.blog-wrapper .btn {
  background: 0 0;
  border: none;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 700;
  outline: 0;
  padding: 15.5px 2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: 0.2s;
}
.blog-wrapper .btn_transparent {
  background-color: transparent;
  border: 1px solid #173091;
  color: #173091;
  font-size: 0.75rem;
  font-weight: 700;
  width: 180px;
}
.blog-wrapper .btn_transparent-color_white {
  border: 1px solid #fff;
  color: #fff;
}
.blog-wrapper .btn_color-skyblue {
  background-color: #5fc3f1;
  color: #fff;
  font-weight: 700;
}
.blog-wrapper .btn-xs {
  width: 230px;
}
.blog-wrapper .btn-label {
  display: inline-block;
}
.blog-wrapper .btn.disabled {
  cursor: default;
  pointer-events: none;
}
.blog-wrapper .btn-bg {
  color: #fff;
}
.blog-wrapper .btn-br {
  border: 2px solid;
}
.blog-wrapper .btn-br.disabled {
  border-color: #929292;
  color: rgba(0, 0, 0, 0.8);
}
.blog-wrapper .btn-nopadd {
  padding-left: 0;
  padding-right: 0;
}
.blog-wrapper .btn-block {
  display: block;
  width: 100%;
}
.blog-wrapper .btn-bg-blue {
  background-color: #0a71cc;
  color: #fff;
}
.blog-wrapper .btn-bg-blue:hover {
  background-color: #3c4afa;
}
.blog-wrapper .btn-bg-blue:active {
  background-color: #0814a6;
}
.blog-wrapper .btn-br-blue {
  border-color: #0a71cc;
  color: #000;
}
.blog-wrapper .btn-br-blue:hover {
  border-color: #3c4afa;
}
.blog-wrapper .btn-br-blue:active {
  border-color: #0814a6;
}
.blog-wrapper .btn-bg-red {
  background-color: #fd2b1c;
}
.blog-wrapper .btn-bg-red:hover {
  background-color: #fc3752;
}
.blog-wrapper .btn-bg-red:active {
  background-color: #a8001c;
}
.blog-wrapper .btn-br-red {
  border-color: #fd2b1c;
}
.blog-wrapper .btn-br-red:hover {
  border-color: #fc3752;
}
.blog-wrapper .btn-br-red:active {
  border-color: #a8001c;
}
.blog-wrapper .stop .article-keep__button {
  margin-top: -90px;
}
.blog-wrapper .horizontal-scroll-wrapper {
  background-attachment: local, local, scroll, scroll;
  background-color: #fff;
  background-image: linear-gradient(to right, #fff, #fff), linear-gradient(to right, #fff, #fff), linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(to left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  background-image: -webkit-gradient(linear, left top, right top, from(white), to(white)), -webkit-gradient(linear, left top, right top, from(white), to(white)), -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.8))), -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.8)));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 40px 100%, 20px 100%, 10px 100%, 40px 100%;
  overflow-style: autohiding-scrollbar;
  overflow-x: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  white-space: nowrap;
}
.blog-wrapper .horizontal-scroll-wrapper::-webkit-scrollbar {
  display: none;
}

.worktape-carousel {
  margin-top: 40px;
  margin-bottom: 40px;
}
.worktape-carousel__item {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 265px;
  border: 1px solid #abb5be;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .worktape-carousel__item {
    width: 290px;
    height: 265px;
  }
}
@media (min-width: 992px) {
  .worktape-carousel__item {
    width: 290px;
    height: 265px;
  }
}
.worktape-carousel__item__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 20px 10px 1rem;
  height: 100%;
  overflow: hidden;
}
.worktape-carousel__item__content__theme {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 19px;
}
@media (min-width: 768px) {
  .worktape-carousel__item__content__theme {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 19px;
  }
}
.worktape-carousel__item__content__subject {
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 19px;
}
@media (min-width: 768px) {
  .worktape-carousel__item__content__subject {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 19px;
  }
}
.worktape-carousel__item__content__subject span {
  color: #8d8d8d;
}
.worktape-carousel__item__content__title {
  position: relative;
  overflow: hidden;
  max-height: 96px;
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  color: #0a71cc;
}
.worktape-carousel__item__content__title a {
  color: #0a71cc;
  text-decoration: none;
}
.worktape-carousel__item__content__title a::first-letter {
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .worktape-carousel__item__content__title {
    max-height: 96px;
    font-size: 18px;
    line-height: 24px;
    color: #0a71cc;
  }
}
.worktape-carousel__item__content__title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10px;
  display: block;
  width: calc(100% + 20px);
  height: 10px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
}
.worktape-carousel__item__price {
  padding: 22px 10px 30px;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  background: #b3da26;
}
@media (min-width: 768px) {
  .worktape-carousel__item__price {
    font-size: 16px;
    line-height: 19px;
  }
}
.worktape-carousel__item__price span {
  font-weight: 700;
}
.worktape-carousel .owl-dots {
  margin-top: 40px;
  margin-bottom: 0;
}
.worktape-carousel .owl-dots .owl-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  background-color: transparent !important;
}
.worktape-carousel .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #c8cdd2;
}
.worktape-carousel .owl-dots .owl-dot.active span {
  width: 10px;
  height: 10px;
  background-color: #b3da26;
}

.breadcrumbs {
  color: #000;
  display: inline;
  font-size: 1.125rem;
  line-height: 1.3125rem;
}
.breadcrumbs > span {
  margin: 0 5px 0 0;
}
.breadcrumbs__item {
  color: inherit;
  display: inline;
  hyphens: auto;
  letter-spacing: -0.02em;
  overflow-wrap: break-word;
  word-break: break-all;
  margin: 0 5px 0 0;
}
@media screen and (max-width: 767px) {
  .breadcrumbs__item {
    font-size: 1rem;
    line-height: normal;
  }
}
.breadcrumbs__item:after {
  content: "›";
  margin: 0 3px;
}
.breadcrumbs__item:last-child:after {
  display: none;
}
.breadcrumbs__link {
  color: inherit;
  display: inline;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.article-keep {
  position: relative;
}
@media screen and (max-width: 767px) {
  .article-keep > .article-keep__list {
    margin: 29px 0 30px;
  }
}
.article-keep__name {
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 2.125rem;
}
@media screen and (max-width: 767px) {
  .article-keep__name {
    font-size: 1rem;
  }
}
.article-keep__list {
  list-style-type: none;
  flex: 1 0 100%;
  flex-wrap: wrap;
  counter-reset: list2;
  margin: 18px 0 0 40px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-top: 10px;
}
.article-keep__list_footer {
  max-width: initial !important;
}
.article-keep__list li:before {
  max-height: 32px;
  color: #555;
  color: #000000 !important;
  background-color: #b3da26;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1;
  font-weight: 700;
  padding: 7px;
  border-radius: 6px;
  margin-right: 17px;
  min-width: 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: inline-block;
  background-color: #b3da26;
  content: counter(list1) "." counter(list2) ". ";
  counter-increment: list2;
}
.article-keep__list li.top {
  counter-increment: list;
  counter-reset: list1;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.article-keep__list li.top:before {
  content: counter(list) ".";
}
.article-keep__list li.sub_1 {
  display: flex;
  align-items: center;
  counter-increment: list1;
  counter-reset: list2;
}
.article-keep__list li.sub_1:before {
  content: counter(list) "." counter(list1) ".";
}
.article-keep__list li.sub_2 {
  counter-increment: list2;
}
.article-keep__list li.sub_2:before {
  content: counter(list) "." counter(list1) "." counter(list2) ".";
}
.article-keep__link {
  color: #152536;
  flex: 1;
  text-decoration: underline;
}
@media screen and (max-width: 480px) {
  .article-keep__link {
    max-width: 65%;
  }
}
.article-keep__link:hover {
  color: #b3da26;
  text-decoration: none;
}
.article-keep__button-link {
  /* align-items: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 74px;
  padding: 14px 12px 13px;
  position: absolute;
  //right: -95px;
  text-transform: uppercase;
  top: 0;

  right: -98px !important; */
  position: sticky;
  top: 100px;
  margin: 100px 0 0 auto;
  transform: rotate(270deg) translate(0, 100px);
  text-transform: uppercase;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  width: max-content;
  height: auto;
  padding: 14px 12px 13px;
  font-size: 0.75rem;
  line-height: 1;
  background-color: #fff;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;
}
@media screen and (max-width: 1200px) {
  .article-keep__button-link {
    display: none;
  }
}
.article-keep__button-link:after {
  background-image: url(/wp-content/themes/studservice/new/img/blog/icon-arrows.png) !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 21px;
  margin-left: 9px;
  transform: rotate(90deg);
  width: 18px;
}
.article-keep__item {
  margin-bottom: 18px;
}
@media screen and (max-width: 480px) {
  .article-keep__item {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
}
.article-keep__item:before {
  background-color: #b3da26;
  border-radius: 6px;
  content: counter(list1);
  counter-increment: list1;
  display: inline-block;
  flex-shrink: 0;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  margin-right: 17px;
  min-width: 25px;
  padding: 7px;
  text-align: center;
}

.unsubscribe-form__wrapper {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.unsubscribe-form__wrapper .form-check input[type=radio]:checked + label:before, .unsubscribe-form__wrapper .form-check input[type=radio]:not(:checked) + label:before {
  top: 50%;
  transform: translate(0, -50%);
}
.unsubscribe-form__wrapper .form-check input[type=radio]:checked + label:after {
  top: 50%;
  left: 3px;
  transform: translate(0, -50%);
}
.unsubscribe-form__textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
}

.articles-blog {
  padding: 50px 0 0;
}
@media screen and (max-width: 767px) {
  .articles-blog {
    padding: 50px 0 0;
  }
}
.articles-blog__body {
  text-decoration: none;
  height: 100%;
}
.articles-blog__body p:not([class]) {
  margin: 0 0 10px;
  max-height: 180px;
  overflow: hidden;
}
.articles-blog__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;
  border: 1px solid #dee2e6;
  border-top: none;
  padding: 20px 15px 20px;
}
@media (min-width: 768px) {
  .articles-blog__content {
    padding: 0 0 0 20px;
    border: none;
  }
}
@media (min-width: 991px) {
  .articles-blog__content {
    padding: 20px 10px 20px 20px;
    border: 1px solid #dee2e6;
    border-top: none;
    flex-grow: 1;
  }
}
.articles-blog__content p {
  overflow: hidden;
}
.articles-blog__title {
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
}
.articles-blog__title span {
  border-radius: 50%;
  flex-shrink: 0;
  height: 12px;
  margin-right: 5px;
  width: 12px;
}
@media screen and (max-width: 767px) {
  .articles-blog__title span {
    height: 6px;
    width: 6px;
  }
}
.articles-blog__item {
  background-color: #fff;
  flex-direction: column;
  border-radius: 6px;
}
@media screen and (max-width: 991px) {
  .articles-blog__item {
    width: 100%;
    flex-direction: row;
    margin-bottom: 22px;
    max-width: none;
  }
}
@media screen and (max-width: 767px) {
  .articles-blog__item {
    flex-direction: column;
  }
}
.articles-blog__item_row {
  max-height: initial;
  margin-right: 55px;
  flex-direction: row;
  margin-bottom: 22px;
  max-width: none;
}
.articles-blog__item_row .articles-blog__content {
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 26px;
}
@media (max-width: 768px) {
  .articles-blog__item_row .articles-blog__content {
    justify-content: space-between;
    border-radius: 0 0 6px 6px;
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 20px 15px;
  }
}
.articles-blog__item_row .articles-blog__footer {
  margin-top: auto;
  padding-top: 10px;
}
.articles-blog__item_row .articles-blog__image {
  max-height: 100%;
}
.articles-blog__list {
  margin: 15px 0 0;
  /*     display: flex;
  flex-wrap: wrap; */
}
@media (min-width: 970px) {
  .articles-blog__list {
    column-gap: 20px;
  }
}
@media screen and (max-width: 767px) {
  .articles-blog__list {
    margin: 23px 0 0;
  }
}
.articles-blog__list_column {
  flex-direction: column;
  display: flex !important;
  row-gap: 20px;
}
.articles-blog__list_row {
  flex-direction: row;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 991px) {
  .articles-blog__list_row {
    flex-direction: column;
    display: flex !important;
    row-gap: 20px;
  }
}
.articles-blog__header {
  background-color: #ffe5b4;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  border-radius: 6px 6px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  object-position: center;
  min-height: 290px;
  top: 0;
}
@media (min-width: 768px) {
  .articles-blog__header {
    min-height: 202px;
    min-width: 280px;
    max-width: 303px;
    border-radius: 6px;
  }
}
@media (min-width: 970px) {
  .articles-blog__header {
    border-radius: 6px 6px 0 0;
    max-width: none;
  }
}
.articles-blog__grid .articles-blog__header {
  border-radius: 6px;
}
@media (max-width: 768px) {
  .articles-blog__grid .articles-blog__header {
    border-radius: 6px 6px 0 0;
  }
}
.articles-blog__image {
  height: 100%;
  max-height: 202px;
  object-fit: cover;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  bottom: 0;
  border-radius: 6px;
}
@media screen and (max-width: 767px) {
  .articles-blog__image {
    border-radius: 6px 6px 0 0;
    min-height: 290px;
  }
}
.articles-blog__name {
  color: #343434;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  text-transform: none;
}
@media (max-width: 425px) {
  .articles-blog__name {
    word-break: break-all;
  }
}
.articles-blog__description {
  color: #68717a;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 11px 0 0;
}
@media (max-width: 425px) {
  .articles-blog__description {
    word-break: break-all;
  }
}
.articles-blog__description + p:not([class]) {
  color: #68717a;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 11px 0 0;
}
.articles-blog__footer {
  font-size: 0.75rem;
  justify-content: space-between;
  margin: 34px 0 0;
}
@media (max-width: 500px) {
  .articles-blog__footer {
    flex-direction: column;
    row-gap: 5px;
  }
}
.articles-blog__footer p {
  font-size: 0.75rem;
  margin-bottom: 0;
}
.articles-blog__text {
  color: #abb5be;
}
.articles-blog__text span {
  color: #0a71cc;
}
.articles-blog__more {
  align-items: center;
  color: #0a71cc;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 20px auto 0;
  text-transform: uppercase;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .articles-blog__more {
    margin: 16px auto 0;
  }
}
.articles-blog__more:after {
  border-bottom: 2px solid #0a71cc;
  border-right: 2px solid #0a71cc;
  content: "";
  height: 8px;
  margin-left: 8px;
  margin-top: -5px;
  transform: rotate(45deg);
  width: 8px;
}
.articles-blog__tags {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px 11px 10px;
  display: flex;
  position: relative;
  left: auto;
  bottom: auto;
  right: auto;
  flex-wrap: wrap;
  margin: 0;
}
@media (min-width: 970px) {
  .articles-blog__tags {
    margin: 0;
  }
}

.banner-blog {
  background-color: #f2f2f2;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .banner-blog {
    overflow: hidden;
  }
}
.banner-blog__grid {
  justify-content: space-between;
  max-width: 50%;
}
@media (max-width: 991px) {
  .banner-blog__grid {
    max-width: 758px;
    margin: 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .banner-blog__grid {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
.banner-blog__title {
  color: #0a71cc;
  font-size: 4.5rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .banner-blog__title {
    font-size: 2.25rem;
    line-height: normal;
    margin: 0;
  }
}
.banner-blog__descritpion {
  font-size: 1.125rem;
  letter-spacing: -0.02em;
  line-height: 1.4125rem;
  margin: 10px 0 0;
}
@media screen and (max-width: 970px) {
  .banner-blog__descritpion {
    margin: 24px 0 0;
  }
}
.banner-blog__search {
  margin: 23px 0 0;
}
@media screen and (max-width: 970px) {
  .banner-blog__search {
    margin: 23px 0 0;
  }
}
.banner-blog__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 630px;
  padding-top: 112px;
}
@media (max-width: 991px) {
  .banner-blog__content {
    max-width: 430px;
  }
}
@media screen and (max-width: 767px) {
  .banner-blog__content {
    padding-top: 0;
    text-align: center;
  }
}
.banner-blog__picture {
  align-items: flex-end;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 0 auto 0 70px;
  max-width: 100%;
  position: relative;
  text-align: center;
  width: 430px;
  z-index: 0;
}
@media screen and (max-width: 970px) {
  .banner-blog__picture {
    margin-right: -100px;
    width: 370px;
  }
}
@media screen and (max-width: 767px) {
  .banner-blog__picture {
    margin: 10px 0 0;
    width: 300px;
  }
}
.banner-blog__picture:after {
  background-color: #b3da26;
  border-radius: 50%;
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 85%;
  transform: translate(-50%, -50%);
  width: inherit;
}
@media screen and (max-width: 970px) {
  .banner-blog__picture:after {
    height: 370px;
    width: 370px;
  }
}
@media screen and (max-width: 767px) {
  .banner-blog__picture:after {
    height: 300px;
    width: 300px;
  }
}
.banner-blog__image {
  margin-left: 10px;
  max-width: 295px;
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 970px) {
  .banner-blog__image {
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .banner-blog__image {
    max-width: 220px;
  }
}
.banner-blog__container {
  min-height: 454px;
  display: flex;
  flex-grow: 1;
  padding-top: 42px;
  padding-bottom: 276px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: auto 290px;
  background-image: url("../img/blog/bg@1x.png");
  background-image: image-set(url("../img/blog/bg@1x.webp") 1x, url("../img/blog/bg@2x.webp") 2x, url("../img/blog/bg@1x.png") 1x, url("../img/blog/bg@2x.png") 2x);
}
@media (min-width: 768px) {
  .banner-blog__container {
    background-position: 140% 100%;
    background-size: auto 454px;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .banner-blog__container {
    background-position: 100% 100%;
    background-size: auto 454px;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .banner-blog__container {
    max-width: 1140px;
  }
}

.blog-category .blog__articles {
  padding: 30px 0 0;
}
@media (max-width: 970px) {
  .blog-category .blog__articles {
    padding: 20px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .blog-category .blog__articles:last-child {
    padding: 20px 0 0 0;
  }
  .blog-category .blog__articles:last-child .articles-blog__list {
    margin: 0;
  }
}

.blog-tags .blog-tags__item {
  margin-bottom: 0;
}
.blog-tags__item {
  font-family: "Gotham Pro", Verdana, Arial, sans-serif;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  display: flex;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1;
  padding: 8px 11px 8px 18px;
  position: relative;
}
.blog-tags__item a {
  color: #000;
  white-space: nowrap;
  text-decoration: none;
}
.blog-tags__item b, .blog-tags__item span {
  transform: translateY(-50%);
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
  height: 100%;
  left: 10px;
  margin-right: 2px;
  margin-top: 0;
  max-height: 5px;
  max-width: 5px;
  position: absolute;
  top: 48%;
  vertical-align: middle;
  width: 100%;
}
.blog-tags span {
  top: 54% !important;
}
.blog-tags p {
  line-height: 1;
  margin: 0;
  vertical-align: middle;
  font-size: 0.625rem !important;
}

.blog {
  padding-bottom: 100px;
  background-color: white;
}
.blog .col, .blog .container-fluid, .blog .container-sm, .blog .container-md, .blog .container-lg, .blog .container-xl {
  padding-left: 15px;
  padding-right: 15px;
}
.blog .container {
  padding-left: 10px;
  padding-right: 10px;
}
.blog .container {
  margin: 0 auto;
  /*     max-width: 100%;
  width: 1185px; */
}
.blog .container.breadcrumbs__container {
  margin: 17px auto 0;
}
.blog__nav.nav-blog .container, .blog__nav.nav-blog.container {
  padding-left: 0;
  padding-right: 0;
}
.blog__nav.nav-blog .nav-blog__menu {
  overflow: hidden;
}
.blog .container-fluid, .blog .container-sm, .blog .container-md, .blog .container-lg, .blog .container-xl {
  margin: 0 auto;
  width: 100%;
}
.blog .row {
  list-style: none;
  margin-left: -15px;
  margin-right: -15px;
}
.blog__bonus {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .blog__bonus {
    margin: 40px 0 0;
  }
}
.blog .breadcrumbs {
  color: #000;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  display: inline;
  flex-wrap: wrap;
  column-gap: 10px;
}
.blog .breadcrumbs__item {
  color: inherit;
  display: inline;
  hyphens: auto;
  letter-spacing: -0.02em;
  overflow-wrap: break-word;
  word-break: break-all;
}
@media screen and (max-width: 767px) {
  .blog .breadcrumbs__item {
    font-size: 1rem;
    line-height: normal;
  }
}
.blog .breadcrumbs__item:after {
  content: "›";
  margin: 0 3px;
}
.blog .breadcrumbs__item:last-child:after {
  display: none;
}
.blog .breadcrumbs__link {
  color: inherit;
  display: inline;
  text-decoration: underline;
  text-underline-offset: 4px;
}
@media screen and (max-width: 1200px) {
  .blog .aside-sidebar {
    display: none;
  }
}

.bonus-blog__grid {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  min-height: 144px;
  padding: 20px;
  background-image: url("/wp-content/themes/studservice/new/img/bonus-blog_back.png");
  border-radius: 15px;
}
@media screen and (max-width: 970px) {
  .bonus-blog__grid {
    align-items: flex-start;
    border-radius: 15px;
    flex-direction: column;
    padding: 43px 80px;
  }
}
@media screen and (max-width: 767px) {
  .bonus-blog__grid {
    align-items: center;
    justify-content: center;
    padding: 43px 35px;
    text-align: center;
  }
}
.bonus-blog__title {
  color: #000;
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: 0.04em;
  line-height: 2.25rem;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .bonus-blog__title {
    font-size: 1.5rem;
  }
}
.bonus-blog__description {
  font-family: ProximaNova;
  font-size: 1.125rem;
  font-weight: 700;
}
.bonus-blog__btn {
  border-radius: 6px;
  margin-left: 127px;
  padding: 19px 18px;
}
@media screen and (max-width: 970px) {
  .bonus-blog__btn {
    margin-left: 0;
    margin-top: 0;
  }
}

.category-blog__title {
  margin: 50px 0 0;
}
@media screen and (max-width: 970px) {
  .category-blog__title {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .category-blog__title {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 39px 0 0;
  }
}
.category-blog__description {
  font-family: ProximaNova;
  font-size: 1.125rem;
  margin: 23px 0 0;
}
@media screen and (max-width: 767px) {
  .category-blog__description {
    font-size: 0.875rem;
  }
}
.category-blog__search {
  margin: 29px 0 0;
}

.comments-blog {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 42px 27px;
}
.comments-blog__list {
  margin: 18px 0 0;
}
.comments-blog__title {
  color: #000;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  font-size: 1.75rem;
  letter-spacing: 0.025em;
  line-height: 2.125rem;
  text-align: center;
}
.comments-blog__top {
  align-items: center;
}
.comments-blog__top.d-flex {
  justify-content: end;
}
.comments-blog__avatar {
  border-radius: 50%;
  flex: 1 0 auto;
  margin-right: 7px;
  max-height: 32px;
  max-width: 32px;
}
.comments-blog__name {
  font-family: ProximaNova;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}
.comments-blog__time {
  color: #949494;
  font-size: 0.5625rem;
  line-height: 1;
  margin-top: 5px;
}
.comments-blog__description {
  color: #000;
  font-family: ProximaNova;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin: 17px 0 0;
}
.comments-blog__item {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 9px;
  padding-bottom: 14px;
}
.comments-blog__item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.comments-blog__item .comments-blog__title {
  font-family: ProximaNova;
  font-size: 0.875rem;
  line-height: 1;
  margin: 17px 0 0;
  text-align: left;
}

.comments {
  max-width: 750px;
  padding: 100px 0 0;
}
.comments__form {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .comments__form {
    margin-bottom: 27px;
  }
}
.comments__header {
  align-items: center;
}
.comments__name {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
}
@media screen and (max-width: 767px) {
  .comments__name {
    font-size: 0.875rem;
  }
}
.comments__time {
  color: #949494;
  font-size: 0.8125rem;
  line-height: 1rem;
  margin: 14px 0 0;
}
@media screen and (max-width: 767px) {
  .comments__time {
    font-size: 0.5625rem;
    margin: 4px 0 0;
  }
}
.comments__avatar {
  height: 64px;
  width: 64px;
}
@media screen and (max-width: 767px) {
  .comments__avatar {
    height: 32px;
    width: 32px;
  }
}
.comments__item {
  margin-bottom: 50px;
}
.comments__item .textarea-wrapper {
  margin-top: 10px;
}
.comments__info {
  margin-left: 14px;
}
.comments__main {
  margin: 22px 0 0;
}
.comments__reply {
  color: #abb5be;
  font-size: 1rem;
  margin: 21px 0 0;
}
.comments__text {
  font-size: 1rem;
  line-height: 1.25rem;
}
@media screen and (max-width: 767px) {
  .comments__text {
    font-size: 0.875rem;
  }
}
.comments__filter {
  align-items: center;
  justify-content: flex-end;
}
.comments__list {
  margin-left: 78px;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .comments__list {
    margin-left: 16px;
  }
}

.detail-article__image {
  border-radius: 6px;
  margin: 20px 0;
  /*       margin: 50px 0 0 !important; */
  width: 100%;
  object-fit: cover;
  max-height: 551px;
}
@media screen and (max-width: 1200px) {
  .detail-article__image {
    margin: 30px auto 0;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .detail-article__image {
    margin: 33px 0 0;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
.detail-article__keep {
  margin: -90px 0 50px;
}
@media screen and (max-width: 1200px) {
  .detail-article__keep {
    margin: 30px 0 0;
  }
}
.detail-article__quote {
  border: 1px solid #0a71cc;
  border-radius: 6px;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0 0 47px;
  padding: 36px 25px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .detail-article__quote {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin: 0 0 26px;
  }
}
.detail-article__quote:after {
  background-image: url(../img/icon-quote.svg);
  background-repeat: no-repeat;
  content: "";
  font-weight: 700;
  height: 52px;
  letter-spacing: 0.025em;
  line-height: 1;
  position: absolute;
  right: 17px;
  top: -13px;
  width: 70px;
}
@media screen and (max-width: 767px) {
  .detail-article__quote:after {
    height: 42px;
    top: -9px;
    width: 46px;
  }
}
.detail-article__note {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .detail-article__note {
    margin: 0 0 25px;
  }
}
.detail-article img:not([class]) {
  margin: 0 0 47px;
  object-fit: cover;
  width: 100%;
}
.detail-article p:not([class]) {
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .detail-article p:not([class]) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
.detail-article .title_h2:not([class]), .detail-article h2:not([class]) {
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 2.125rem;
  margin: 20px 0 20px 0;
}
@media screen and (max-width: 767px) {
  .detail-article .title_h2:not([class]), .detail-article h2:not([class]) {
    font-size: 1rem;
    margin: 31px 0 27px;
  }
}
.detail-article__description {
  max-width: 706px;
  padding: 0 20px 0 0;
}
@media (max-width: 1200px) {
  .detail-article__description {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .detail-article__description {
    max-width: none;
  }
}
.detail-article__description a:not([class]) {
  color: #0a71cc;
  text-decoration: none;
}
.detail-article__description iframe {
  width: 100%;
}
.detail-article__description ul,
.detail-article__description ol {
  margin-left: 20px;
  margin-top: 25px;
  margin-bottom: 30px;
}
.detail-article__description ul:not([class]) {
  margin-left: 0;
}
.detail-article__description ul:not([class]) li {
  display: flex;
  align-items: center;
}
.detail-article__description ul:not([class]) li:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #0a71cc;
  margin-right: 12px;
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.detail-article__description ol:not([class]) {
  list-style-type: decimal;
}

.detail-blog {
  padding: 30px 0 0;
}
.detail-blog__bonus .bonus-blog__grid {
  align-items: flex-start;
  background-image: url("/wp-content/themes/studservice/new/img/bonus-blog_back.png");
  border-radius: 16px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 45px 70px;
}
.detail-blog__bonus .bonus-blog__title {
  font-family: "Gotham Pro", Verdana, Arial, sans-serif;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin: 0;
}
.detail-blog__bonus .bonus-blog__description {
  margin: 29px 0 0;
}
.detail-blog__bonus .bonus-blog__btn {
  margin: 35px 0 0;
}
.detail-blog__title {
  font-family: "Gotham Pro", Verdana, Arial, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin: 40px 0 0;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 970px) {
  .detail-blog__title {
    font-size: 1.875rem;
    line-height: normal;
  }
}
@media screen and (max-width: 767px) {
  .detail-blog__title {
    margin: 16px 0 0;
  }
}
.detail-blog__col {
  flex: 0 0 auto;
}
.detail-blog__grid {
  justify-content: space-between;
}
.detail-blog__content {
  flex: 2;
  max-width: 690px;
}
@media screen and (max-width: 1200px) {
  .detail-blog__content {
    max-width: 100%;
  }
}
.detail-blog__row {
  align-items: center;
  justify-content: center;
  margin: 47px 0 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .detail-blog__row {
    justify-content: center;
    margin: 31px 0 0;
  }
}
@media (max-width: 425px) {
  .detail-blog__row {
    justify-content: space-between;
  }
}
.detail-blog__text {
  color: #abb5be;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-bottom: 12px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .detail-blog__text {
    text-align: left;
  }
}
@media (max-width: 425px) {
  .detail-blog__text {
    margin: 0;
    line-height: 1rem;
    margin-bottom: 0;
  }
}
.detail-blog__text:last-child {
  margin-bottom: 0;
}
.detail-blog__text span {
  color: #0a71cc;
}
.detail-blog__tags {
  justify-content: center;
  flex-wrap: wrap;
}
.detail-blog__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .detail-blog__info {
    margin: 0;
  }
}
@media (max-width: 425px) {
  .detail-blog__info {
    align-items: flex-start;
    row-gap: 12px;
  }
}
.detail-blog__aside {
  flex-wrap: wrap;
  max-width: 370px;
}

.feedback-article {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 6px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
  padding: 44px 42px 51px;
  width: 100%;
  max-width: 728px;
}
@media screen and (max-width: 767px) {
  .feedback-article {
    width: auto;
    margin: 20px 0 0;
    padding: 27px 15px;
  }
}
.feedback-article__button {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
}
.feedback-article__title {
  color: #0a71cc;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .feedback-article__title {
    font-size: 1rem;
  }
}
.feedback-article__group {
  margin-bottom: 16px;
  padding: 0 8px;
}
@media screen and (max-width: 767px) {
  .feedback-article__group {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.feedback-article__group_middle {
  flex: 1 1 50%;
  max-width: 50%;
}
@media (max-width: 768px) {
  .feedback-article__group_middle {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.feedback-article__group_large {
  flex: 1 1 50%;
  max-width: 100%;
}
.feedback-article__grid {
  margin: 36px -8px 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .feedback-article__grid {
    margin-top: 22px;
  }
}
.feedback-article .select-wrapper {
  cursor: pointer;
  z-index: 10;
}
.feedback-article .select-wrapper:after {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: "";
  cursor: pointer;
  height: 8px;
  position: absolute;
  right: 32px;
  top: 45%;
  transform: rotate(45deg) translateY(-50%);
  width: 8px;
  z-index: -1;
}
.feedback-article__element {
  appearance: none;
  border: 1px solid #abb5be;
  border-radius: 6px;
  color: #abb5be;
  font-size: 1rem;
  min-height: 54px;
  padding: 8px 16px;
  resize: none;
  width: 100%;
  height: 100%;
  transition: border-color 0.3s ease;
  font-family: Proxima Nova;
  font-weight: 700;
}
@media (max-width: 767px) {
  .feedback-article__element {
    height: 100%;
  }
}
.feedback-article__element::placeholder {
  color: inherit;
}
.feedback-article__element.error {
  border-color: #dc3545;
}
.feedback-article::-moz-placeholder, .feedback-article::-webkit-input-placeholder, .feedback-article:-ms-input-placeholder, .feedback-article::placeholder {
  color: #abb5be;
}
.feedback-article__select {
  background-image: url(../img/icon-arrow.svg);
  background-position: center right 20px;
  background-repeat: no-repeat;
  background-size: 10px;
  cursor: pointer;
}
.feedback-article__textarea {
  appearance: none;
  height: 99px;
}
.feedback-article__button {
  border-radius: 6px;
  justify-content: center;
}
.feedback-article .footer__popup__agreement {
  margin-top: 0;
  margin-bottom: 20px;
}
.feedback-article .footer__popup__agreement label {
  line-height: 16px;
}

.filter-comments {
  font-size: 1rem;
}
.filter-comments__button {
  align-items: center;
  display: flex;
  line-height: 1;
  margin-left: 5px;
  text-decoration: underline;
}
.filter-comments__button:after {
  background-image: url(../img/icon-filter.svg);
  margin-left: 9px;
}
.filter-comments__button_old:after {
  transform: rotate(-180deg);
}

.form-comments__textarea {
  appearance: none;
  border: none;
  border-radius: 6px;
  color: #abb5be;
  font-size: 1rem;
  height: 45px;
  outline: 0;
  padding: 15px 16px 0;
  resize: none;
  width: 100%;
}
.form-comments__textarea::-moz-placeholder, .form-comments__textarea::-webkit-input-placeholder, .form-comments__textarea::placeholder {
  color: #abb5be;
}
.form-comments__file {
  height: 0.1px;
  opacity: 0;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}
.form-comments__button {
  background-color: #0a71cc;
  border-radius: 4px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 6px 10px 5px;
  text-transform: uppercase;
}
.form-comments__button:after {
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  height: 14px;
  width: 14px;
}
.form-comments__label {
  display: flex;
}
.form-comments__label:before {
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  height: 14px;
  width: 14px;
  background-image: url(../img/icon-attach.svg);
  background-size: 100%;
  cursor: pointer;
  margin-right: 10px;
}
.form-comments .file__text {
  color: #88898a;
  font-size: 0.75rem;
  margin-right: 5px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-comments__footer {
  align-items: center;
  bottom: 10px;
  position: absolute;
  right: 20px;
}

.fresh-blog {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 10px 27px 15px 19px;
}
.fresh-blog__title {
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 2.125rem;
  margin: 0;
  text-align: center;
  text-transform: none;
}
.fresh-blog__item {
  margin-bottom: 15px;
  min-height: 95px;
}
.fresh-blog__item:last-child .fresh-blog__content {
  border-bottom: none;
}
.fresh-blog__footer {
  color: #949494;
  font-size: 0.5625rem;
  letter-spacing: 0.055em;
  line-height: 0.6875rem;
  margin-top: auto;
}
.fresh-blog__footer p {
  font-style: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: inherit;
}
.fresh-blog__name {
  color: #000;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-decoration: none;
}
.fresh-blog__avatar {
  border-radius: 50%;
  flex: 1 0 auto;
  max-height: 84px;
  max-width: 84px;
  object-fit: cover;
}
.fresh-blog__time {
  margin-bottom: 0;
}
.fresh-blog__date {
  margin-bottom: 0;
}
.fresh-blog__content {
  border-bottom: 1px solid #dee2e6;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 28px;
  row-gap: 10px;
  padding-bottom: 9px;
}
.fresh-blog__list {
  margin: 20px 0 0;
}

.nav-blog {
  background-color: #fff;
}
.nav-blog__link {
  color: inherit;
  font-size: 0.875rem;
  letter-spacing: -0.02em;
  margin: 0 12px;
  padding: 9px 0 10px;
  white-space: nowrap;
}
.nav-blog__item, .nav-blog .cat-item {
  font-size: 0.875rem;
  letter-spacing: -0.02em;
  color: #0a71cc;
  margin: 0;
  white-space: nowrap;
}
.nav-blog .cat-item {
  padding: 0 12px;
}
.nav-blog a {
  position: relative;
  padding: 9px 0 10px;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: inline-block;
  text-decoration: none;
  color: #a6a6a6;
  transition: color 0.3s ease;
}
.nav-blog a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}
.nav-blog a.active {
  color: #000;
}
.nav-blog a.active:after {
  background-color: #000;
}
.nav-blog__item {
  padding: 0 12px;
}
.nav-blog__menu {
  position: relative;
  transition: all 0.6s;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  list-style-type: none;
}
.nav-blog__menu .owl-stage {
  display: flex;
}
.nav-blog__menu .owl-item {
  position: relative;
}
.nav-blog__menu .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: 0;
  width: 230px;
  height: 100%;
  padding: 0 0 0 15px;
  transform: translate(-2px, -50%);
  z-index: 2;
}
@media (max-width: 768px) {
  .nav-blog__menu .owl-nav .owl-prev {
    width: 40px;
  }
}
.nav-blog__menu .owl-nav .owl-prev img {
  position: relative;
  width: 15px;
  z-index: 3;
}
.nav-blog__menu .owl-nav .owl-prev:before {
  width: 50px;
  bottom: 0;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0, #fff 90%, #fff);
  left: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
  width: 50px;
}
@media (min-width: 768px) {
  .nav-blog__menu .owl-nav .owl-prev:before {
    width: 200px;
  }
}
.nav-blog__menu .owl-nav .owl-prev.disabled {
  display: none;
}
.nav-blog__menu .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  right: 0;
  width: 30px;
  height: 100%;
  padding: 0 15px 0 0;
  transform: translate(2px, -50%);
  z-index: 2;
}
.nav-blog__menu .owl-nav .owl-next img {
  transform: rotate(180deg);
  position: relative;
  width: 15px;
  z-index: 3;
}
.nav-blog__menu .owl-nav .owl-next:before {
  width: 50px;
  bottom: 0;
  content: "";
  display: block;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #fff 90%, #fff);
  right: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
  width: 50px;
}
@media (min-width: 768px) {
  .nav-blog__menu .owl-nav .owl-next:before {
    width: 200px;
  }
}
.nav-blog__menu .owl-nav .owl-next.disabled {
  display: none;
}
.nav-blog__container {
  max-width: 1185px;
  overflow: hidden;
  position: relative;
}
.nav-blog__btn {
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  z-index: 100;
}
.nav-blog__btn:after {
  border-bottom: 2px solid #767676;
  border-right: 2px solid #767676;
  content: "";
  height: 10px;
  position: absolute;
  width: 10px;
}
.nav-blog__btn_active {
  opacity: 1;
}
.nav-blog__btn-left {
  left: 0;
  opacity: 0;
}
.nav-blog__btn-left:after {
  left: 10px;
  transform: translateY(-50%) rotate(135deg);
}
.nav-blog__btn-right {
  right: 0;
}
.nav-blog__btn-right:after {
  right: 10px;
  transform: translateY(-50%) rotate(-45deg);
}

.note {
  border: 1px solid #b3da26;
  border-radius: 6px;
  padding: 60px 25px 36px;
  position: relative;
}
.note__name {
  background-color: #b3da26;
  border-radius: 6px;
  font-size: 1.125rem;
  font-weight: 600;
  left: 0;
  line-height: 1;
  padding: 12px 28px 12px 54px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
}
.note:before {
  background-image: url(../img/bulb-icon.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  height: 30px;
  left: 18px;
  position: absolute;
  top: -4px;
  width: 28px;
}

.search__footer {
  align-items: center;
  margin: 6px 0 0;
}
@media screen and (max-width: 767px) {
  .search__footer {
    align-items: flex-start;
    display: none;
  }
}
.search__input {
  background-color: transparent;
  border: 1px solid #b3da26;
  border-radius: 6px 0 0 6px;
  color: #abb5be;
  font-size: 1rem;
  height: 38px;
  line-height: 1;
  padding: 0 15px;
  width: 100%;
}
.search__input::placeholder {
  color: #abb5be;
}
.search__button {
  align-items: center;
  background-color: #b3da26;
  border-radius: 0 6px 6px 0;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  min-height: 100%;
  min-width: 40px;
}
.search__button:after {
  background-image: url("/wp-content/themes/studservice/new/img/search-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  height: 19px;
  width: 19px;
}
.search__text {
  color: #abb5be;
  font-size: 1rem;
  letter-spacing: -0.02em;
  line-height: 1;
  margin-bottom: 0;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .search__text {
    line-height: 0.875rem;
  }
}
.search__text_color-dark {
  color: #000;
  font-size: 0.875rem;
  margin-right: 5px;
}
.search__link {
  color: inherit;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .search__link {
    display: inline;
    font-size: 0.875rem;
  }
}
.search__link:hover {
  text-decoration: underline;
}

.select-wrapper {
  cursor: pointer;
  position: relative;
}

.sidebar {
  max-width: 370px;
  min-width: 370px;
  margin: 0 0 31px;
}

.social {
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
@media screen and (max-width: 767px) {
  .social {
    align-items: flex-end;
    flex-direction: column;
  }
}
@media (max-width: 425px) {
  .social {
    position: static;
    top: auto;
    right: auto;
    transform: translate(0, 0);
    row-gap: 12px;
  }
}
.social__text {
  color: #abb5be;
  font-size: 0.75rem;
  margin-right: 14px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .social__text {
    margin: 0 0 11px;
    text-align: right;
  }
}
@media (max-width: 425px) {
  .social__text {
    margin: 0;
  }
}
.social__item {
  margin: 0 7px;
}
.social__link {
  align-items: center;
  background-color: #b3da26;
  border-radius: 50%;
  color: #000;
  display: flex;
  font-size: 0.75rem;
  height: 32px;
  justify-content: center;
  width: 32px;
}
@media screen and (max-width: 767px) {
  .social__link {
    height: 26px;
    width: 26px;
  }
}
.social__list {
  margin: 0 -7px;
}

.textarea-wrapper {
  border: 1px solid #ced4da;
  border-radius: 6px;
  min-height: 120px;
  position: relative;
}

.title_color-white {
  color: #fff;
}
.title_center {
  text-align: center;
}
.title_h2 {
  margin: 0;
  font-family: "Gotham Pro", Verdana, Arial, sans-serif;
  margin-top: 10px;
  margin-bottom: 30px;
}
@media screen and (max-width: 970px) {
  .title_h2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .title_h2 {
    font-size: 1rem;
  }
}

.city-list {
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  list-style-type: none;
}
.city-list li {
  margin-bottom: 8px;
  line-height: 26px;
}
.city-list li strong a {
  font-weight: 900;
  font-size: 18px;
}
.city-list li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}
.city-list__box {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 50vh;
}
.city-list__elite {
  margin-bottom: 22px;
}

.leadHunterForm .footer__popup__agreement {
  margin-top: 0;
  margin-bottom: 20px;
}

.mobile-nav__location {
  text-decoration: none;
  color: #000000;
}
.mobile-nav__close {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  outline: none;
  cursor: pointer;
}

.partners-agency__header-button {
  text-transform: uppercase;
}
.partners-agency__header-button--empty {
  background-color: rgba(255, 255, 255, 0);
  color: #0a71cc;
}

.partners-calc-form {
  background-color: #313845;
  padding: 100px 0;
  /*

    REMOVE SLIDER STYLE DEFAULTS

  */
  /*

    HANDLE

  */
  /*

    TRACK

  */
}
@media (max-width: 767px) {
  .partners-calc-form {
    padding: 55px 0;
  }
}
.partners-calc-form__title {
  color: #ffffff;
  /*     font-weight: 400; */
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 35px;
}
@media (max-width: 1023px) {
  .partners-calc-form__title {
    font-size: 24px;
    line-height: 23px;
  }
}
@media (max-width: 767px) {
  .partners-calc-form__title {
    font-size: 16px;
    line-height: 15px;
  }
}
.partners-calc-form__wrapper {
  display: flex;
  column-gap: 17px;
}
@media (max-width: 1023px) {
  .partners-calc-form__wrapper {
    flex-direction: column-reverse;
    row-gap: 17px;
  }
}
.partners-calc-form__main-form {
  padding: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  flex-grow: 1;
}
@media (max-width: 767px) {
  .partners-calc-form__main-form {
    padding: 30px 20px;
  }
}
.partners-calc-form__range {
  position: relative;
}
.partners-calc-form__select-wrapper {
  width: max-content;
  margin: 0 0 22px;
}
@media (max-width: 767px) {
  .partners-calc-form__select-wrapper {
    width: 100%;
  }
}
.partners-calc-form__select-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 10px;
}
@media (max-width: 767px) {
  .partners-calc-form__select-title {
    font-size: 14px;
    line-height: 17px;
  }
}
.partners-calc-form__range-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
@media (max-width: 767px) {
  .partners-calc-form__range-title {
    font-size: 14px;
    line-height: 17px;
  }
}
.partners-calc-form__result-form {
  padding: 40px;
  width: 386px;
  border-radius: 15px;
  background-color: #b3da26;
}
@media (max-width: 1023px) {
  .partners-calc-form__result-form {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .partners-calc-form__result-form {
    padding: 30px 20px;
  }
}
.partners-calc-form__result-summ {
  font-family: "Gotham Pro", Verdana, Arial, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 34px;
  margin: 0 0 16px;
}
.partners-calc-form__first-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 16px;
}
@media (max-width: 1023px) {
  .partners-calc-form__first-text br {
    display: none;
  }
}
.partners-calc-form__second-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}
@media (max-width: 1023px) {
  .partners-calc-form__second-text br {
    display: none;
  }
}
.partners-calc-form .tick-slider-value-container {
  position: relative;
  width: 100%;
  height: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-family: "Hind Madurai", sans-serif;
  font-size: 18px;
  color: #71738b;
}
.partners-calc-form .tick-slider-value {
  position: absolute;
  top: 0;
  font-weight: bold;
  color: #34385a;
  border-radius: 4px;
}
.partners-calc-form .tick-slider-value > div {
  animation: bulge 0.3s ease-out;
}
.partners-calc-form .tick-slider-background,
.partners-calc-form .tick-slider-progress,
.partners-calc-form .tick-slider-tick-container {
  position: absolute;
  bottom: 22px;
  left: 0;
  height: 8px;
  pointer-events: none;
  border-radius: 4px;
}
.partners-calc-form .tick-slider-background {
  width: 100%;
  bottom: 22px;
  background-color: #ededed;
}
.partners-calc-form .tick-slider-progress {
  background: linear-gradient(270deg, #0a71cc 5.56%, #e0e5f2 100%);
}
.partners-calc-form .tick-slider-tick-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
}
.partners-calc-form .tick-slider-label {
  opacity: 0.85;
  transition: opacity 0.1s ease;
}
.partners-calc-form .tick-slider-label.hidden {
  opacity: 0;
}
@keyframes bulge {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.partners-calc-form input[type=range] {
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  margin: -5px 0;
}
.partners-calc-form input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
}
.partners-calc-form input[type=range]:focus {
  outline: none;
}
.partners-calc-form input[type=range]::-moz-focus-outer {
  border: 0;
}
.partners-calc-form input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  background: #ffa929;
  background: #fff;
  border: none;
  border-radius: 50%;
  border: 4px solid #0a71cc;
  cursor: pointer;
  margin-top: -10px;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.partners-calc-form input[type=range]:hover::-webkit-slider-thumb,
.partners-calc-form input[type=range]:focus::-webkit-slider-thumb {
  transform: scale(1.2);
}
.partners-calc-form input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
  background: #fff;
  border: none;
  border-radius: 50%;
  border: 4px solid #0a71cc;
  cursor: pointer;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.partners-calc-form input[type=range]:hover::-moz-range-thumb,
.partners-calc-form input[type=range]:focus::-moz-range-thumb {
  transform: scale(1.2);
}
.partners-calc-form input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
}
.partners-calc-form input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: none;
  border-radius: 4px;
}
.partners-calc-form input[type=range]:focus::-webkit-slider-runnable-track {
  background: none;
}
.partners-calc-form input[type=range]:active::-webkit-slider-runnable-track {
  background: none;
}

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom; /* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox { /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}

.mCSB_container { /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto;
}

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
} /* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container { /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
} /* RTL direction/left-side scrollbar */
.mCSB_scrollTools { /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
} /* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools { /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
} /* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer { /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger { /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px; /* minimum dragger height */
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar { /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px; /* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px; /* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
} /* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
} /* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px; /* minimum dragger width */
  height: 100%;
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px; /* auto-expanded scrollbar */
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px; /* auto-expanded scrollbar */
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper { /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; /* non-visible scrollbar */
  margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
----------------------------------------
6.1 THEMES 
----------------------------------------
*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)";
}

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0);
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)";
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)";
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png); /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
  sprites locations 
  light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
  dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
  */
}

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
  sprites locations
  light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
  dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
  */
}

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
  sprites locations 
  light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
  dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
  */
}

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
  sprites locations 
  light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
  dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
  */
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 5px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px; /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px; /* auto-expanded scrollbar */
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px; /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px; /* auto-expanded scrollbar */
  margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px;
}

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

/* ---------------------------------------- */
/*======================================
  Selectric v1.13.0
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #abb5be;
  border-radius: 6px;
  background: #fff;
  position: relative;
  overflow: hidden;
}
.selectric-below .selectric {
  border-color: #0a71cc;
}
.selectric-form__field {
  margin-bottom: 22px;
}
.selectric-form__field.error {
  border-color: #dc3545;
}
.selectric-form__field.error::placeholder {
  color: #dc3545 !important;
}
.selectric-form__field.error.selectric-below .selectric {
  border-color: #0a71cc;
}
.selectric-form__field.error.selectric-below .selectric .label {
  color: #0a71cc !important;
}
.selectric-form__field .selectric {
  height: 52px;
}
.selectric-form__field .selectric .label {
  padding: 16px 26px;
  margin-left: 0;
  font-size: 16px;
  line-height: 21px;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 40px 0 1rem;
  font-size: 16px;
  line-height: 1;
  padding: 17px 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Proxima Nova";
  color: #abb5be;
  height: 52px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.selectric-below .selectric .label {
  color: #0a71cc;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: transparent;
  color: transparent;
  text-align: center;
}

.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 15px;
  height: 10px;
  background: url("../img/arrow-down.svg") no-repeat 50% 50%;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric-items {
  display: block;
  z-index: 100;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #f8f8f8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul,
.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 10px;
  color: #666;
  cursor: pointer;
}

.selectric-items li.selected {
  background: #e0e0e0;
  color: #444;
}

.selectric-items li.highlighted {
  background: #d0d0d0;
  color: #444;
}

.selectric-items li:hover {
  background: #d5d5d5;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  border: none;
  background-color: transparent;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.dropzone, .dropzone * {
  box-sizing: border-box;
}

.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 54px 54px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.js-dropzone .work-message-photo-item {
  display: inline-block;
  max-width: 200px;
  position: relative;
  text-align: center;
}

.js-dropzone .work-message-photo-delete {
  position: absolute;
  right: 5px;
  top: 5px;
}

.js-dropzone .work-message-photo-text {
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}

/*
 * Theme name: СтудСервис
 * Author: СтудСервис
 * Author URI: https://studservis.ru
 * Version: 1.0
 */
#addReview .form__field.error {
  /* margin-bottom: 0; */
}

.selectric-form__field.error .selectric {
  border-color: #dc3545;
}

.selectric-form__field.error .selectric span {
  color: #dc3545;
}

.review-rating.error {
  color: #dc3644;
}

.review-rating.error .reviews__item__rating {
  /* margin-bottom: 0; */
}

h1.no-style {
  font-weight: initial;
  text-transform: inherit;
  letter-spacing: inherit;
}

span.error {
  display: none !important;
}

#addReview span.error {
  color: #dc3545;
  font-size: 10px;
  /* display: block !important; */
  display: none;
}

.formLoader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.hiddenCalcForm {
  display: none;
}

@-webkit-keyframes load5 {
  0%, 100% {
    box-shadow: 0em -2.6em 0em 0em #5c5c5c, 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.5), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.7), 1.8em -1.8em 0 0em #5c5c5c, 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.5), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7), 2.5em 0em 0 0em #5c5c5c, 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5), 2.5em 0em 0 0em rgba(92, 92, 92, 0.7), 1.75em 1.75em 0 0em #5c5c5c, 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.5), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.7), 0em 2.5em 0 0em #5c5c5c, -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.5), 0em 2.5em 0 0em rgba(92, 92, 92, 0.7), -1.8em 1.8em 0 0em #5c5c5c, -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.5), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.7), -2.6em 0em 0 0em #5c5c5c, -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.5), -2.6em 0em 0 0em rgba(92, 92, 92, 0.7), -1.8em -1.8em 0 0em #5c5c5c;
  }
}
@keyframes load5 {
  0%, 100% {
    box-shadow: 0em -2.6em 0em 0em #5c5c5c, 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.5), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.7), 1.8em -1.8em 0 0em #5c5c5c, 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.5), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7), 2.5em 0em 0 0em #5c5c5c, 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5), 2.5em 0em 0 0em rgba(92, 92, 92, 0.7), 1.75em 1.75em 0 0em #5c5c5c, 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.5), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.7), 0em 2.5em 0 0em #5c5c5c, -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.5), 0em 2.5em 0 0em rgba(92, 92, 92, 0.7), -1.8em 1.8em 0 0em #5c5c5c, -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.5), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.7), -2.6em 0em 0 0em #5c5c5c, -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2), 2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.5), -2.6em 0em 0 0em rgba(92, 92, 92, 0.7), -1.8em -1.8em 0 0em #5c5c5c;
  }
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}
html.scroll-lock {
  overflow: hidden;
}

body {
  font-size: 14px;
  line-height: 17px;
  font-family: "Proxima Nova", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
  color: #000;
}

.html--authors {
  scroll-behavior: smooth;
}

a {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*   & .container {
    & .row {
      margin-left: 0;
      margin-right: 0;

      @include media-breakpoint-up(md) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  } */
}
.wrapper input[type=checkbox]:checked, .wrapper input[type=checkbox]:not(:checked), .wrapper input[type=radio]:checked, .wrapper input[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.wrapper input[type=checkbox]:checked + label, .wrapper input[type=checkbox]:not(:checked) + label, .wrapper input[type=radio]:checked + label, .wrapper input[type=radio]:not(:checked) + label {
  position: relative;
  display: inline-block;
  padding-left: 28px;
  cursor: pointer;
}
.wrapper input[type=checkbox]:checked + label:before, .wrapper input[type=checkbox]:not(:checked) + label:before, .wrapper input[type=radio]:checked + label:before, .wrapper input[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  background-color: #fff;
}
.wrapper input[type=checkbox]:checked + label:before, .wrapper input[type=checkbox]:not(:checked) + label:before {
  border-radius: 2px;
  top: 50%;
  transform: translate(0, -50%);
}
.wrapper input[type=radio]:checked + label:before, .wrapper input[type=radio]:not(:checked) + label:before {
  border-radius: 100%;
}
.wrapper input[type=checkbox]:checked + label:after, .wrapper input[type=checkbox]:not(:checked) + label:after, .wrapper input[type=radio]:checked + label:after, .wrapper input[type=radio]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.wrapper input[type=checkbox]:checked + label:after, .wrapper input[type=checkbox]:not(:checked) + label:after {
  top: 50%;
  left: 8px;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background: url("../img/checked.svg") no-repeat 50% 50%;
}
.wrapper input[type=radio]:checked + label:after, .wrapper input[type=radio]:not(:checked) + label:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #b3da26;
}
.wrapper input[type=checkbox]:not(:checked) + label:after, .wrapper input[type=radio]:not(:checked) + label:after {
  opacity: 0;
}
.wrapper input[type=checkbox]:checked + label:after, .wrapper input[type=radio]:checked + label:after {
  opacity: 1;
}
@media (max-width: 575.98px) {
  .wrapper .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.visuallity-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

button,
input,
select,
textarea {
  border-radius: 6px;
}
button:focus, button:active,
input:focus,
input:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  outline: none !important;
}

.experts-wrapper {
  overflow: hidden;
  min-height: 100vh;
  font-size: 14px;
  line-height: 17px;
  font-family: "Proxima Nova";
  color: #000;
}
.experts-wrapper input[type=checkbox]:checked, .experts-wrapper input[type=checkbox]:not(:checked), .experts-wrapper input[type=radio]:checked, .experts-wrapper input[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.experts-wrapper input[type=checkbox]:checked + label, .experts-wrapper input[type=checkbox]:not(:checked) + label, .experts-wrapper input[type=radio]:checked + label, .experts-wrapper input[type=radio]:not(:checked) + label {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
}
.experts-wrapper input[type=checkbox]:checked + label:before, .experts-wrapper input[type=checkbox]:not(:checked) + label:before, .experts-wrapper input[type=radio]:checked + label:before, .experts-wrapper input[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  border-radius: 2px;
}
.experts-wrapper input[type=checkbox]:checked + label:before, .experts-wrapper input[type=checkbox]:not(:checked) + label:before {
  border-radius: 2px;
}
.experts-wrapper input[type=radio]:checked + label:before, .experts-wrapper input[type=radio]:not(:checked) + label:before {
  border-radius: 100%;
}
.experts-wrapper input[type=checkbox]:checked + label:after, .experts-wrapper input[type=checkbox]:not(:checked) + label:after, .experts-wrapper input[type=radio]:checked + label:after, .experts-wrapper input[type=radio]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.experts-wrapper input[type=checkbox]:checked + label:after, .experts-wrapper input[type=checkbox]:not(:checked) + label:after {
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background: url("../img/checked.svg") no-repeat 50% 50%;
}
.experts-wrapper input[type=radio]:checked + label:after, .experts-wrapper input[type=radio]:not(:checked) + label:after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #b3da26;
}
.experts-wrapper input[type=checkbox]:not(:checked) + label:after, .experts-wrapper input[type=radio]:not(:checked) + label:after {
  opacity: 0;
}
.experts-wrapper input[type=checkbox]:checked + label:after, .experts-wrapper input[type=radio]:checked + label:after {
  opacity: 1;
}

.expert-tooltip.show {
  opacity: 1;
}
.expert-tooltip .arrow::before {
  border-bottom-color: #fff;
}
.expert-tooltip .tooltip-inner {
  max-width: 135px;
  padding: 12px 9px;
  font-size: 10px;
  line-height: 12px;
  color: #000;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

.delimtr {
  width: 80%;
  height: 1px;
  background-color: rgba(171, 181, 190, 0.5);
}

.text-green {
  color: #b3da26;
}

.bg-grey {
  overflow: auto;
  background-color: #f2f2f2;
}

@media (min-width: 992px) {
  .bg-social {
    overflow: auto;
    background: url("../img/achievements-bg.svg") no-repeat 50% 10%;
    background-size: 100% auto;
  }
}

.btn {
  font-weight: 700;
  font-family: "Proxima Nova", Arial, sans-serif !important;
  text-decoration: none;
}

.btnCalculate {
  /* display: none; */
  position: fixed;
  right: 36px;
  bottom: 130px;
  background: #0a71cc;
  color: #fff;
  width: 180px;
  font-size: 16px;
  border-radius: 110px;
  cursor: pointer;
  transition: 0.2s;
  z-index: 100;
}
.btnCalculate:before {
  content: "";
  display: block;
  position: absolute;
  width: 34px;
  top: 13px;
  left: 19px;
  height: 34px;
  background: url(../img/icons/calculator.svg);
  background-size: cover;
}
.btnCalculate:hover {
  background: #d71f25;
}
.btnCalculate span {
  width: 95px;
  display: block;
  line-height: 15px;
  padding: 14px 0 14px 60px;
  font-weight: bold;
}

/* @mixin hover-focus {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }

    &.focus-visible:focus {
      @content;
    }
  }
*/
/*  @include hover-focus {
      opacity: 0.8;
    }
*/
/*@mixin hover {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }
  }
*/
/*@mixin focus {
    &.focus-visible:focus {
      @content;
    }
  }
*/
/*@mixin active {
    &.focus-visible:active {
      @content;
    }
  }
*/