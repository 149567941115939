*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img,
svg,
video {
  display: block;
  max-width: 100%;
}
blockquote,
body,
dd,
dl,
figcaption,
figure,
li {
  margin: 0;
}
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

button,
input,
select,
textarea {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0s !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
  }
}

/* a {
  user-select: none;
} */

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

//неведомая хрень
#toTop img {
  width: 50px;
  margin: 0 auto !important;
}

a.bonus-blog__btn.btn.btn-bg-blue,
button.bonus-blog__btn.btn.btn-bg-blue {
  width: 202px;
  height: 52px;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-category-article.margin-bottom .articles-blog__item.d-flex {
  margin-bottom: 40px;
}
// закончилась хрень

.main {
  background: #e4eaee;
  padding: 0 0 45px !important;
}

.btn-primary:disabled {
  &:hover {
    cursor: auto;
  }
}

.visually-hidden {
  visibility: hidden;

  &_clip {
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    position: absolute;
    width: 1px;
  }
}

.blog-wrapper {
  & .article-keep__button,
  & .article-keep__item:before,
  & .comments__main,
  & .comments__name,
  & .comments__time,
  & .detail-article,
  & .detail-article .title_h2:not([class]),
  & .detail-article h2:not([class]),
  & .feedback-article__title,
  & .filter-comments,
  & .form-comments,
  & .form-comments__button {
    font-family: ProximaNova;
  }

  li {
    margin-bottom: 10px;
  }

  & ul:not([class]) {
    margin-left: 0;
  }

  & .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }

  & .main {
    flex: 1;
    //overflow-x: hidden;
    position: relative;
  }

  & .d-flex {
    display: flex;
  }

  & .wrap {
    flex-wrap: wrap;
  }

  & .responsive-img {
    height: auto;
    max-width: 100%;
  }

  & .sticky {
    position: fixed;
    z-index: 101;
  }

  & .stop {
    position: relative;
    z-index: 101;
  }

  & .box-center {
    text-align: center;
  }

  & .btn {
    background: 0 0;
    border: none;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
    outline: 0;
    padding: 15.5px 2rem;
    text-decoration: none;
    text-transform: uppercase;
    transition-duration: 0.2s;

    &_transparent {
      background-color: transparent;
      border: 1px solid #173091;
      color: #173091;
      font-size: 0.75rem;
      font-weight: 700;
      width: 180px;
    }

    &_transparent-color_white {
      border: 1px solid #fff;
      color: #fff;
    }

    &_color-skyblue {
      background-color: #5fc3f1;
      color: #fff;
      font-weight: 700;
    }

    &-xs {
      width: 230px;
    }

    &-label {
      display: inline-block;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    &-bg {
      color: #fff;
    }

    &-br {
      border: 2px solid;

      &.disabled {
        border-color: #929292;
        color: rgba(0, 0, 0, 0.8);
      }
    }

    &-nopadd {
      padding-left: 0;
      padding-right: 0;
    }

    &-block {
      display: block;
      width: 100%;
    }

    &-bg-blue {
      background-color: #0a71cc;
      color: #fff;

      &:hover {
        background-color: #3c4afa;
      }

      &:active {
        background-color: #0814a6;
      }
    }

    &-br-blue {
      border-color: #0a71cc;
      color: #000;

      &:hover {
        border-color: #3c4afa;
      }

      &:active {
        border-color: #0814a6;
      }
    }

    &-bg-red {
      background-color: #fd2b1c;

      &:hover {
        background-color: #fc3752;
      }

      &:active {
        background-color: #a8001c;
      }
    }

    &-br-red {
      border-color: #fd2b1c;

      &:hover {
        border-color: #fc3752;
      }

      &:active {
        border-color: #a8001c;
      }
    }
  }

  & .stop .article-keep__button {
    margin-top: -90px;
  }

  & .horizontal-scroll-wrapper {
    background-attachment: local, local, scroll, scroll;
    background-color: #fff;
    background-image: linear-gradient(to right, #fff, #fff), linear-gradient(to right, #fff, #fff),
      linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
      linear-gradient(to left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    background-image: -webkit-gradient(linear, left top, right top, from(white), to(white)),
      -webkit-gradient(linear, left top, right top, from(white), to(white)),
      -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.8))),
      -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.8)));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-size: 40px 100%, 20px 100%, 10px 100%, 40px 100%;
    overflow-style: autohiding-scrollbar;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
    white-space: nowrap;
  }

  & .horizontal-scroll-wrapper::-webkit-scrollbar {
    display: none;
  }
}
