.worktape-carousel {
  margin-top: 40px;
  margin-bottom: 40px;
  &__item {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 265px;

    border: 1px solid #abb5be;
    border-radius: 6px;

    @include media-breakpoint-up(md) {
      width: 290px;
      height: 265px;
    }
    @include media-breakpoint-up(lg) {
      width: 290px;
      height: 265px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 20px 10px 1rem;
      height: 100%;
      overflow: hidden;

      &__theme {
        margin-bottom: 8px;

        font-size: 16px;
        line-height: 19px;

        @include media-breakpoint-up(md) {
          margin-bottom: 8px;

          font-size: 16px;
          line-height: 19px;
        }
      }
      &__subject {
        margin-bottom: 22px;

        font-size: 16px;
        line-height: 19px;

        @include media-breakpoint-up(md) {
          margin-bottom: 22px;

          font-size: 16px;
          line-height: 19px;
        }

        & span {
          color: #8d8d8d;
        }
      }
      &__title {
        position: relative;

        overflow: hidden;

        max-height: 96px;
        height: 100%;

        font-size: 18px;
        line-height: 24px;

        color: #0a71cc;

        & a {
          color: #0a71cc;
          text-decoration: none;
          &::first-letter {
            text-transform: uppercase;
          }
        }
        @include media-breakpoint-up(md) {
          max-height: 96px;
          font-size: 18px;
          line-height: 24px;
          color: #0a71cc;
        }

        &::before {
          content: '';

          position: absolute;
          bottom: 0;
          left: -10px;

          display: block;

          width: calc(100% + 20px);
          height: 10px;

          background: rgb(255, 255, 255);
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.7) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }
    }

    &__price {
      padding: 22px 10px 30px;

      font-size: 16px;
      line-height: 19px;

      color: #fff;
      background: #b3da26;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 19px;
      }

      & span {
        font-weight: 700;
      }
    }
  }

  //& .owl-carousel .owl-stage-outer {
  //    overflow: visible !important;
  //}

  & .owl-dots {
    margin-top: 40px;
    margin-bottom: 0;

    & .owl-dot {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 10px;
      height: 10px;

      background-color: transparent !important;

      & span {
        width: 6px;
        height: 6px;

        border-radius: 50%;
        background-color: #c8cdd2;
      }

      &.active {
        & span {
          width: 10px;
          height: 10px;

          background-color: #b3da26;
        }
      }
    }
  }
}
