.worktape {
  &-filter {
    margin-bottom: 30px;
    padding: 20px 10px;
    box-sizing: border-box;

    border: 1px solid #ced4da;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      padding: 50px 40px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 30px;
      padding: 50px;
    }

    &__title {
      margin-bottom: 22px;

      font-weight: 700;
      font-size: 18px;
      line-height: 24px;

      text-align: center;

      color: $blue;
    }

    & .form__field,
    & .selectric-form__field {
      margin-bottom: 11px;

      @include media-breakpoint-up(md) {
        margin-bottom: 22px;
      }
    }

    &__btn {
      // margin-top: 9px;
    }
  }
  &-control {
    display: none;
    overflow: hidden;

    color: #000;
    border-radius: 16px;
    background: #b3da26 url('../img/bg.jpg') no-repeat 50% 50%;
    background-size: cover;

    @include media-breakpoint-up(sm) {
      display: block;

      padding: 48px 30px;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      padding: 40px 80px;

      text-align: left;
    }
    @include media-breakpoint-up(lg) {
      max-width: 370px;
      padding: 45px 70px;

      text-align: left;
    }

    &__title {
      margin-bottom: 25px;

      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      font-family: 'GothamPro', Verdana, Arial, sans-serif;

      text-transform: uppercase;
    }

    &__content {
      margin-bottom: 25px;

      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }

    & .btn {
      display: block;
      width: 100%;

      padding: 15.5px 10px;

      @include media-breakpoint-up(md) {
        width: 202px;
      }

      @include media-breakpoint-up(lg) {
        width: 100%;
      }

      font-weight: 700;
      font-size: 16px;
      line-height: 19px;

      text-decoration: none;
      text-transform: uppercase;

      color: #fff;
      border-radius: 6px;
      background: #0a71cc;
      transition: all 0.3s ease-out;

      &:hover {
        color: #0a71cc;
        border: 1px solid #0a71cc;
        background: #fff;
        box-shadow: 0 0 5px #b3da26;
      }

      &:focus,
      &:active {
        color: #fff;
        background: #005bab;
        box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__catalog {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 40px;

    &__title {
      margin-bottom: 40px;

      // font-weight: 600;
      // font-size: 24px;
      // line-height: 29px;

      text-align: center;

      color: #000;

      & a {
        color: inherit;
      }
    }

    &__item {
      position: relative;
      z-index: 1;

      margin-bottom: -1px;
      padding: 20px 11px;

      border: 1px solid #b3da26;
      border-radius: 6px;
      background-color: #fff;

      font-size: 14px;
      line-height: 20px;

      @include media-breakpoint-up(md) {
        padding: 35px 30px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 19px;
        padding: 36px 30px;
      }

      &:hover {
        z-index: 10;

        background: #fff7e3;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      }

      &__date {
        color: #000;

        margin-bottom: 8px;
        @include media-breakpoint-up(md) {
          margin-bottom: 20px;
        }

        & span {
          color: #8d8d8d;
        }
      }

      &__number {
        margin-bottom: 8px;
        @include media-breakpoint-up(md) {
          margin-bottom: 20px;
        }

        color: #8d8d8d;

        & span {
          color: #000;
        }
      }

      &__text {
        margin-bottom: 8px;
        @include media-breakpoint-up(md) {
          margin-bottom: 22px;
        }

        color: #8d8d8d;

        font-size: 16px;
        line-height: 19px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 24px;
        }

        & a {
          text-decoration: none;

          color: #0a71cc;
        }
      }

      &__tag {
        display: flex;
        margin-bottom: 8px;

        color: #8d8d8d;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }

        & a {
          color: inherit;
        }

        &:before {
          content: '';
          display: block;
          width: 15px;
          height: 15px;
          background: url('../img/icons/date.svg') no-repeat 50% 50%;
          margin-right: 8px;
        }
      }

      &__price {
        margin-bottom: 8px;

        color: #8d8d8d;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;

          font-size: 16px;
          line-height: 19px;
        }

        & span {
          color: #000;
        }
      }

      &__rating {
        display: flex;
        & span {
          display: block;

          width: 21px;
          height: 20px;
          margin-right: 4px;

          background: url('../img/star.svg') no-repeat 50% 50%;
          background-size: 21px 20px;

          &.current {
            background: url('../img/star-current.svg') no-repeat 50% 50%;
            background-size: 21px 20px;
          }
        }
      }
    }

    &__more {
      display: block;

      width: 202px;
      margin: 0 auto 61px;
      padding: 7.5px 10px;

      font-weight: 700;
      font-size: 12px;
      line-height: 15px;

      cursor: pointer;
      text-transform: uppercase;

      color: #0a71cc;
      border: 1px solid #0a71cc;
      border-radius: 6px;
      background-color: #fff;

      @include media-breakpoint-up(md) {
        margin-bottom: 99px;
        padding: 14.5px 10px;
      }
      @include media-breakpoint-up(lg) {
        padding: 15.5px 10px;

        font-size: 16px;
        line-height: 19px;
      }

      &:hover {
        box-shadow: 0 0 5px #b3da26;
      }

      &:focus,
      &:active {
        box-shadow: inset 2px 4px 5px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.worktape-description {
  margin-top: 55px;
  margin-bottom: 55px;

  @include media-breakpoint-up(md) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &__box {
    box-sizing: border-box;

    border: 1px solid #b3da26;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
      display: flex;
    }

    &__content {
      font-size: 16px;
      line-height: 19px;
      padding: 25px;

      @include media-breakpoint-up(md) {
        padding: 30px;
      }

      @include media-breakpoint-up(lg) {
        padding: 20px 50px;
        font-size: 18px;
        line-height: 24px;
        width: 60%;
      }

      & > .row.align-items-center {
        @media (min-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start !important;
          height: 100%;
          justify-content: flex-start;
          row-gap: 20px;
        }

        & .col-md-8,
        & .col-md-4 {
          @media (min-width: 768px) {
            width: 100%;
            flex: 0 0 auto;
            max-width: 100%;
          }
        }

        & .col-md-4 {
          @media (min-width: 768px) {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
          }

          @media (min-width: 992px) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            column-gap: 20px;
          }

          & .btn {
            @media (min-width: 768px) {
              margin: 0 auto;
            }

            @media (min-width: 992px) {
              margin: 0;
            }
          }
        }
      }

      &--green {
        color: #fff;
        background-color: #b3da26;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        @include media-breakpoint-up(lg) {
          width: 40%;
        }

        & .row .row {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
        }

        & > .row.align-items-center {
          row-gap: 20px;

          @media (min-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }

          & .col-md-7,
          & .col-md-5,
          & .col-lg-2 {
            @media (min-width: 768px) {
              width: 100%;
              flex: 0 0 auto;
              max-width: 100%;
            }
          }
        }
      }

      & p {
        margin-bottom: 8px;

        &.label {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;

          color: #8d8d8d;

          @include media-breakpoint-up(md) {
            margin-top: 16px;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      &__label {
        color: #000;
        //margin-bottom: 8px;
        font-weight: 400;
        display: inline-block;
        margin-right: 8px;

        @include media-breakpoint-up(lg) {
          display: block;
          margin-right: 0;
        }
      }

      &__date {
        padding-left: 55px;
        color: #000;

        background: url('../img/date.svg') no-repeat 0 0;
        background-size: 38px 46px;
        font-weight: 400;

        & span {
          color: #fff;
          font-weight: 700;
        }

        & p {
          margin: 8px 0;
        }
      }
    }

    &__workbtn {
      font-size: 16px !important;
      line-height: 19px !important;
      min-height: 52px;
      margin-bottom: 22px;
      margin-top: 22px;

      @include media-breakpoint-up(md) {
        width: 202px;
        margin-bottom: 22px;
        margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
        width: 202px;
        margin-bottom: 22px;
      }
    }

    &__time {
      font-size: 16px;
      line-height: 19px;

      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: url('../img/clock.svg') no-repeat 50% 50%;
        margin-right: 8px;
        background-size: cover;
      }
    }
  }
}
