.experts-control {
    display: none;
    overflow: hidden;

    border-radius: 16px;
    background: #b3da26 url('../img/bg.jpg') no-repeat 50% 50%;
    background-size: cover;

    @include media-breakpoint-up(sm) {
        display: block;

        padding: 48px 30px;
    }
    @include media-breakpoint-up(md) {
        max-width: 290px;
        padding: 48px 30px;

        text-align: center;
    }
    @include media-breakpoint-up(lg) {
        max-width: 370px;

        text-align: left;
    }

    &__title {
        margin-bottom: 25px;

        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        font-family: "GothamPro",Verdana,Arial,sans-serif;

        text-transform: uppercase;
    }

    &__content {
        margin-bottom: 25px;

        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }

    & .btn {
        display: block;

        width: 100%;
        padding: 15.5px 10px;

        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        text-decoration: none;
        text-transform: uppercase;

        color: #fff;
        border-radius: 6px;
        background: #0a71cc;
        transition: all .3s ease-out;

        &:hover {
            color: #0a71cc;
            border: 1px solid #0a71cc;
            background: #fff;
            box-shadow: 0 0 5px #b3da26;
        }

        &:focus,
        &:active {
            color: #fff;
            background: #005bab;
            box-shadow: inset 2px 4px 5px rgba(0, 0, 0, .25);
        }
    }
}
