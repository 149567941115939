.experts-reviews-paginations {
    display: flex;
    justify-content: center;

    margin-top: 40px;
    margin-bottom: 61px;

    @include media-breakpoint-up(md) {
        margin-bottom: 99px;
    }

    & .btn {
        display: block;
        width: 32px;
        height: 32px;
        padding: 0;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        margin: 0 5px;
        border-radius: 4px;
        border: 1px solid #DEE2E6;
        font-weight: 600;

        &:hover {
            background-color: #DEE2E6;
        }

        &.disable {
            opacity: 0.2;
            background-color: #fff;
            cursor: default;
        }

        &.current {
            background-color: #B3DA26;
            border: 1px solid #B3DA26;
        }

        &-prev {
            background-image: url('../img/arrow-left.svg');
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }

        &-next {
            background-image: url('../img/arrow-right.svg');
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
    }
}