.servicies {
    &__catalog {
        margin-top: 55px;
        margin-bottom: 55px;
        padding: 0 15px;

        @include media-breakpoint-up(md) {
            margin-top: 100px;
            margin-bottom: 60px;
            padding: 0;
        }

        &__item {
            position: relative;
            z-index: 1;

            display: flex;
            overflow: hidden;
            flex-direction: column;
            justify-content: space-between;

            margin-bottom: 0;
            padding: 0 20px 30px;

            border: 1px solid #dee2e6;
            transition: all .5s ease-out;

            @include media-breakpoint-up(md) {
                margin-bottom: 40px;
            }
            @include media-breakpoint-up(lg) {
                border-right: none;
            }

            &:before {
                content: '';

                position: absolute;
                bottom: 0;
                left: 0;

                display: block;

                width: 0;
                height: 5px;

                background-color: $green;
                transition: width .5s ease-out;
            }

            &:hover {
                z-index: 10;

                box-shadow: 0 0 30px rgba(0, 0, 0, .15);

                &:before {
                    content: '';

                    position: absolute;
                    bottom: 0;
                    left: 0;

                    display: block;

                    width: 100%;
                    height: 5px;

                    background-color: $green;
                    transition: width .3s ease-in;
                }
            }

            &__pic {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 151px;
                margin-right: -20px;
                margin-bottom: 30px;
                margin-left: -20px;

                background-color: #f2f2f2;
            }

            &__desc {
                margin-bottom: 22px;

                font-size: 16px;
                line-height: 26px;

                color: #68717a;


                & h4 {
                    margin-bottom: 6px;

                    font-size: 18px;
                    line-height: 24px;
                    font-family: inherit;

                    color: #343434;

                    text-transform: none;
                    & a {
                        color: #343434;
                        text-decoration: none;
                    }
                }


                & p {
                    margin: 8px 0;

                    &:first-of-type {
                        margin-top: 6px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            & .btn {
                margin-top: auto;

                text-transform: uppercase;

                color: #fff;

                max-width: 220px;
                margin-left: auto;
                margin-right: auto;
            }

            &:last-of-type,
            &:nth-of-type(4n) {
                @include media-breakpoint-up(lg) {
                    border-right: 1px solid #dee2e6;
                    border-radius: 0 6px 6px 0;
                }
            }

            &:first-of-type,
            &:nth-of-type(5),
            &:nth-of-type(9),
            &:nth-of-type(13),
            &:nth-of-type(17) {
                @include media-breakpoint-up(lg) {
                    border-radius: 6px 0 0 6px;
                }
            }
        }
    }
}
