.principles {
  margin-top: 55px;
  margin-bottom: 55px;

  @include media-breakpoint-up(md) {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  &__title {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }

  &__list,
  & .row:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;

    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  & ul {
    list-style: none outside;
    & li {
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 22px;
      }

      &:before {
        content: "—";
        font-weight: 700;

        display: inline-block;

        margin-top: -4px;
        margin-right: 10px;

        vertical-align: middle;

        color: $green;
      }
    }
  }
  &__item {
    $item: &;
    width: 100%;
    flex: 0 0 auto;

    overflow: hidden;

    margin: 0 auto 22px;
    padding: 0;
    max-width: 400px;

    border: 1px solid #dee2e6;
    border-radius: 6px;

    @include media-breakpoint-up(md) {
      //margin: 0 25% 22px;
      //max-width: none;
      max-width: 500px;
      margin: 0 auto 22px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      max-width: none;
    }

    &:nth-of-type(2) {
      @include media-breakpoint-up(lg) {
      }
    }

    &__pic {
      background-color: $green;

      & img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    &__desc {
      padding: 30px 22px;

      color: #68717a;

      font-size: 12px;
      line-height: 16px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 20px;
      }

      & h4 {
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
          margin-bottom: 22px;
          font-size: 18px !important;
          line-height: 24px !important;
        }
        font-family: "Proxima Nova";

        text-transform: none;

        color: #343434;
      }
    }

    &:first-of-type {
      @include media-breakpoint-up(lg) {
      }

      & #{$item}__pic {
        background-color: $blue;
      }
    }

    &:last-of-type {
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
      }
      & #{$item}__pic {
        background-color: $blue;
      }
    }
  }
}
