.experts-breadcrumbs {
  letter-spacing: -0.02em;
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
  margin-bottom: 28px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    margin-bottom: 36px;
  }

  & ul {
    list-style: none outside;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;

    & li {
      position: relative;
      padding: 0 15px 0 0;

      &:after {
        content: '›';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }

  & a {
    color: initial;
    text-decoration: underline;
  }
}
