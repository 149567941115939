.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 490px) {
    flex-wrap: wrap;
    row-gap: 10px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 32px;
    height: 32px;
    margin-right: 4px;
    margin-left: 4px;
    padding-right: 5px;
    padding-left: 5px;

    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    &:hover {
      border-color: #dee2e6;
      background-color: #dee2e6;
    }

    &.current {
      border-color: $green;
      background-color: $green;
    }

    &--prev {
      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 16px;
        background: url("../img/arrow-left.svg") no-repeat 50% 50%;
      }
    }

    &--next {
      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 16px;
        background: url("../img/arrow-right.svg") no-repeat 50% 50%;
      }
    }
  }
}
