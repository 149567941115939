/*
 * Theme name: СтудСервис
 * Author: СтудСервис
 * Author URI: https://studservis.ru
 * Version: 1.0
 */

#addReview .form__field.error {
  /* margin-bottom: 0; */
}

.selectric-form__field.error .selectric {
  border-color: #dc3545;
}

.selectric-form__field.error .selectric span {
  color: #dc3545;
}

.review-rating.error {
  color: #dc3644;
}

.review-rating.error .reviews__item__rating {
  /* margin-bottom: 0; */
}

h1.no-style {
  font-weight: initial;
  text-transform: inherit;
  letter-spacing: inherit;
}

span.error {
  display: none !important;
}

#addReview span.error {
  color: #dc3545;
  font-size: 10px;
  /* display: block !important; */
  display: none;
}

.formLoader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.hiddenCalcForm {
  display: none;
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #5c5c5c, 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2),
      -2.6em 0em 0 0em rgba(92, 92, 92, 0.5), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.7), 1.8em -1.8em 0 0em #5c5c5c,
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2),
      -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.5), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7),
      2.5em 0em 0 0em #5c5c5c, 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.7), 1.75em 1.75em 0 0em #5c5c5c, 0em 2.5em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.5), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.7), 0em 2.5em 0 0em #5c5c5c,
      -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.5),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.7), -1.8em 1.8em 0 0em #5c5c5c, -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.5), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.7), -2.6em 0em 0 0em #5c5c5c,
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.5),
      -2.6em 0em 0 0em rgba(92, 92, 92, 0.7), -1.8em -1.8em 0 0em #5c5c5c;
  }
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #5c5c5c, 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2),
      -2.6em 0em 0 0em rgba(92, 92, 92, 0.5), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.7), 1.8em -1.8em 0 0em #5c5c5c,
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2),
      -2.6em 0em 0 0em rgba(92, 92, 92, 0.2), -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.5), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.7),
      2.5em 0em 0 0em #5c5c5c, 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2), 0em 2.5em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.5),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.7), 1.75em 1.75em 0 0em #5c5c5c, 0em 2.5em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.5), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.7), 0em 2.5em 0 0em #5c5c5c,
      -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.2), -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.5),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.7), -1.8em 1.8em 0 0em #5c5c5c, -2.6em 0em 0 0em rgba(92, 92, 92, 0.2),
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.5), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.7), -2.6em 0em 0 0em #5c5c5c,
      -1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(92, 92, 92, 0.2), 1.8em -1.8em 0 0em rgba(92, 92, 92, 0.2),
      2.5em 0em 0 0em rgba(92, 92, 92, 0.2), 1.75em 1.75em 0 0em rgba(92, 92, 92, 0.2),
      0em 2.5em 0 0em rgba(92, 92, 92, 0.2), -1.8em 1.8em 0 0em rgba(92, 92, 92, 0.5),
      -2.6em 0em 0 0em rgba(92, 92, 92, 0.7), -1.8em -1.8em 0 0em #5c5c5c;
  }
}
