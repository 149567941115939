.helpers {
  &__title {
    margin-bottom: 30px;
  }

  &__container {
    padding-top: 55px;
    padding-bottom: 55px;
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 260px;
      background-image: url('../img/helpers/bg-md.png');
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-image: image-set(
        url('../img/helpers/bg-md.webp') 1x,
        url('../img/helpers/bg-md@2x.webp') 2x,
        url('../img/helpers/bg-md.png') 1x,
        url('../img/helpers/bg-md@2x.png') 2x
      );
      background-size: 213px 257px;
      margin-bottom: -55px;

      @include media-breakpoint-up(md) {
        position: absolute;
        margin-bottom: 0;
        bottom: 0;
        right: 0;
        height: 561px;
        background-size: 464px 561px;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: 100px;
      padding-bottom: 306px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 175px;
      padding-bottom: 50px;
      background-image: url('../img/helpers/bg-md.png');
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-image: image-set(
        url('../img/helpers/bg-md.webp') 1x,
        url('../img/helpers/bg-md@2x.webp') 2x,
        url('../img/helpers/bg-md.png') 1x,
        url('../img/helpers/bg-md@2x.png') 2x
      );
    }

    &__tooltip {
      position: absolute;
      bottom: 72px;
      left: 10px;

      padding: 16px 8px;
      max-width: 210px;

      border-radius: 6px;
      background-color: #fff;
      font-size: 12px;
      line-height: 15px;
      z-index: 10;

      @include media-breakpoint-up(md) {
        padding: 18px 19px;
        max-width: 298px;
        font-size: 16px;
        line-height: 19px;
        left: 50px;
        bottom: 78px;
      }
      @include media-breakpoint-up(lg) {
        left: 50%;
        bottom: 78px;
        transform: translateX(-150px);
      }

      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

      &:after {
        content: '';

        position: absolute;
        right: -8px;
        top: 10px;

        display: block;

        width: 0;

        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 8px solid #fff;
      }
    }
  }

  &__desc {
    & ul {
      list-style: none outside;

      & li {
        margin-bottom: 17px;
        font-size: 14px;
        line-height: 17px;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 22px;

          margin-bottom: 22px;
        }

        &:before {
          content: '—';
          font-weight: 700;

          margin-right: 10px;

          color: $green;
        }
      }
    }
  }
}
