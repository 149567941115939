.experts-mainpanel {
  position: relative;
  //min-height: 537px;
  background: #f2f2f2;
  //margin-bottom: 61px;
  padding: 40px 15px;
  text-align: center;
  padding-bottom: 0;
  padding-top: 0;
  flex-direction: column;
  min-height: 454px;

  @include media-breakpoint-up(sm) {
    min-height: 454px;
    //margin-bottom: 99px;

    text-align: left;
    //padding-top: 107px;
  }

  @include media-breakpoint-up(md) {
    //padding-top: 118px;
  }

  & .container {
    display: flex;
    flex-grow: 1;
    padding-top: 42px;
    padding-bottom: 276px;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    background-color: #f2f2f2;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: auto 290px;
    background-image: url('../img/men-768.png');
    background-image: image-set(
      url('../img/men-768.webp') 1x,
      url('../img/men-768@2x.webp') 2x,
      url('../img/men-768.png') 1x,
      url('../img/men-768@2x.png') 2x
    );
    min-height: inherit;

    @media (min-width: 768px) {
      background-position: 140% 100%;
      background-size: auto 434px;
      padding-top: 0;
      padding-bottom: 0;
      max-width: 100%;
    }

    @media (min-width: 992px) {
      background-position: 100% 100%;
      /* background-size: 480px 434px; */
      padding-top: 0;
      padding-bottom: 0;
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    & .row {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 100%;
      margin: 0 auto;
      min-height: inherit;

      @media (max-width: 991px) {
        max-width: 758px;
      }
    }
  }
  /*     &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        background: url('../img/men-768.png') no-repeat 0% 100%;
        transform: translateX(-50%);
        background-size: 326px 300px;
        width: 326px;
        height: 300px;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            transform: translateX(0%);
            width: 476px;
            height: 437px;
            background-size: 476px 437px;
        }

        @include media-breakpoint-up(xl) {
            transform: translateX(20%);
        }
    } */

  &__title {
    font-family: 'GothamPro', Verdana, Arial, sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #0a71cc;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      font-size: 72px;
      line-height: 1;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 9px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    margin-bottom: 43px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 22px;
      max-width: 340px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 626px;
      margin-bottom: 41px;
    }
  }

  &__form {
    display: flex;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #b3da26;
    margin-bottom: 4px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      max-width: 260px;
    }

    @include media-breakpoint-up(md) {
      max-width: 340px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 626px;
    }

    & form {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    & input {
      font-size: 16px;
      line-height: 21px;
      padding: 6.5px 18px;
      background-color: transparent;
      border: none;
      display: block;
      flex: 1 1 auto;

      &::placeholder {
        color: #abb5be;
        font-weight: 400;
      }
    }

    & button {
      background: #b3da26 url('../img/search-icon.svg') no-repeat 50% 50%;
      width: 38px;
      height: 38px;
      border: none;
      margin: 0;
      padding: 0;
      display: block;
      flex: 0 1 38px;
      cursor: pointer;
    }
  }

  &__help {
    font-size: 12px;
    line-height: 16px;
    color: #abb5be;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    & span {
      font-size: 14px;
      color: #000;
    }
  }
}
