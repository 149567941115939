.title {
  &_color-white {
    color: #fff;
  }

  &_center {
    text-align: center;
  }

  &_h2 {
    margin: 0;
    font-family: 'Gotham Pro', Verdana, Arial, sans-serif;
    margin-top: 10px;
    margin-bottom: 30px;

    @media screen and (max-width: 970px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }
}
