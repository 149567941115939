.detail-blog {
  padding: 30px 0 0;

  &__bonus {
    & .bonus-blog__grid {
      align-items: flex-start;
      //background-image: url(../img/bonus-blog_sidebar_back.png);
      background-image: url("/wp-content/themes/studservice/new/img/bonus-blog_back.png");
      border-radius: 16px;
      flex-direction: column;
      justify-content: flex-start;

      padding: 45px 70px;
    }

    & .bonus-blog__title {
      font-family: "Gotham Pro", Verdana, Arial, sans-serif;
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin: 0;
    }

    & .bonus-blog__description {
      margin: 29px 0 0;
    }

    & .bonus-blog__btn {
      margin: 35px 0 0;
    }
  }

  &__title {
    font-family: "Gotham Pro", Verdana, Arial, sans-serif;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin: 40px 0 0;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 970px) {
      font-size: 1.875rem;
      line-height: normal;
    }

    @media screen and (max-width: 767px) {
      margin: 16px 0 0;
    }
  }

  &__col {
    flex: 0 0 auto;
  }

  &__grid {
    justify-content: space-between;
  }

  &__content {
    flex: 2;
    max-width: 690px;

    @media screen and (max-width: 1200px) {
      max-width: 100%;
    }
  }

  &__row {
    align-items: center;
    justify-content: center;
    margin: 47px 0 0;
    position: relative;

    @media screen and (max-width: 767px) {
      justify-content: center;
      margin: 31px 0 0;
    }

    @media (max-width: 425px) {
      justify-content: space-between;
    }
  }

  &__text {
    color: #abb5be;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-bottom: 12px;
    text-align: center;

    @media screen and (max-width: 767px) {
      text-align: left;
    }

    @media (max-width: 425px) {
      margin: 0;
      line-height: 1rem;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      color: #0a71cc;
    }
  }

  &__tags {
    justify-content: center;
    flex-wrap: wrap;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin-left: auto;
    //margin-right: 75px;
    //margin-right: 114px !important;

    @media screen and (max-width: 1200px) {
      //margin-left: 43%;
      //margin-right: auto;
    }

    @media screen and (max-width: 767px) {
      margin: 0;
    }

    @media (max-width: 425px) {
      align-items: flex-start;
      row-gap: 12px;
    }
  }

  &__aside {
    flex-wrap: wrap;
    max-width: 370px;
  }
}
