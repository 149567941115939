.articles-smart {
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &__col {
    overflow: hidden;

    @include media-breakpoint-up(md) {
      flex: 0 0 100%;
      max-width: none;
    }

    &:first-of-type {
      @include media-breakpoint-up(md) {
      }
    }

    &:last-of-type {
      @include media-breakpoint-up(md) {
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      column-gap: 0;
      flex-direction: row;
    }

    @media (min-width: 992px) {
      flex-direction: column;
      height: 100%;
    }

    &__preview {
      background-color: #ffe5b4;
      border: none;
      border-radius: 8px 8px 0 0;
      min-height: 202px;
      min-width: 280px;
      background-position: 50% 25%;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      @media (min-width: 768px) {
        border-radius: 8px;
      }

      @media (min-width: 992px) {
        max-height: 303px;
        border-radius: 8px 8px 0 0;
      }
      /*
      & img {
        position: relative;
        z-index: 1;

        display: block;

        width: 100%;
      } */

      &__tags {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;

        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 10px;

        padding: 10px;

        @include media-breakpoint-up(md) {
          padding: 10px;
        }
        @include media-breakpoint-up(lg) {
          padding: 10px;
        }
      }

      /*       &__tag {
        display: flex;
        align-items: center;

        //min-width: 119px;
        height: 25px;
        padding: 8px 11px;

        font-size: 10px;
        line-height: 12px;

        border-radius: 6px;
        background: #fff;

        & span {
          display: block;

          width: 4px;
          height: 4px;
          margin-right: 5px;

          border-radius: 50%;

          &.green {
            background-color: #28a745;
          }

          &.orange {
            background-color: #ffa500;
          }
        }
      } */

      &__tag {
        font-family: "Gotham Pro", Verdana, Arial, sans-serif;
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        color: #000;
        display: flex;
        font-size: 0.625rem;
        font-weight: 400;
        line-height: 1;
        //margin-bottom: 10px;
        //margin-right: 10px;
        padding: 8px 11px 8px 18px;
        position: relative;

        & a {
          color: #000; //!important;
          white-space: nowrap;
          text-decoration: none;
        }

        & b,
        & span {
          transform: translateY(-50%);
          border-radius: 50%;
          display: inline-block;
          flex-shrink: 0;
          height: 100%;
          left: 10px;
          margin-right: 2px;
          margin-top: 0;
          max-height: 5px;
          max-width: 5px;
          position: absolute;
          top: 50%;
          //transform: translateY(-65%);
          vertical-align: middle;
          width: 100%;
          //transform: translateY(-17%);
        }
      }
    }

    &__dop {
      //margin-top: 25px;

      font-size: 12px;
      line-height: 17px;

      color: #abb5be;

      @include media-breakpoint-up(md) {
        //margin-top: 35px;

        font-size: 12px;
        line-height: 17px;
      }

      span {
        color: #0a71cc;
      }

      &.text-lg-right {
        @media (min-width: 768px) {
          display: flex;
          column-gap: 5px;
          white-space: nowrap;
        }
      }
    }

    &__summary {
      display: flex;
      flex-direction: column;
      border-radius: 0 0 8px 8px;
      border: 1px solid #dee2e6;
      border-top: none;
      row-gap: 10px;
      padding: 20px 15px 20px;

      @include media-breakpoint-up(md) {
        border: none;
        padding: 0 0 0 20px;
      }

      @media (min-width: 992px) {
        border: 1px solid #dee2e6;
        border-top: none;
        padding: 20px 10px 20px 20px;
        height: 100%;
      }
    }

    &__title {
      color: #343434;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.375rem;
      text-transform: none;

      @media (max-width: 425px) {
        word-break: break-all;
      }
      /*       font-size: 18px;
      line-height: 22px;

      color: #343434;
      & a {
        color: #343434;
        text-decoration: none;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;

        font-size: 18px;
        line-height: 22px;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;

        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      } */
    }

    &__description {
      color: #68717a;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      margin: 11px 0 34px;

      @media (max-width: 425px) {
        word-break: break-all;
      }

      & + p:not([class]) {
        color: #68717a;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        margin: 11px 0 0;
      }
    }

    &__content {
      font-size: 12px;
      line-height: 16px;

      color: #68717a;

      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      & > span {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        @media (min-width: 768px) {
          justify-content: space-between;
          height: 100%;
        }
      }

      & .row-content {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        @media (min-width: 768px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        & .col-12,
        & .col-6 {
          padding-left: 0;
          padding-right: 0;

          @media (min-width: 768px) {
            width: auto;
            flex: 0 0 auto;
          }
        }
      }

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 20px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__name {
      color: #343434;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.375rem;
      text-transform: none;

      @media (max-width: 425px) {
        word-break: break-all;
      }
    }

    &__body {
      text-decoration: none;
      height: 100%;

      & p:not([class]) {
        margin: 0 0 10px;
        max-height: 180px;
        overflow: hidden;
      }
    }
  }
}

.articles-smart-slider {
  position: relative;

  overflow: hidden;

  width: 100%;

  @include media-breakpoint-up(md) {
    overflow-x: auto;
  }

  & .row {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      row-gap: 30px;
    }

    @media (min-width: 992px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
    }
  }

  &__container {
    width: 100%;

    @include media-breakpoint-up(md) {
      //width: 1150px;
    }
    @include media-breakpoint-up(lg) {
      //width: 100%;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    z-index: 10;

    display: none;
    align-items: center;
    justify-content: center;
    transform: translateY(-225px);

    width: 90px;
    height: 520px;

    cursor: pointer;

    border: none;
    border-radius: 0;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &__left {
      left: 10px;

      display: none;

      background: rgb(255, 255, 255);
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      justify-content: flex-start;

      &:after {
        content: "";

        display: block;
        margin-left: 6px;

        width: 16px;
        height: 19px;

        background: url("../img/icons/arrow-big.svg") no-repeat 50% 50%;
        transform: rotate(-180deg);
      }

      &.current {
        @include media-breakpoint-up(md) {
          display: flex;
        }
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &__right {
      right: 10px;

      background: rgb(255, 255, 255);
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      justify-content: flex-end;

      &:after {
        content: "";

        display: block;
        margin-right: 6px;

        width: 16px;
        height: 19px;

        background: url("../img/icons/arrow-big.svg") no-repeat 50% 50%;
      }
    }
  }
}
