.filter-comments {
  font-size: 1rem;
  &__button {
    align-items: center;
    display: flex;
    line-height: 1;
    margin-left: 5px;
    text-decoration: underline;
    &:after {
      background-image: url(../img/icon-filter.svg);
      margin-left: 9px;
    }
  }

  &__button_old {
    &:after {
      transform: rotate(-180deg);
    }
  }
}
