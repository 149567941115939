.fresh-blog {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 10px 27px 15px 19px;

  &__title {
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    line-height: 2.125rem;
    margin: 0;
    text-align: center;
    text-transform: none;
  }

  &__item {
    margin-bottom: 15px;
    min-height: 95px;

    &:last-child {
      & .fresh-blog__content {
        border-bottom: none;
      }
    }
  }

  &__footer {
    color: #949494;
    font-size: 0.5625rem;
    letter-spacing: 0.055em;
    line-height: 0.6875rem;
    margin-top: auto;

    & p {
      font-style: normal;
      font-weight: normal;
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__name {
    color: #000;
    font-family: ProximaNova;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    text-decoration: none;
  }

  &__avatar {
    border-radius: 50%;
    flex: 1 0 auto;
    max-height: 84px;
    max-width: 84px;
    object-fit: cover;
  }

  &__time {
    margin-bottom: 0;
  }

  &__date {
    margin-bottom: 0;
  }

  &__content {
    border-bottom: 1px solid #dee2e6;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 28px;
    row-gap: 10px;

    padding-bottom: 9px;
  }

  &__list {
    margin: 20px 0 0;
  }
}
