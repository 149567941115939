.note {
  border: 1px solid #b3da26;
  border-radius: 6px;
  padding: 60px 25px 36px;
  position: relative;

  &__name {
    background-color: #b3da26;
    border-radius: 6px;
    font-size: 1.125rem;
    font-weight: 600;
    left: 0;
    line-height: 1;
    padding: 12px 28px 12px 54px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
  }

  &:before {
    background-image: url(../img/bulb-icon.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    height: 30px;
    left: 18px;
    position: absolute;
    top: -4px;
    width: 28px;
  }
}
