.city-list {
  font-size: 14px;
  line-height: 17px;

  white-space: nowrap;
  list-style-type: none;

  & li {
    margin-bottom: 8px;
    line-height: 26px;
    & strong {
      & a {
        font-weight: 900;
        font-size: 18px;
      }
    }
    & a {
      text-decoration: none;
      color: #000;
      font-size: 16px;
    }
  }

  &__box {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 50vh;
  }

  &__elite {
    margin-bottom: 22px;
  }
}
