.error-page {

    margin-top: 50px;
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
        min-height: calc(100vh - 144px);
    }

    &__pic {
        display: block;
        margin: 0 auto 8px;
        max-width: 100%;
    }

    &__title {
        margin-bottom: 8px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        line-height: 120%;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 24px;
            max-width: 726px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 750px;
        }

        & .btn {
            width: 100%;
            display: block;

            @include media-breakpoint-up(md) {
                width: 202px;
            }
        }
    }
}
