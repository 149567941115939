.mobile-nav {
  &__location {
    text-decoration: none;
    color: $color-default-black;
  }

  &__close {
    background-color: $color-transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
