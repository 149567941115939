.partners-agency {
  &__header-button {
    text-transform: uppercase;

    &--empty {
      background-color: $color-transparent;
      color: #0a71cc;
    }
  }
}
