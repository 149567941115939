.breadcrumbs {
  color: #000;
  display: inline;
  font-size: 1.125rem;
  line-height: 1.3125rem;

  & > span {
    margin: 0 5px 0 0;
  }

  &__item {
    color: inherit;
    display: inline;
    hyphens: auto;
    letter-spacing: -0.02em;
    overflow-wrap: break-word;
    word-break: break-all;
    margin: 0 5px 0 0;

    @media screen and (max-width: 767px) {
      font-size: 1rem;
      line-height: normal;
    }

    &:after {
      content: '›';
      margin: 0 3px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__link {
    color: inherit;
    display: inline;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
