.header {
  &-topbar {
    padding-top: 13px;
    padding-bottom: 13px;

    color: #000;

    & a {
      color: inherit;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__logo {
      max-height: 20px;

      @include media-breakpoint-up(md) {
        padding-right: 40px;
      }

      @include media-breakpoint-up(lg) {
        max-height: 31px;
        padding-right: 0px;
      }

      & img {
        max-height: 20px;

        @include media-breakpoint-up(lg) {
          max-height: 100%;
        }
      }
    }

    &__location {
      position: relative;
      max-width: 165px;

      font-size: 14px;
      line-height: 22px;
    }

    &__location-chooser {
      position: absolute;
      bottom: -5px;
      transform: translate(-50%, 100%);
      left: 80%;
      width: 100%;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      background-color: #eee;
      padding: 10px;
      border-radius: 10px;
      z-index: 100;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s ease, opacity 0.3s ease;
      pointer-events: none;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px 0 2px rgba(0, 0, 0, 0.05);

      &--visible {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }

    &__location-name {
      padding: 0;
      margin: 0;
      font-size: 12px;
      line-height: 14px;

      & span {
        white-space: nowrap;
        text-decoration: underline;
      }
    }

    &__location-buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
    }

    &__location-agree,
    &__location-change {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 10px;
      border-radius: 5px;
      background-color: $color-default-white;
    }

    &__telephone {
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &__burger {
      display: block;

      width: 24px;
      height: 16px;

      border: none;
      border-radius: 0;
      background: url("../img/burger.svg") no-repeat 50% 50%;
      background-size: contain;

      .mobilemenu-open & {
        background: url("../img/burger-close.svg") no-repeat 50% 50%;
        width: 24px;
        height: 16px;
      }
    }

    &__loginbox {
      display: flex;
      flex-wrap: nowrap;

      margin-right: -8px;
      margin-left: -8px;

      & .btn {
        min-width: 150px;
        margin: 0 8px;
        padding: 8.5px 8px;

        font-size: 12px;
        line-height: 15px;

        text-transform: uppercase;

        border-radius: 34px;
      }
    }
  }

  &-navbar {
    position: relative;
    z-index: 10;

    padding-top: 20px;
    padding-bottom: 20px;

    color: #fff;
    background-color: $green;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    & a {
      text-decoration: none;

      color: inherit;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 15px;

      margin-right: -15px;
      margin-left: -15px;

      list-style: none outside;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 22px;
      }

      & li {
        margin: 0 15px;
        white-space: nowrap;
      }

      & a {
        color: #000;
      }
    }

    &__privat-office {
      display: flex;
      align-items: center;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 22px;
      }

      &:before {
        content: "";

        display: block;

        width: 16px;
        height: 20px;
        margin-right: 6px;

        background: url("../img/icons/avatar.svg") no-repeat 50% 50%;
      }
    }

    &-popup {
      position: fixed;
      top: 46px;
      right: 0;
      width: 100%;
      height: 100%;
      min-height: 100vh;
      display: block;
      pointer-events: none;
      z-index: 500;

      .mobilemenu-open & {
        pointer-events: all;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      &__overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;

        .mobilemenu-open & {
          opacity: 1;
          visibility: visible;
        }
      }

      &__content {
        position: relative;
        margin-left: auto;
        width: 100%;
        max-width: 300px;
        height: 100%;
        background-color: #fff;
        z-index: 10;
        transform: translate(100%, 0);
        transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
        opacity: 0;
        visibility: hidden;
        overflow: auto;
        padding: 0 0 30px;

        @media (max-width: 767px) {
          max-width: 400px;
        }

        @media (max-width: 475px) {
          max-width: none;
        }

        .mobilemenu-open & {
          transform: translate(0, 0);
          opacity: 1;
          visibility: visible;

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }

      &__list {
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        list-style: none outside;
        padding: 11px 15px 22px 37px;

        @media (max-width: 768px) {
          font-size: 22px;
        }

        & li {
          margin-bottom: 15px;
          text-align: left;
        }

        & a {
          color: #000;
          text-decoration: none;
        }
      }

      &__privat-office {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #dee2e6;

        & a {
          display: block;
          width: max-content;
          font-size: 18px;
          line-height: 24px;
          font-weight: 700;
          padding: 10px 15px 10px 37px;
          color: #000;
          text-decoration: none;
          position: relative;
          text-align: right;

          &::before {
            content: "";
            width: 17px;
            height: 20px;
            background: url("../img/icons/privat-office-popup-icon.svg") no-repeat 50% 50%;
            position: absolute;
            top: 13px;
            left: 14px;
          }

          @media (max-width: 767px) {
            font-size: 22px;
            line-height: 26px;
          }
        }
      }

      &__loginbox {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;

        /* margin-right: -8px;
        margin-left: -8px; */

        & .btn {
          width: 200px;
          margin: 0 auto 11px 37px;
          padding: 8.5px 8px;

          font-size: 16px;
          line-height: 20px;

          text-transform: uppercase;

          border-radius: 34px;
        }
      }

      &__contacts {
        padding: 11px 15px 22px 37px;

        & a {
          color: #000;
        }

        p {
          text-align: left;
        }
      }

      &__telephone {
        font-family: "Proxima Nova";
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        display: block;
        margin-bottom: 11px;
        text-align: left;
      }
    }
  }
}
