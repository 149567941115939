.contacts {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
        margin-bottom: 100px;
    }

    &__content {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 50px;
        &__title {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 8px;
        }

        &__phone {
            font-size: 18px;
            line-height: 100%;
            font-weight: 700;
            margin-bottom: 8px;
            padding-right: 20px;

            @include media-breakpoint-up(md) {
                font-size: 24px;
                line-height: 100%;
                font-weight: 700;
                margin-bottom: 0px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 24px;
                line-height: 100%;
                font-weight: 700;
                margin-bottom: 22px;
            }
        }

        &__callback {
            width: 100%;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
                width: 202px;
            }
        }
    }

    &__map {
        height: 370px;
        width: 100%;
        background: url('../img/contacts/map.png') no-repeat 50% 50%;
    }

    &__tabs {
        overflow: hidden;
        &__menu {
            display: flex;
            flex-wrap: nowrap;

            margin-bottom: 50px;

            list-style: none outside;

            font-weight: 700;
            font-size: 18px;
            line-height: 24px;

            color: #68717a;
            border-bottom: 2px solid #ededed;

            & a {
                display: block;

                padding-bottom: 8px;

                text-decoration: none;

                color: inherit;
                border-bottom: 5px solid transparent;
            }

            & li {
                margin-right: 30px;
                margin-bottom: -2px;

                white-space: nowrap;
                &.current {
                    color: #000;

                    & a {
                        border-bottom: 5px solid $green;
                    }
                }
            }
        }

        &__item {
            display: none;

            &.current {
                display: block;
            }
        }
    }
}
