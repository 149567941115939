.form {
  &__files {
    &__item {
      margin-bottom: 13px;
      padding-left: 26px;
      padding-right: 26px;
      color: #abb5be;
    }

    &-error {
      color: #ff3333;
    }
  }

  & textarea {
    font-family: inherit;
  }

  &__field-wrapper {
    position: relative;
  }

  &__field-list-courses {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: absolute;
    top: calc(100% - 25px);
    left: 0;
    right: 0;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    border-radius: 6px;
    background-color: #fff;
    border: solid 1px #abb5be;
    box-shadow: 0 0 30px rgba(74, 74, 74, 0.15);
    list-style-type: none;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s ease, opacity 0.3s ease;
    min-height: 30px;
    z-index: 10;

    &--opened {
      visibility: visible;
      opacity: 1;
    }
  }

  &__field-item-courses {
    font-size: 14px;
    line-height: 1;
    color: #5f6061;
    font-weight: 600;
    padding: 8px 26px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
    word-wrap: break-word;

    &--disabled {
      cursor: none;
      pointer-events: none;
    }

    &:hover {
      color: #000;
      background-color: #f2f2f2;
    }
  }

  &__field {
    width: 100%;
    margin-bottom: 13px;
    padding: 16px 26px;
    box-sizing: border-box;
    text-overflow: ellipsis;

    font-size: 16px;
    line-height: 1;

    color: #000;
    border: 1px solid #abb5be;
    border-radius: 6px;
    background: #fff;

    &::placeholder {
      font-weight: 700;
      font-family: "Proxima Nova";

      text-transform: uppercase;

      color: #abb5be;
    }
    &.error {
      border-color: $red;

      &::placeholder {
        color: $red !important;
      }
    }

    &__label {
      font-size: 12px;
      line-height: 16px;

      letter-spacing: 0.04em;

      & a {
        text-decoration: underline;

        color: inherit;

        &:hover {
          color: $green;
        }
      }
    }

    &-group-files {
      position: relative;
      display: block;

      width: 100%;
      min-height: 52px;
      margin-bottom: 13px;
      padding: 16px 26px;
      box-sizing: border-box;

      font-size: 16px;
      line-height: 1;

      color: #000;
      border: 1px solid #abb5be;
      border-radius: 6px;
      background: #fff;
      cursor: pointer;

      &::after {
        content: attr(data-placeholder);

        font-weight: 700;
        font-family: "Proxima Nova";

        text-transform: uppercase;

        color: #abb5be;
      }

      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 18px;
        background: url("../img/download.svg") no-repeat 50% 50%;
        position: absolute;
        top: 15px;
        right: 20px;
      }

      & input {
        display: none;
      }
    }

    &--search {
      padding: 9.5px 26px;
      font-size: 14px;
      font-family: "Proxima Nova";
      font-weight: 400;
      text-transform: none;
      background-image: url("../img/icons/search.svg");
      background-position: 98% 50%;
      background-repeat: no-repeat;

      &::placeholder {
        font-weight: 400;
        font-family: "Proxima Nova";

        text-transform: none;

        color: #abb5be;
      }
    }
  }

  &__textfield {
    width: 100%;
    min-height: 126px;
    margin-bottom: 22px;
    padding: 16px 26px;
    box-sizing: border-box;

    font-size: 16px;
    line-height: 1;

    color: #000;
    border: 1px solid #abb5be;
    border-radius: 6px;
    background: #fff;

    &::placeholder {
      font-weight: 700;
      font-family: "Proxima Nova";

      text-transform: uppercase;

      color: #abb5be;
    }
  }
}
