.banner-blog {
  background-color: #f2f2f2;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 767px) {
    overflow: hidden;
  }

  &__grid {
    justify-content: space-between;
    max-width: 50%;

    @media (max-width: 991px) {
      max-width: 758px;
      margin: 0 auto;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__title {
    color: #0a71cc;
    font-size: 4.5rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1;

    @media screen and (max-width: 767px) {
      font-size: 2.25rem;
      line-height: normal;
      margin: 0;
    }
  }

  &__descritpion {
    font-size: 1.125rem;
    letter-spacing: -0.02em;
    line-height: 1.4125rem;
    margin: 10px 0 0;

    @media screen and (max-width: 970px) {
      margin: 24px 0 0;
    }
  }

  &__search {
    margin: 23px 0 0;

    @media screen and (max-width: 970px) {
      margin: 23px 0 0;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 630px;
    padding-top: 112px;

    @media (max-width: 991px) {
      max-width: 430px;
    }

    @media screen and (max-width: 767px) {
      padding-top: 0;
      text-align: center;
    }
  }

  &__picture {
    align-items: flex-end;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin: 0 auto 0 70px;
    max-width: 100%;
    position: relative;
    text-align: center;
    width: 430px;
    z-index: 0;

    @media screen and (max-width: 970px) {
      margin-right: -100px;
      width: 370px;
    }

    @media screen and (max-width: 767px) {
      margin: 10px 0 0;
      width: 300px;
    }

    &:after {
      background-color: #b3da26;
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 85%;
      transform: translate(-50%, -50%);
      width: inherit;

      @media screen and (max-width: 970px) {
        height: 370px;
        width: 370px;
      }

      @media screen and (max-width: 767px) {
        height: 300px;
        width: 300px;
      }
    }
  }

  &__image {
    margin-left: 10px;
    max-width: 295px;
    position: relative;
    z-index: 100;

    @media screen and (max-width: 970px) {
      height: auto;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      max-width: 220px;
    }
  }

  &__container {
    min-height: 454px;
    display: flex;
    flex-grow: 1;
    padding-top: 42px;
    padding-bottom: 276px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: auto 290px;
    background-image: url('../img/blog/bg@1x.png');
    background-image: image-set(
      url('../img/blog/bg@1x.webp') 1x,
      url('../img/blog/bg@2x.webp') 2x,
      url('../img/blog/bg@1x.png') 1x,
      url('../img/blog/bg@2x.png') 2x
    );

    @media (min-width: 768px) {
      background-position: 140% 100%;
      background-size: auto 454px;
      padding-top: 0;
      padding-bottom: 0;
      max-width: 100%;
    }

    @media (min-width: 992px) {
      background-position: 100% 100%;
      background-size: auto 454px;
      padding-top: 0;
      padding-bottom: 0;
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }
}
