.jumbotron {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;

  background-color: #f2f2f2;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  & > .container {
    & > .row {
      & > .col-12 {
        padding: 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &--mainpage {
    overflow: hidden;
    background: #f2f2f2 url("../img/jumbotron/mainpage-xs.png") no-repeat 100% 100%;
    background-image: image-set(
      url("../img/jumbotron/mainpage-xs.webp") 1x,
      url("../img/jumbotron/mainpage-xs@2x.webp") 2x,
      url("../img/jumbotron/mainpage-xs.png") 1x,
      url("../img/jumbotron/mainpage-xs@2x.png") 2x
    );

    @include media-breakpoint-up(md) {
      background: #f2f2f2;
    }

    &:before {
      content: "";
      display: none;
      width: 320px;
      height: 330px;
      background: url("../img/jumbotron/mainpage-xs.png") no-repeat 100% 100%;
      background-image: image-set(
        url("../img/jumbotron/mainpage-xs.webp") 1x,
        url("../img/jumbotron/mainpage-xs@2x.webp") 2x,
        url("../img/jumbotron/mainpage-xs.png") 1x,
        url("../img/jumbotron/mainpage-xs@2x.png") 2x
      );
      position: absolute;
      top: 663px;
      right: 0%;
      z-index: 1;

      @include media-breakpoint-up(md) {
        display: block;
        width: 100%;
        height: 900px;
        top: 0;
        left: 0%;
        background: url("../img/jumbotron/mainpage-md.png") no-repeat 100% 0%;
        background-image: image-set(
          url("../img/jumbotron/mainpage-md.webp") 1x,
          url("../img/jumbotron/mainpage-md@2x.webp") 2x,
          url("../img/jumbotron/mainpage-md.png") 1x,
          url("../img/jumbotron/mainpage-md@2x.png") 2x
        );
        transform: translateX(0px) translateY(0px);
      }

      @include media-breakpoint-up(lg) {
        width: 50%;
        height: 880px;
        left: 50%;
        // transform: translateX(-150px) translateY(0px);
        background: url("../img/jumbotron/mainpage.png") no-repeat 0% 100%;
        background-image: image-set(
          url("../img/jumbotron/mainpage.webp") 1x,
          url("../img/jumbotron/mainpage@2x.webp") 2x,
          url("../img/jumbotron/mainpage.png") 1x,
          url("../img/jumbotron/mainpage@2x.png") 2x
        );
      }

      @include media-breakpoint-up(xl) {
        width: 100%;
        height: 880px;
        transform: translateX(-130px) translateY(0px);
        background: url("../img/jumbotron/mainpage.png") no-repeat 0% 100%;
        background-image: image-set(
          url("../img/jumbotron/mainpage.webp") 1x,
          url("../img/jumbotron/mainpage@2x.webp") 2x,
          url("../img/jumbotron/mainpage.png") 1x,
          url("../img/jumbotron/mainpage@2x.png") 2x
        );
      }
    }

    & .jumbotron__desc {
      font-size: 18px;
      line-height: 24px;

      @media (max-width: 767px) {
        margin: 0 auto;
      }
    }
  }

  &--servicies,
  &--contacts,
  &--service,
  &--about,
  &--worktape,
  &--worktape-inner,
  &--working-conditions,
  &--partners,
  &--promocodes,
  &--authors-signup,
  &--partners-students,
  &--partners-agency {
    padding-bottom: 0;
    padding-top: 0;
    flex-direction: column;
    min-height: 454px;

    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    & .container {
      display: flex;
      flex-grow: 1;
      padding-top: 0;
      padding-bottom: 276px;
      padding-left: 0;
      padding-right: 0;
      height: 100%;
      background-color: #f2f2f2;
      background-repeat: no-repeat;
      background-position: 50% 100%;
      background-size: auto 290px;

      @media (min-width: 768px) {
        background-position: 140% 100%;
        background-size: auto 454px;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 100%;
      }

      @media (min-width: 992px) {
        background-position: 100% 100%;
        /* background-size: 480px 434px; */
        padding-top: 0;
        padding-bottom: 0;
        max-width: 960px;
      }

      @media (min-width: 1200px) {
        max-width: 1140px;
      }

      & .row {
        display: flex;
        align-items: center;
        width: 100%;
        //min-height: 100%;
        margin: 0 auto;

        @media (max-width: 991px) {
          max-width: 758px;
        }
      }
    }
  }

  &--authors-signup {
    & .container {
      background-image: url("../img/working-conditions/bg.png");
      background-image: image-set(
        url("../img/working-conditions/bg.webp") 1x,
        url("../img/working-conditions/bg@2x.webp") 2x,
        url("../img/working-conditions/bg.png") 1x,
        url("../img/working-conditions/bg@2x.png") 2x
      );
    }

    & .jumbotron__desc--full {
      max-width: 547px;
    }

    & .jumbotron__breadcrumbs {
      padding: 0 10px;
    }

    & .jumbotron__innerpage {
      height: 100%;

      & .row {
        margin-left: 0;
        margin-right: 0;
        margin: auto 0;
      }
    }
  }

  &--servicies {
    & .container {
      background-image: url("../img/servicies/bg-lg.png");
      background-image: image-set(
        url("../img/servicies/bg-lg.webp") 1x,
        url("../img/servicies/bg-lg@2x.webp") 2x,
        url("../img/servicies/bg-lg.png") 1x,
        url("../img/servicies/bg-lg@2x.png") 2x
      );
    }
  }

  &--promocodes {
    & .container {
      background-image: url("../img/promocodes/bg.png");
      background-image: image-set(
        url("../img/promocodes/bg.webp") 1x,
        url("../img/promocodes/bg@2x.webp") 2x,
        url("../img/promocodes/bg.png") 1x,
        url("../img/promocodes/bg@2x.png") 2x
      );

      @media (min-width: 768px) {
        background-position: 170px 100%;
      }

      @media (min-width: 992px) {
        background-position: 100% 100%;
      }
    }

    & .jumbotron__suptitle {
      text-align: center;

      @media (min-width: 768px) {
        text-align: left;
      }
    }

    & .jumbotron__desc {
      @media (min-width: 992px) {
        max-width: 500px;
      }

      @media (min-width: 1200px) {
        max-width: none;
      }
    }
  }

  &--partners {
    & .container {
      position: relative;
      background-image: url("../img/partners/main-bg@1x.png");
      background-image: image-set(
        url("../img/partners/main-bg@1x.webp") 1x,
        url("../img/partners/main-bg@2x.webp") 2x,
        url("../img/partners/main-bg@1x.png") 1x,
        url("../img/partners/main-bg@2x.png") 2x
      );
    }
    & .partners__main {
      &--second-text {
        font-weight: 700;
      }
    }

    & .jumbotron__title {
      & h1 {
        position: relative;
        display: inline;

        & svg {
          position: absolute;
          top: 50%;
          right: -8px;
          transform: translate(100%, -50%);
          width: 26px;
          height: auto;

          @media (min-width: 768px) {
            width: 38px;
            height: 54px;
          }
        }
      }
    }

    & .jumbotron__filter__tooltip {
      position: absolute;
      right: auto;
      left: 15px;
      bottom: 54px;
      top: auto;

      @media (min-width: 768px) {
        left: auto;
        right: 135px;
      }

      @media (min-width: 992px) {
        right: 335px;
      }
    }
  }

  &--partners-students {
    & .container {
      position: relative;
      background-image: url("../img/partners-students/main-bg@1x.png");
      background-image: image-set(
        url("../img/partners-students/main-bg@1x.webp") 1x,
        url("../img/partners-students/main-bg@2x.webp") 2x,
        url("../img/partners-students/main-bg@1x.png") 1x,
        url("../img/partners-students/main-bg@2x.png") 2x
      );
    }

    & .partners-student__header-button {
      text-transform: uppercase;

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    & .jumbotron__innerpage {
      height: 100%;

      & > .experts-breadcrumbs {
        margin-bottom: 0;
      }

      & > .row {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    & > .container > .row > .col-12.partner-student-header-container {
      justify-content: flex-start;
    }

    & .partner-student-header-container {
      & .jumbotron__desc {
        @media (max-width: 767px) {
          text-align: left;
          width: 100%;
        }
      }

      & .jumbotron__breadcrumbs {
        margin-bottom: 0;
      }

      & .jumbotron__suptitle {
        margin: 10px 0 8px;
      }
    }

    & .partners-student__header-text {
      margin: 0 0 30px;
    }

    & .partners-student__header-text-blue {
      font-weight: 700;
      color: $blue;
    }

    & .partners-student__header-text-dark {
      font-weight: 700;
      color: #000;
    }

    & .jumbotron__suptitle {
      margin: 0 0 8px;
    }
  }

  &--partners-agency {
    & .container {
      position: relative;
      background-image: url("../img/partners-agency/agency-jumbotron.png");
      background-image: image-set(
        url("../img/partners-agency/agency-jumbotron.webp") 1x,
        url("../img/partners-agency/agency-jumbotron@2x.webp") 2x,
        url("../img/partners-agency/agency-jumbotron.png") 1x,
        url("../img/partners-agency/agency-jumbotron2x.png") 2x
      );
    }

    & .jumbotron__innerpage {
      height: 100%;

      & > .experts-breadcrumbs {
        margin-bottom: 0;
      }

      & > .row {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    & > .container > .row > .col-12.partner-student-header-container {
      justify-content: flex-start;
    }

    & .partner-student-header-container {
      & .jumbotron__desc {
        @media (max-width: 767px) {
          text-align: left;
          width: 100%;
        }
      }

      & .jumbotron__breadcrumbs {
        margin-bottom: 0;
      }

      & .jumbotron__suptitle {
        margin: 10px 0 8px;
      }
    }

    & .partners-student__header-text {
      margin: 0 0 30px;
    }

    & .partners-student__header-text-blue {
      font-weight: 700;
      color: $blue;
    }

    & .partners-student__header-text-dark {
      font-weight: 700;
      color: #000;
    }

    & .jumbotron__suptitle {
      margin: 0 0 8px;
    }
  }

  &--contacts {
    & .container {
      background-image: url("../img/contacts/bg-xs.png");
      background-image: image-set(
        url("../img/contacts/bg-xs.webp") 1x,
        url("../img/contacts/bg-xs@2x.webp") 2x,
        url("../img/contacts/bg-xs.png") 1x,
        url("../img/contacts/bg-xs@2x.png") 2x
      );
    }
  }

  &--service {
    & .container {
      background-image: url("../img/servicies/service-bg-lg.png");
      background-image: image-set(
        url("../img/servicies/service-bg-lg.webp") 1x,
        url("../img/servicies/service-bg-lg@2x.webp") 2x,
        url("../img/servicies/service-bg-lg.png") 1x,
        url("../img/servicies/service-bg-lg@2x.png") 2x
      );
    }
  }

  &--about {
    & .container {
      background-image: url("../img/about/bg.png");
      background-image: image-set(
        url("../img/about/bg.webp") 1x,
        url("../img/about/bg@2x.webp") 2x,
        url("../img/about/bg.png") 1x,
        url("../img/about/bg@2x.png") 2x
      );

      @media (min-width: 768px) {
        background-size: auto 434px;
      }
    }

    & ~ .about-content {
      & .about-content__tabs__btn__right {
        display: none;

        &.current {
          display: flex;
        }
      }
    }
  }

  &--worktape {
    & .container {
      background-image: url("../img/worktape/worktape-bg-lg.png");

      background-image: image-set(
        url("../img/worktape/worktape-bg-lg.webp") 1x,
        url("../img/worktape/worktape-bg-lg@2x.webp") 2x,
        url("../img/worktape/worktape-bg-lg.png") 1x,
        url("../img/worktape/worktape-bg-lg@2x.png") 2x
      );

      @media (min-width: 992px) {
        background-size: 440px 434px;
      }
    }
  }

  &--worktape-inner {
    & .container {
      background-image: url("../img/worktape/worktape-inner-bg-lg.png");
      background-image: image-set(
        url("../img/worktape/worktape-inner-bg-lg.webp") 1x,
        url("../img/worktape/worktape-inner-bg-lg@2x.webp") 2x,
        url("../img/worktape/worktape-inner-bg-lg.png") 1x,
        url("../img/worktape/worktape-inner-bg-lg@2x.png") 2x
      );
    }
  }

  &--working-conditions {
    & .container {
      background-image: url("../img/working-conditions/bg.png");
      background-image: image-set(
        url("../img/working-conditions/bg.webp") 1x,
        url("../img/working-conditions/bg@2x.webp") 2x,
        url("../img/working-conditions/bg.png") 1x,
        url("../img/working-conditions/bg@2x.png") 2x
      );
    }

    & .jumbotron__desc--full {
      max-width: 547px;
    }
  }

  &__innerpage {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__breadcrumbs {
    margin-bottom: 30px;
    //padding-left: 10px;

    font-size: 14px;
    line-height: 120%;
    max-width: 680px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 120%;
    }

    @include media-breakpoint-up(lg) {
    }

    & a {
      color: inherit;
    }

    &--worktape {
      margin-bottom: 47px;

      @include media-breakpoint-up(md) {
        margin-bottom: 38px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 60px;
      }
    }
  }

  &__suptitle {
    margin-bottom: 12px;

    font-weight: 900;
    font-size: 16px;
    line-height: 150%;
    font-family: "GothamPro", Verdana, Arial, sans-serif;

    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 150%;
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 30px;
      line-height: 150%;
    }
  }

  &__parallax {
    position: absolute;
    top: 0;
    right: 0;

    display: none;

    width: 100%;
    max-width: 1200px;
    height: 100%;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    &__item {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      &--1 {
        z-index: 4;

        background: url("../img/parallax/1.png") no-repeat 80% 60%;
      }
      &--2 {
        z-index: 3;

        background: url("../img/parallax/2.png") no-repeat 0 0;
      }
      &--3 {
        z-index: 2;

        background: url("../img/parallax/3.png") no-repeat 85% 0;
      }
    }
  }

  &__title,
  &__title h1 {
    position: relative;
    z-index: 10;

    margin-bottom: 16px;

    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    font-family: "GothamPro", Verdana, Arial, sans-serif;

    text-align: center;
    letter-spacing: -0.02em;
    text-transform: none;
    color: $blue;

    @include media-breakpoint-up(md) {
      font-size: 72px;
      line-height: 1;

      text-align: left;
    }

    @include media-breakpoint-up(xl) {
      // margin-top: 90px;
    }
  }

  &__desc {
    position: relative;
    z-index: 10;

    max-width: 340px;
    margin-bottom: 0;

    font-size: 14px;
    line-height: 120%;

    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;

      text-align: left;
    }

    &--full {
      max-width: 100%;
    }

    &__statistics {
      margin: 15px 0;

      font-size: 12px;
      line-height: 15px;
      text-align: left;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 22px;
        margin: 30px 0 0;
      }

      & h4 {
        margin-bottom: 11px;
        font-size: 18px;
        line-height: 100%;
        font-family: "Proxima Nova" !important;

        @include media-breakpoint-up(md) {
          font-size: 24px;
          line-height: 100%;
          margin-bottom: 8px;
        }
      }

      & .delimtr {
        margin-bottom: 11px;
        @include media-breakpoint-up(md) {
          margin-bottom: 8px;
        }
      }
    }

    & ul {
      list-style: none outside;

      font-size: 14px;
      line-height: 17px;
      text-align: left;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 22px;
      }

      & li {
        position: relative;

        margin-bottom: 8px;
        padding-left: 32px;

        @include media-breakpoint-up(md) {
          margin-bottom: 22px;
        }

        &:before {
          content: "";

          position: absolute;
          top: 0;
          left: 0;

          display: block;

          width: 20px;
          height: 20px;

          background: url("../img/icons/good.svg") no-repeat 50% 50%;
        }
      }
    }
  }

  &__button-wrappers {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;

    @media (max-width: 767px) {
      justify-content: space-between;
    }

    @media (max-width: 499px) {
      flex-direction: column;
    }
  }

  &__reviews {
    position: relative;
    z-index: 10;

    display: flex;
    align-items: flex-start;

    width: 440px;
    margin-top: 20px;

    &__pic {
      padding-right: 24px;
      img {
        width: 180px;
        height: 189px;
        max-width: none;
      }
    }

    &__desc {
      //padding-top: 60px;

      font-size: 14px;
      line-height: 120%;
      padding: 50px 0 0;
    }
  }

  &__filter {
    position: relative;
    z-index: 10;

    margin-top: 100px;
    padding: 20px 10px;

    border-radius: 6px;
    background-color: #fff;

    max-width: 988px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 258px;

    & span.error {
      display: none !important;
    }

    & .selectric-items {
      border-radius: 6px;
      top: auto;
      bottom: 100%;
    }

    & .selectric-form__field {
      & .selectric {
        height: 100%;
      }
    }

    & .row {
      margin-left: -8px;
      margin-right: -8px;

      > .col,
      > [class*="col-"] {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 230px;
      padding: 46px 56px 33px;
      margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 20px;
      padding: 46px 66px 33px;
    }

    & .form__field {
      margin-bottom: 22px;
    }

    &__tooltip {
      position: absolute;
      top: -84px;
      right: 8px;

      padding: 18px 19px;

      border-radius: 6px;
      background-color: #fff;
      font-size: 16px;
      line-height: 19px;
      z-index: 50;

      @include media-breakpoint-up(md) {
        right: auto;
        left: 0;
      }
      @include media-breakpoint-up(lg) {
        right: 66px;
        left: auto;
      }

      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

      &:before {
        content: "";

        position: absolute;
        top: -16px;
        right: -8px;

        display: block;

        width: 46px;
        height: 46px;

        border-radius: 6px;
        background: rgba(10, 113, 204, 0.35) url("../img/good.svg") no-repeat 50% 50%;

        backdrop-filter: blur(10px);
      }

      &:after {
        content: "";

        position: absolute;
        right: 10px;
        bottom: -8px;

        display: block;

        width: 0;

        border-top: 8px solid #fff;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
      }
    }

    &__agreement {
      width: 100%;
      padding-top: 8px;

      font-size: 12px;

      letter-spacing: 0.04em;
      & a {
        color: black;
      }
      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 20px;
        left: 0;

        padding-top: 0;

        text-align: center;
      }

      & input.error {
        & + label {
          color: $red;

          & a {
            color: inherit;
          }
        }
      }

      & input[type="checkbox"]:checked + .filter-agreement__label,
      & input[type="checkbox"]:not(:checked) + .filter-agreement__label {
        &:after,
        &:before {
          top: 50%;
          transform: translate(0, -50%);
        }

        &:after {
          left: 9px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
