.articles-blog {
  padding: 50px 0 0;

  @media screen and (max-width: 767px) {
    padding: 50px 0 0;
  }

  &__body {
    text-decoration: none;
    height: 100%;

    & p:not([class]) {
      margin: 0 0 10px;
      max-height: 180px;
      overflow: hidden;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 6px 6px;
    border: 1px solid #dee2e6;
    border-top: none;
    padding: 20px 15px 20px;

    @media (min-width: 768px) {
      padding: 0 0 0 20px;
      border: none;
    }

    @media (min-width: 991px) {
      padding: 20px 10px 20px 20px;
      border: 1px solid #dee2e6;
      border-top: none;
      flex-grow: 1;
    }

    & p {
      overflow: hidden;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0;
    text-transform: uppercase;

    & span {
      border-radius: 50%;
      flex-shrink: 0;
      height: 12px;
      margin-right: 5px;
      width: 12px;

      @media screen and (max-width: 767px) {
        height: 6px;
        width: 6px;
      }
    }
  }

  &__item {
    background-color: #fff;
    flex-direction: column;
    //width: calc(100% - 55px);
    //max-width: calc(33% - 14px);
    border-radius: 6px;

    @media screen and (max-width: 991px) {
      width: 100%;
      flex-direction: row;
      margin-bottom: 22px;
      max-width: none;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    &_row {
      //max-height: 202px;
      max-height: initial;
      margin-right: 55px;
      flex-direction: row;
      margin-bottom: 22px;
      max-width: none;

      & .articles-blog__content {
        border: none;
        display: flex;
        flex-direction: column;
        padding: 0 0 0 26px;

        @media (max-width: 768px) {
          justify-content: space-between;
          border-radius: 0 0 6px 6px;
          border: 1px solid #dee2e6;
          border-top: none;
          padding: 20px 15px;
        }
      }

      & .articles-blog__footer {
        margin-top: auto;
        padding-top: 10px;
      }

      & .articles-blog__image {
        max-height: 100%;
      }
    }
  }

  &__list {
    margin: 15px 0 0;
    /*     display: flex;
    flex-wrap: wrap; */
    // Необходимо переписать d-flex от bootstrap, там стоит display: flex; !important

    @media (min-width: 970px) {
      column-gap: 20px;
    }

    @media screen and (max-width: 767px) {
      margin: 23px 0 0;
    }

    &_column {
      flex-direction: column;
      display: flex !important;
      row-gap: 20px;
    }

    &_row {
      flex-direction: row;
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr;

      @media screen and (max-width: 991px) {
        flex-direction: column;
        display: flex !important;
        row-gap: 20px;
      }
    }
  }

  &__header {
    background-color: #ffe5b4;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    border-radius: 6px 6px 0 0;
    //max-width: 381px;
    background-repeat: no-repeat;
    background-size: cover;
    object-position: center;
    //background-position: 50% 40%;
    //background-size: 100%;
    min-height: 290px;
    top: 0;

    @media (min-width: 768px) {
      min-height: 202px;
      min-width: 280px;
      max-width: 303px;
      border-radius: 6px;
      //background-position: 50%;
    }

    @media (min-width: 970px) {
      border-radius: 6px 6px 0 0;
      max-width: none;
    }
  }

  &__grid {
    & .articles-blog__header {
      border-radius: 6px;

      @media (max-width: 768px) {
        border-radius: 6px 6px 0 0;
      }
    }
  }

  &__image {
    height: 100%;
    max-height: 202px;
    object-fit: cover;
    width: 100%;
    //min-width: 303px;
    //max-height: 202px !important;
    //display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    bottom: 0;
    border-radius: 6px;

    @media screen and (max-width: 767px) {
      border-radius: 6px 6px 0 0;
      min-height: 290px;
    }
  }

  &__name {
    color: #343434;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    text-transform: none;

    @media (max-width: 425px) {
      word-break: break-all;
    }
  }

  &__description {
    color: #68717a;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 11px 0 0;

    @media (max-width: 425px) {
      word-break: break-all;
    }

    & + p:not([class]) {
      color: #68717a;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
      margin: 11px 0 0;
    }
  }

  &__footer {
    font-size: 0.75rem;
    justify-content: space-between;
    margin: 34px 0 0;

    @media (max-width: 500px) {
      flex-direction: column;
      row-gap: 5px;
    }

    & p {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }

  &__text {
    color: #abb5be;
    & span {
      color: #0a71cc;
    }
  }

  &__more {
    align-items: center;
    color: #0a71cc;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    margin: 20px auto 0;
    text-transform: uppercase;
    text-decoration: none;

    @media screen and (max-width: 767px) {
      margin: 16px auto 0;
    }

    &:after {
      border-bottom: 2px solid #0a71cc;
      border-right: 2px solid #0a71cc;
      content: "";
      height: 8px;
      margin-left: 8px;
      margin-top: -5px;
      transform: rotate(45deg);
      width: 8px;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    padding: 10px 11px 10px;
    display: flex;
    position: relative;
    left: auto;
    bottom: auto;
    right: auto;
    flex-wrap: wrap;
    margin: 0;

    @media (min-width: 970px) {
      margin: 0;
    }
  }
}
