.search {
  &__footer {
    align-items: center;
    margin: 6px 0 0;

    @media screen and (max-width: 767px) {
      align-items: flex-start;
      display: none;
    }
  }

  &__input {
    background-color: transparent;
    border: 1px solid #b3da26;
    border-radius: 6px 0 0 6px;
    color: #abb5be;
    font-size: 1rem;
    height: 38px;
    line-height: 1;
    padding: 0 15px;
    width: 100%;

    &::placeholder {
      color: #abb5be;
    }
  }

  &__button {
    align-items: center;
    background-color: #b3da26;
    border-radius: 0 6px 6px 0;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    min-height: 100%;
    min-width: 40px;

    &:after {
      background-image: url("/wp-content/themes/studservice/new/img/search-icon.svg");
      //background-image: url(../img/icons-search.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      content: "";
      height: 19px;
      width: 19px;
    }
  }

  &__text {
    color: #abb5be;
    font-size: 1rem;
    letter-spacing: -0.02em;
    line-height: 1;
    margin-bottom: 0;
    text-align: left;

    @media screen and (max-width: 767px) {
      line-height: 0.875rem;
    }

    &_color-dark {
      color: #000;
      font-size: 0.875rem;
      margin-right: 5px;
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;

    @media screen and (max-width: 767px) {
      display: inline;
      font-size: 0.875rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
