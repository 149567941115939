.work-is-simple {
  margin-top: 55px;
  margin-bottom: 55px;
  font-size: 12px;
  line-height: 16px;

  @include media-breakpoint-up(md) {
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 16px;
    line-height: 26px;
  }

  color: #68717a;

  & h2 {
    color: initial;
  }

  &__pic {
    height: auto;
    display: block;
    margin: 0 auto 30px;
    max-width: 100%;
  }

  & .editor-text {
    & ul {
      margin-bottom: 29px;
      list-style-type: disc;
      font-size: 16px;
      line-height: 20px;

      color: #68717a;

      & li {
        position: relative;

        margin-bottom: 22px;
        padding-left: 0;

        &:before {
          content: none;
        }
      }
    }
  }

  & ul {
    margin-bottom: 29px;

    list-style: none outside;

    font-size: 16px;
    line-height: 20px;

    color: #68717a;

    & li {
      position: relative;

      margin-bottom: 22px;
      padding-left: 32px;

      &:before {
        content: "";

        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        display: block;

        width: 20px;
        height: 20px;

        background: url("../img/icons/good.svg") no-repeat 50% 50%;
      }
    }
  }

  & .btn {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;

    @include media-breakpoint-up(md) {
      width: 202px;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }

  &--partners,
  &--partners-students {
    @media (min-width: 768px) {
      margin-top: 100px;
      margin-bottom: 100px;
    }

    & .row {
      margin-left: 0;
      margin-right: 0;
    }

    & h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.02px;
      margin: 0 0 8px;

      @media (min-width: 1200px) {
        font-size: 30px;
      }
    }

    & .work-is-simple {
      &__pic {
        width: 372px;
        height: auto;
        margin: 0 auto;

        @media (min-width: 992px) {
          width: 320px;
        }

        @media (min-width: 1200px) {
          width: 372px;
        }
      }

      &__text {
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #000;
        margin: 0 0 22px;
      }
    }

    & .btn {
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      padding: 16px 43px;
      width: 100%;

      @media (min-width: 768px) {
        width: max-content;
      }

      @media (min-width: 992px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    & span.error {
      display: none !important;
    }

    & .form__field {
      margin-bottom: 22px;

      &:last-of-type {
        margin-bottom: 10px;
      }
    }

    & .col-12 {
      & > .col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }

    & form {
      margin-bottom: 30px;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }

  &--partners-students {
    & form {
      margin: 0;
    }
  }

  &__partners-students-form {
    padding: 0 0 0 160px;

    @media (max-width: 1023px) {
      padding: 0;
      margin: 21px 0 0 0;

      .btn {
        margin-left: 0;
      }

      & h2,
      & .work-is-simple__text {
        display: none;
      }
    }
  }

  &__partners-students-picture {
    & h2,
    & .work-is-simple__text {
      display: none;
    }

    @media (max-width: 1023px) {
      & h2 {
        display: block;
      }

      & .work-is-simple__text {
        display: inline-block;
      }
    }
  }
}
