.we-company {
  & .container {
    @include media-breakpoint-down(xs) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &__title {
    @include media-breakpoint-up(lg) {
      margin-bottom: 55px;
    }
  }
  &__item {
    margin-top: 22px;
    padding: 30px 37px 254px;

    font-size: 12px;
    line-height: 16px;

    color: #fff;
    border-radius: 6px;
    background: #313845;

    @include media-breakpoint-up(md) {
      min-height: 322px;
      padding: 50px;

      font-size: 16px;
      line-height: 26px;
    }

    @include media-breakpoint-up(lg) {
      height: calc(100% - 50px);
      margin-top: 50px;
      padding: 50px 140px 50px 50px;
    }

    @media (min-width: 1200px) {
      padding: 50px;
    }

    &.first-of-type {
      margin-top: 0;
    }

    &--1 {
      position: relative;

      @include media-breakpoint-up(md) {
        background-position: 100% 100%;
        background-size: contain;
      }

      &:after {
        display: block;
        content: "";
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url("../img/company/1-xs.png");
        background-image: image-set(
          url("../img/company/1-xs.webp") 1x,
          url("../img/company/1-xs@2x.webp") 2x,
          url("../img/company/1-xs.png") 1x,
          url("../img/company/1-xs@2x.png") 2x
        );
        background-repeat: no-repeat;
        background-position: 100% 100%;

        z-index: 1;

        @include media-breakpoint-up(md) {
          background-image: url("../img/company/1-md.png");
          background-image: image-set(
            url("../img/company/1-md.webp") 1x,
            url("../img/company/1-md@2x.webp") 2x,
            url("../img/company/1-md.png") 1x,
            url("../img/company/1-md@2x.png") 2x
          );
          background-repeat: no-repeat;
          background-position: 100% 100%;
        }

        @include media-breakpoint-up(lg) {
          top: -20px;
          background-image: url("../img/company/1.png");
          background-image: image-set(
            url("../img/company/1.webp") 1x,
            url("../img/company/1@2x.webp") 2x,
            url("../img/company/1.png") 1x,
            url("../img/company/1@2x.png") 2x
          );
          background-size: auto 340px;
        }
      }
    }

    &--2 {
      background-image: url("../img/company/2-xs.png");
      background-image: image-set(
        url("../img/company/2-xs.webp") 1x,
        url("../img/company/2-xs@2x.webp") 2x,
        url("../img/company/2-xs.png") 1x,
        url("../img/company/2-xs@2x.png") 2x
      );
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size: auto 254px;

      @include media-breakpoint-up(md) {
        background-image: url("../img/company/2-md.png");
        background-image: image-set(
          url("../img/company/2-md.webp") 1x,
          url("../img/company/2-md@2x.webp") 2x,
          url("../img/company/2-md.png") 1x,
          url("../img/company/2-md@2x.png") 2x
        );
        background-position: 100% 100%;
        background-size: contain;
      }

      @include media-breakpoint-up(lg) {
        background-image: url("../img/company/2.png");
        background-image: image-set(
          url("../img/company/2.webp") 1x,
          url("../img/company/2@2x.webp") 2x,
          url("../img/company/2.png") 1x,
          url("../img/company/2@2x.png") 2x
        );
        margin-right: 10px;
      }
    }

    &--3 {
      background-image: url("../img/company/3-xs.png");
      background-image: image-set(
        url("../img/company/3-xs.webp") 1x,
        url("../img/company/3-xs@2x.webp") 2x,
        url("../img/company/3-xs.png") 1x,
        url("../img/company/3-xs@2x.png") 2x
      );
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size: auto 254px;

      @include media-breakpoint-up(md) {
        background-image: url("../img/company/3-md.png");
        background-image: image-set(
          url("../img/company/3-md.webp") 1x,
          url("../img/company/3-md@2x.webp") 2x,
          url("../img/company/3-md.png") 1x,
          url("../img/company/3-md@2x.png") 2x
        );
        background-position: 100% 100%;
        background-size: contain;
      }

      @include media-breakpoint-up(lg) {
        background-image: url("../img/company/3.png");
        background-image: image-set(
          url("../img/company/3.webp") 1x,
          url("../img/company/3@2x.webp") 2x,
          url("../img/company/3.png") 1x,
          url("../img/company/3@2x.png") 2x
        );
        margin-left: 10px;
      }
    }

    & h4 {
      margin-bottom: 8px;

      font-size: 14px;
      line-height: 100%;
      font-family: inherit;

      @include media-breakpoint-up(md) {
        margin-bottom: 22px;

        font-size: 18px;
        line-height: 100%;
      }

      color: $green;
    }

    &__content {
      max-width: 340px;
      position: relative;
      z-index: 5;
    }
  }
}
