.form-comments {
  &__textarea {
    appearance: none;
    border: none;
    border-radius: 6px;
    color: #abb5be;
    font-size: 1rem;
    height: 45px;
    outline: 0;
    padding: 15px 16px 0;
    resize: none;
    width: 100%;

    &::-moz-placeholder,
    &::-webkit-input-placeholder,
    &::placeholder {
      color: #abb5be;
    }
  }

  &__file {
    height: 0.1px;
    opacity: 0;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }

  &__button {
    background-color: #0a71cc;
    border-radius: 4px;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 6px 10px 5px;
    text-transform: uppercase;

    &:after {
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: 14px;
      width: 14px;
    }
  }

  &__label {
    display: flex;

    &:before {
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: 14px;
      width: 14px;
      background-image: url(../img/icon-attach.svg);
      background-size: 100%;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  & .file__text {
    color: #88898a;
    font-size: 0.75rem;
    margin-right: 5px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__footer {
    align-items: center;
    bottom: 10px;
    position: absolute;
    right: 20px;
  }
}
