@import "variables";
@import "global/fonts";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/bootstrap-grid";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/tooltip";

// Blocks
// ---------------------------------

/* @import "./blocks/custom-input";
@import "./blocks/custom-toggle";
@import "./blocks/custom-select"; */

@import "./blocks/typo";

@import "./blocks/experts-mainpanel";
@import "./blocks/experts-filter";
@import "./blocks/experts-reviews";
@import "./blocks/experts-control";
@import "./blocks/experts-reviews-paginations";
@import "./blocks/experts-breadcrumbs";
@import "./blocks/experts-page";

@import "./blocks/header";
@import "./blocks/footer";
@import "./blocks/form";
@import "./blocks/footer-payment";
@import "./blocks/achievements";
@import "./blocks/reviews";
@import "./blocks/warranty";
@import "./blocks/about-smart";
@import "./blocks/about-content";
@import "./blocks/helpers";
@import "./blocks/working";
@import "./blocks/jumbotron";
@import "./blocks/principles";
@import "./blocks/work-is-simple";
@import "./blocks/we-company";
@import "./blocks/servicies";
@import "./blocks/calculator";
@import "./blocks/recomendation";
@import "./blocks/contacts";
@import "./blocks/error-page";
@import "./blocks/question-answer";
@import "./blocks/jcarousel";
@import "./blocks/worktape";
@import "./blocks/pagination";
@import "./blocks/articles-smart";
@import "./blocks/main.scss";
@import "./blocks/worktape-carousel";

@import "./blocks/components/breadcrumbs";
@import "./blocks/components/article-keep";
@import "./blocks/components/unsubscribe-form";
@import "./blocks/components/articles-blog";
@import "./blocks/components/banner-blog";
@import "./blocks/components/blog-category";
@import "./blocks/components/blog-tags";
@import "./blocks/components/blog";
@import "./blocks/components/bonus-blog";
@import "./blocks/components/category-blog";
@import "./blocks/components/comments-blog";
@import "./blocks/components/comments";
@import "./blocks/components/detail-article";
@import "./blocks/components/detail-blog";
@import "./blocks/components/feedback-article";
@import "./blocks/components/filter-comments";
@import "./blocks/components/form-comments";
@import "./blocks/components/fresh-blog";
@import "./blocks/components/nav-blog";
@import "./blocks/components/note";
@import "./blocks/components/search";
@import "./blocks/components/select-wrapper";
@import "./blocks/components/sidebar";
@import "./blocks/components/social";
@import "./blocks/components/textarea-wrapper";
@import "./blocks/components/title";
@import "./blocks/components/city-list";
@import "./blocks/components/leadHunterForm";
@import "./blocks/components/mobile-nav";
@import "./blocks//components/partners-agency";

@import "./blocks/components/partners-calc-form";

@import "./vendor/mCustomScrollbar";
@import "./vendor/selectric.scss";
@import "./vendor/_slick.scss";
@import "./vendor/dropzone.scss";
@import "./customStyleOld.scss";

@import "reboot";
