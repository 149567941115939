.article-keep {
  position: relative;

  @media screen and (max-width: 767px) {
    & > .article-keep__list {
      margin: 29px 0 30px;
    }
  }

  &__name {
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    line-height: 2.125rem;

    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  &__list {
    list-style-type: none;
    flex: 1 0 100%;
    flex-wrap: wrap;
    counter-reset: list2;
    margin: 18px 0 0 40px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-top: 10px;

    &_footer {
      max-width: initial !important;
    }

    & li {
      &:before {
        max-height: 32px;
        color: #555;
        color: #000000 !important;
        background-color: #b3da26;
        text-align: center;
        font-size: 1.125rem;
        line-height: 1;
        font-weight: 700;
        padding: 7px;
        border-radius: 6px;
        margin-right: 17px;
        min-width: 25px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: inline-block;
        background-color: #b3da26;
        content: counter(list1) "." counter(list2) ". ";
        counter-increment: list2;
      }

      &.top {
        counter-increment: list;
        counter-reset: list1;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:before {
          content: counter(list) ".";
        }
      }

      &.sub_1 {
        display: flex;
        align-items: center;
        counter-increment: list1;
        counter-reset: list2;

        &:before {
          content: counter(list) "." counter(list1) ".";
        }
      }

      &.sub_2 {
        counter-increment: list2;

        &:before {
          content: counter(list) "." counter(list1) "." counter(list2) ".";
        }
      }
    }
  }

  &__link {
    color: #152536;
    flex: 1;
    text-decoration: underline;

    @media screen and (max-width: 480px) {
      max-width: 65%;
    }

    &:hover {
      color: #b3da26;
      text-decoration: none;
    }
  }

  &__button-link {
    /* align-items: center;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    font-size: 0.75rem;
    line-height: 1;
    margin-top: 74px;
    padding: 14px 12px 13px;
    position: absolute;
    //right: -95px;
    text-transform: uppercase;
    top: 0;

    right: -98px !important; */
    position: sticky;
    top: 100px;
    margin: 100px 0 0 auto;
    transform: rotate(270deg) translate(0, 100px);
    text-transform: uppercase;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    width: max-content;
    height: auto;
    padding: 14px 12px 13px;
    font-size: 0.75rem;
    line-height: 1;
    background-color: #fff;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    &:after {
      background-image: url(/wp-content/themes/studservice/new/img/blog/icon-arrows.png) !important;
      //background-image: url(../img/icon-arrows.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 21px;
      margin-left: 9px;
      transform: rotate(90deg);
      width: 18px;
    }
  }

  &__item {
    margin-bottom: 18px;

    @media screen and (max-width: 480px) {
      align-items: flex-start;
      display: flex;

      flex-wrap: wrap;
    }

    &:before {
      background-color: #b3da26;
      border-radius: 6px;
      content: counter(list1);
      counter-increment: list1;
      display: inline-block;
      flex-shrink: 0;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1;
      margin-right: 17px;
      min-width: 25px;
      padding: 7px;
      text-align: center;
    }
  }
}
