.warranty {
  padding-top: 55px;
  padding-bottom: 25px;

  color: #fff;
  background: #313845;

  @include media-breakpoint-up(md) {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  &__title {
    margin-bottom: 22px;
  }

  &__desc {
    margin-bottom: 50px;
    font-size: 12px;
    line-height: 16px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__item {
    max-width: 310px;
    margin: 0 auto 50px;

    text-align: center;

    &--agency {
      font-size: 16px;
      font-size: 16px;
    }

    & img {
      display: block;

      margin: 0 auto 20px;
    }

    & h3 {
      margin-bottom: 8px;

      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      font-family: "Proxima Nova";
    }
  }

  &--partners {
    & .warranty {
      &__item {
        & svg {
          margin: 0 auto 22px;
        }
      }

      &__item-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
