.social {
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

  @media screen and (max-width: 767px) {
    align-items: flex-end;
    flex-direction: column;
  }

  @media (max-width: 425px) {
    position: static;
    top: auto;
    right: auto;
    transform: translate(0, 0);
    row-gap: 12px;
  }

  &__text {
    color: #abb5be;
    font-size: 0.75rem;
    margin-right: 14px;
    margin-bottom: 0;

    @media screen and (max-width: 767px) {
      margin: 0 0 11px;
      text-align: right;
    }

    @media (max-width: 425px) {
      margin: 0;
    }
  }

  &__item {
    margin: 0 7px;
  }

  &__link {
    align-items: center;
    background-color: #b3da26;
    border-radius: 50%;
    color: #000;
    display: flex;
    font-size: 0.75rem;
    height: 32px;
    justify-content: center;
    width: 32px;

    @media screen and (max-width: 767px) {
      height: 26px;
      width: 26px;
    }
  }

  &__list {
    margin: 0 -7px;
  }
}
