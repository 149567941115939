h1,
.h1 {
  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }

  &.extra {
    margin-bottom: 28px;

    font-weight: 700;
    font-size: 36px;
    line-height: 98.3%;

    letter-spacing: -0.02em;
    //text-transform: uppercase;

    @include media-breakpoint-up(md) {
      margin-bottom: 23px;

      font-size: 64px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 72px;
    }
  }
}

h2,
.h2 {
  margin-bottom: 30px;

  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    margin-bottom: 30px;

    font-size: 24px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 50px;
  }
}

h3,
.h3 {
  margin-bottom: 14px;

  font-weight: 700;
  font-size: 16px;
  line-height: 122.8%;
  font-family: "GothamPro", Verdana, Arial, sans-serif;

  letter-spacing: 0.005em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.04em;
    margin-bottom: 22px;
  }
}

h4,
.h4 {
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  font-family: "GothamPro", Verdana, Arial, sans-serif;

  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 24px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 27px;
  }
}

p {
  margin-bottom: 20px;
}

.gradient-text {
  background: linear-gradient(180deg, #0a71cc 0%, #b3da26 166.81%);
  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}

.text-primary {
  color: $primary;
}
