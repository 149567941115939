.about-smart {
    padding-top: 55px;
    padding-bottom: 55px;

    color: #fff;
    background: #313845;

    @include media-breakpoint-up(md) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &__desc {
        font-size: 12px;
        line-height: 16px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 26px;
        }

        & p {
            margin-bottom: 30px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &:before {
            content: '“';

            display: none;
            float: left;
            overflow: hidden;

            width: 156px;
            height: 156px;
            margin-top: -20px;
            margin-right: 50px;

            font-weight: 700;
            font-size: 288px;
            line-height: 242px;
            font-family: "GothamPro",Verdana,Arial,sans-serif;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
}
