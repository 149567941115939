.partners-calc-form {
  background-color: $dark;
  padding: 100px 0;

  @media (max-width: 767px) {
    padding: 55px 0;
  }

  &__title {
    color: $color-default-white;
    /*     font-weight: 400; */
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 35px;

    @media (max-width: 1023px) {
      font-size: 24px;
      line-height: 23px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 15px;
    }
  }

  &__wrapper {
    display: flex;
    column-gap: 17px;

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
      row-gap: 17px;
    }
  }

  &__main-form {
    padding: 40px;
    background-color: $color-default-white;
    border-radius: 15px;
    flex-grow: 1;

    @media (max-width: 767px) {
      padding: 30px 20px;
    }
  }

  &__range {
    position: relative;
  }

  &__select-wrapper {
    width: max-content;
    margin: 0 0 22px;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__select-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 10px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__range-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__result-form {
    padding: 40px;
    width: 386px;
    border-radius: 15px;
    background-color: $green;

    @media (max-width: 1023px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 30px 20px;
    }
  }

  &__result-summ {
    font-family: "Gotham Pro", Verdana, Arial, sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 34px;
    margin: 0 0 16px;
  }

  &__first-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 16px;

    br {
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__second-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;

    br {
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  .tick-slider-value-container {
    position: relative;
    width: 100%;
    height: 17px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 12px;

    font-family: "Hind Madurai", sans-serif;
    font-size: 18px;
    color: #71738b;
  }

  .tick-slider-value {
    position: absolute;
    top: 0;

    font-weight: bold;

    color: #34385a;

    border-radius: 4px;
  }

  .tick-slider-value > div {
    animation: bulge 0.3s ease-out;
  }

  .tick-slider-background,
  .tick-slider-progress,
  .tick-slider-tick-container {
    position: absolute;
    bottom: 22px;
    left: 0;

    height: 8px;

    pointer-events: none;

    border-radius: 4px;
  }

  .tick-slider-background {
    width: 100%;
    bottom: 22px;
    background-color: #ededed;
  }

  .tick-slider-progress {
    background: linear-gradient(270deg, #0a71cc 5.56%, #e0e5f2 100%);
  }

  .tick-slider-tick-container {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 calc(14px / 2);
  }

  .tick-slider-label {
    opacity: 0.85;
    transition: opacity 0.1s ease;
  }

  .tick-slider-label.hidden {
    opacity: 0;
  }

  @keyframes bulge {
    0% {
      transform: scale(1);
    }

    25% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  /*

    REMOVE SLIDER STYLE DEFAULTS

  */
  input[type="range"] {
    -webkit-appearance: none;
    position: relative;

    width: 100%;
    height: 100%;

    background: transparent;
    outline: none;

    margin: -5px 0;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;

    border: none;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-moz-focus-outer {
    border: 0;
  }

  /*

    HANDLE

  */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;

    width: 22px;
    height: 22px;

    background: #ffa929;

    background: #fff;

    border: none;
    border-radius: 50%;
    border: 4px solid #0a71cc;

    cursor: pointer;

    margin-top: -10px;

    -webkit-transform: scale(1);
    transform: scale(1);

    transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  input[type="range"]:hover::-webkit-slider-thumb,
  input[type="range"]:focus::-webkit-slider-thumb {
    transform: scale(1.2);
  }

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;

    width: 14px;
    height: 14px;

    background: #fff;

    border: none;
    border-radius: 50%;
    border: 4px solid #0a71cc;

    cursor: pointer;

    transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  input[type="range"]:hover::-moz-range-thumb,
  input[type="range"]:focus::-moz-range-thumb {
    transform: scale(1.2);
  }

  /*

    TRACK

  */

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;

    cursor: pointer;

    background: none;

    border-radius: 4px;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 6px;

    cursor: pointer;

    background: none;

    border-radius: 4px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: none;
  }
  input[type="range"]:active::-webkit-slider-runnable-track {
    background: none;
  }
}
