html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));

  &.scroll-lock {
    overflow: hidden;
  }
}

body {
  font-size: 14px;
  line-height: 17px;
  font-family: "Proxima Nova", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;

  color: #000;
}

.html {
  &--authors {
    scroll-behavior: smooth;
  }
}

a {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // убрал для position sticly article-keep__button

  // min-height: 100vh;

  /*   & .container {
    & .row {
      margin-left: 0;
      margin-right: 0;

      @include media-breakpoint-up(md) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  } */

  & input[type="checkbox"]:checked,
  & input[type="checkbox"]:not(:checked),
  & input[type="radio"]:checked,
  & input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  & input[type="checkbox"]:checked + label,
  & input[type="checkbox"]:not(:checked) + label,
  & input[type="radio"]:checked + label,
  & input[type="radio"]:not(:checked) + label {
    position: relative;

    display: inline-block;

    padding-left: 28px;

    cursor: pointer;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before,
  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: 16px;
    height: 16px;

    border: 1px solid #ced4da;
    border-radius: 2px;
    background-color: #fff;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
    top: 50%;
    transform: translate(0, -50%);
  }

  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    border-radius: 100%;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    content: "";

    position: absolute;

    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after {
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);

    width: 16px;
    height: 16px;

    background: url("../img/checked.svg") no-repeat 50% 50%;
  }

  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    top: 5px;
    left: 5px;

    width: 10px;
    height: 10px;

    border-radius: 100%;
    background-color: #b3da26;
  }

  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="radio"]:checked + label:after {
    opacity: 1;
  }

  & .container {
    @include media-breakpoint-down(xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.visuallity-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}

button,
input,
select,
textarea {
  border-radius: 6px;
  &:focus,
  &:active {
    outline: none !important;
  }
}
.experts-wrapper {
  overflow: hidden;

  min-height: 100vh;

  font-size: 14px;
  line-height: 17px;
  font-family: "Proxima Nova";

  color: #000;

  & input[type="checkbox"]:checked,
  & input[type="checkbox"]:not(:checked),
  & input[type="radio"]:checked,
  & input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  & input[type="checkbox"]:checked + label,
  & input[type="checkbox"]:not(:checked) + label,
  & input[type="radio"]:checked + label,
  & input[type="radio"]:not(:checked) + label {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before,
  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 16px;
    height: 16px;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    border-radius: 2px;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
  }

  & input[type="radio"]:checked + label:before,
  & input[type="radio"]:not(:checked) + label:before {
    border-radius: 100%;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after {
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: url("../img/checked.svg") no-repeat 50% 50%;
  }

  & input[type="radio"]:checked + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #b3da26;
  }

  & input[type="checkbox"]:not(:checked) + label:after,
  & input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="radio"]:checked + label:after {
    opacity: 1;
  }
}
.expert-tooltip {
  &.show {
    opacity: 1;
  }

  & .arrow {
    &::before {
      border-bottom-color: #fff;
    }
  }
  & .tooltip-inner {
    max-width: 135px;
    padding: 12px 9px;

    font-size: 10px;
    line-height: 12px;

    color: #000;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 0 15px rgba(#000, 0.25);
  }
}

.delimtr {
  width: 80%;
  height: 1px;

  background-color: rgba(171, 181, 190, 0.5);
}

.text-green {
  color: $green;
}

.bg-grey {
  overflow: auto;

  background-color: $light;
}

.bg-social {
  @include media-breakpoint-up(lg) {
    overflow: auto;

    background: url("../img/achievements-bg.svg") no-repeat 50% 10%;
    background-size: 100% auto;
  }
}

.btn {
  font-weight: 700;
  font-family: "Proxima Nova", Arial, sans-serif !important;

  text-decoration: none;
}

.btnCalculate {
  /* display: none; */
  position: fixed;
  right: 36px;
  bottom: 130px;
  background: #0a71cc;
  color: #fff;
  width: 180px;
  font-size: 16px;
  border-radius: 110px;
  cursor: pointer;
  transition: 0.2s;
  z-index: 100;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 34px;
    top: 13px;
    left: 19px;
    height: 34px;
    background: url(../img/icons/calculator.svg);
    background-size: cover;
  }

  &:hover {
    background: #d71f25;
  }

  & span {
    width: 95px;
    display: block;
    line-height: 15px;
    padding: 14px 0 14px 60px;
    font-weight: bold;
  }
}

// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте mixin hover-focus для отключения ховеров на тач устройствах
/* @mixin hover-focus {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }

    &.focus-visible:focus {
      @content;
    }
  }
*/
/*  @include hover-focus {
      opacity: 0.8;
    }
*/
// но не используем для текстовых полей ( input, textarea )
// так же в сборке есть отдельный миксин для hover
/*@mixin hover {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }
  }
*/
// для focus
/*@mixin focus {
    &.focus-visible:focus {
      @content;
    }
  }
*/
// и для active
/*@mixin active {
    &.focus-visible:active {
      @content;
    }
  }
*/
// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅
