.about-content {
  margin-top: 30px;
  margin-bottom: 30px;

  font-size: 12px;
  line-height: 16px;

  color: #68717a;

  @include media-breakpoint-up(md) {
    margin-top: 55px;
    margin-bottom: 55px;

    font-size: 16px;
    line-height: 26px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  & p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__tabs {
    // overflow: hidden;

    &__menu {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      height: 37px;
      // overflow-x: auto;
      // overflow-scrolling: touch;
      // overflow-style: autohiding-scrollbar;
      // -moz-overflow-style: autohiding-scrollbar;
      // -ms-overflow-style: none;
      // scrollbar-width: none;
      // scroll-behavior: smooth;

      margin-bottom: 20px;

      list-style: none outside;

      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      color: #68717a;
      //border-bottom: 2px solid #ededed;

      @include media-breakpoint-up(md) {
        margin-bottom: 50px;

        font-size: 18px;
        line-height: 24px;
      }

      & a {
        display: block;
        padding-bottom: 8px;

        text-decoration: none;

        color: inherit;
        //border-bottom: 5px solid transparent;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: transparent;
          z-index: 10;
        }
      }

      & > .card {
        margin-bottom: 0;
        // margin-right: 30px;
        //padding-right: 30px;
        white-space: nowrap;
        position: relative;
        margin-right: 0;
        padding: 0 20px;

        &:first-child {
          padding: 0 20px 0 0;
        }

        &:last-child {
          padding-right: 30px;

          &:after {
            width: calc(100% - 30px);
          }
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: transparent;
          z-index: 1;
        }

        &.current {
          color: #000;

          & a {
            &:after {
              background-color: $green;
            }
            //border-bottom: 5px solid $green;
          }
        }
      }
    }

    &__item {
      display: none;

      &.current {
        display: block;
      }

      & iframe {
        width: 100%;
        height: 370px;
      }
    }

    &__btn {
      position: absolute;
      top: -10px;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 35px;
      height: 40px;

      border: none;
      border-radius: 0;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        top: -7px;
      }

      &__left {
        left: -2px;

        display: none;

        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

        &:after {
          content: "";

          display: block;

          width: 9px;
          height: 16px;

          background: url("../img/arrow-left.svg") no-repeat 50% 50%;
        }

        &.current {
          display: flex;
        }
      }

      &__right {
        right: -2px;

        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

        &:after {
          content: "";

          display: block;

          width: 9px;
          height: 16px;

          background: url("../img/arrow-right.svg") no-repeat 50% 50%;
        }
      }
    }
  }
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;

  white-space: nowrap;

  .card {
    display: inline-block;
  }
}

.scrolling-wrapper-flexbox {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;

  .card {
    flex: 0 0 auto;

    //margin-right: 3px;
  }
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow-style: autohiding-scrollbar;
  -moz-overflow-style: autohiding-scrollbar;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;

  width: 100%;
  height: 30px;

  @include media-breakpoint-up(md) {
    height: 37px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
