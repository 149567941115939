.question-answer {
    @include media-breakpoint-up(lg) {
        display: block;

        margin-top: 100px;
        margin-bottom: 100px;
    }

    &__catalog {
        &__item {
            $p: &;
            border: 1px solid #DEE2E6;
            border-radius: 6px;
            margin-bottom: 22px;
            padding: 15px 22px 15px 22px;

            min-height: 52px;

            position: relative;

            &:after {
                position: absolute;
                top: 14px;
                right: 22px;
                content: '';
                display: flex;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: $green url('../img/question-answer/plus.svg') no-repeat 50% 50%;
                font-weight: 700;
                font-size: 24px;
                line-height: 100%;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                z-index: 1;
            }

            &.open {
                &:after {
                    content: '';
                    background: $green url('../img/question-answer/minus.svg') no-repeat 50% 50%;
                }
            }

            &__title {
                margin-bottom: 0px;
                cursor: pointer;
                position: relative;
                z-index: 10;
                padding-right: 46px;

                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                    line-height: 22px;
                }
                & h2 {
                    @include media-breakpoint-up(lg) {
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 0;
                        text-transform: initial;
                        font-weight: 300;
                    }
                }
            }

            &__content {
                display: none;
                font-size: 16px;
                line-height: 26px;

                & p {
                    &:last-of-type {
                        // margin-bottom: 0;
                    }
                }

                & ul {
                    margin: 20px 0;
                    padding-left: 22px;
                }
            }

            &.open &__title {
                margin-bottom: 8px;
                color: $blue;
            }

            &.open &__content {
                display: block;
            }
        }
    }
}
