.unsubscribe-form {
  &__wrapper {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .form-check {
      & input[type="radio"]:checked + label:before,
      & input[type="radio"]:not(:checked) + label:before {
        top: 50%;
        transform: translate(0, -50%);
      }

      & input[type="radio"]:checked + label:after {
        top: 50%;
        left: 3px;
        transform: translate(0, -50%);
      }
    }
  }

  &__textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
  }
}
